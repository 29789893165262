import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import { loggedUser, logoutSuccess, settingData } from 'store';
import { LOGIN } from 'config';

const HomePageNavbar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  /**
   * Using hooks from react-redux to get data from store
   */
  const dispatch = useDispatch();
  const checkLoggedInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggedInUser.isLoggedIn;

  const [isActive, setIsActive] = useState(false);
  const [navBgColor, setNavBgColor] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  /**
   * This will call on page render, and set background color
   */
  useEffect(() => {
    changeBackground();
    window.addEventListener('scroll', changeBackground);
    const queryString = window.location.search;
    let query = new URLSearchParams(queryString);
    if (query.get('redirect') && isRedirect === false) {
      setTimeout(() => {
        gotoServices(query.get('redirect'));
        setIsRedirect(true);
      }, 1000);
    }
  });
  const { t, banner_data } = props;
  /**
   * This function will call on click of nav links, and will move to that section
   */
  const gotoServices = (id) => {
    const titleElement = document.getElementById(id.toString());
    if (titleElement) {
      titleElement.scrollIntoView(true, { behavior: 'smooth', block: 'end' });
    }
  };
  /**
   * This function will call when user scrolls page, it'll change background color
   */
  const changeBackground = () => {
    if (window.scrollY >= 3) {
      setNavBgColor(true);
    } else {
      setNavBgColor(false);
    }
  };
  /**
   * This function will call on logout link, then user will logged out,
   * data will get removed from redux store as well
   */
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate(LOGIN);
  };
  /**
   * Getting page data from redux store
   */
  const getSettingData = useSelector(settingData);
  /**
   * This function will call on nav link of dashboard,
   * user will be redirected to dashboard if logged in, or else to the login page
   */
  const handleDashboard = () => {
    navigate('/');
  };
  /**
   * This on click of dashboard and will be redirected to the login page
   */
  const handleSteps = () => {
    navigate(LOGIN);
  };
  const setNavExpandedClick = (expanded) => {
    setIsActive(expanded);
  };

  const setNavClose = () => {
    setIsActive(false);
  };
  return (
    <Container fluid className="navbar-container">
      <Navbar
        collapseOnSelect
        expand="lg"
        onToggle={setNavExpandedClick}
        expanded={isActive}
        className={navBgColor || isActive === true ? 'navbar-dark-bg' : 'navbar'}>
        <span>
          <NavLink to="/" className="brand-logo navbar-brand">
            <img
              src={
                getSettingData &&
                (navBgColor || isActive === true
                  ? getSettingData?.home_page_general_header_sub_logo
                  : getSettingData?.home_page_general_header_logo)
              }
              className="brand-logo-img"
              alt={'Header Logo'}
            />
          </NavLink>
        </span>
        <div className="d-flex">
          <div className="login-button mobile-login-btn">
            {isLoggedIn ? (
              <>
                <Button onClick={handleLogout} className="login-btn ms-2 button-rounded">
                  {t('front.home_page_navbar_button_log_out')}
                </Button>
                {location && location.pathname.includes('/auth/') ? (
                  <Button onClick={handleDashboard} className="step-btn ms-2 button-rounded">
                    {t('front.home_page_navbar_button_dashboard')}
                  </Button>
                ) : (
                  <Button onClick={handleSteps} className="step-btn ms-2 button-rounded">
                    {t('front.home_page_navbar_button_dashboard')}
                  </Button>
                )}
              </>
            ) : (
              <Link to="/login" className="login-btn button-rounded text-decoration-none">
                {t('front.home_page_navbar_button_log_in')}
              </Link>
            )}
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FontAwesomeIcon className="custom-icon" icon={isActive === true ? faXmark : faBars} />
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={navBgColor || isActive === true ? '' : ''}>
          <Nav className="ms-auto navbar-color" onClick={setNavClose}>
            <Nav.Item>
              <Nav.Link
                to="/"
                eventKey="1"
                onClick={() => {
                  gotoServices('Home_header_section');
                  setIsActive(false);
                }}>
                {t('front.home_page_navbar_nav_link_home')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                to="/"
                eventKey="2"
                onClick={() => {
                  gotoServices('about_us_section');
                  setIsActive(false);
                }}>
                {t('front.home_page_navbar_nav_link_about')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                to="/"
                eventKey="3"
                onClick={() => {
                  gotoServices('how_it_works_section');
                  setIsActive(false);
                }}>
                {t('front.home_page_navbar_nav_link_how_to')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                to="/"
                eventKey="4"
                onClick={() => {
                  gotoServices('pricing_section');
                  setIsActive(false);
                }}>
                {t('front.home_page_navbar_nav_link_pricing')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                to={banner_data?.home_page_banner_blog_link}
                href={banner_data?.home_page_banner_blog_link}>
                {t('front.home_page_navbar_nav_link_blog')}
              </Nav.Link>
            </Nav.Item>
            <div className="login-button desktop-login-btn">
              {isLoggedIn ? (
                <>
                  <Button onClick={handleLogout} className="login-btn button-rounded ms-2">
                    {t('front.home_page_navbar_button_log_out')}
                  </Button>
                  {location && location.pathname.includes('/auth/') ? (
                    <Button onClick={handleDashboard} className="step-btn ms-2 button-rounded">
                      {t('front.home_page_navbar_button_dashboard')}
                    </Button>
                  ) : (
                    <Button onClick={handleSteps} className="step-btn ms-3 button-rounded">
                      {t('front.home_page_navbar_button_dashboard')}
                    </Button>
                  )}
                </>
              ) : (
                <Link to="/login" className="text-decoration-none login-btn button-rounded">
                  {t('front.home_page_navbar_button_log_in')}
                </Link>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};
HomePageNavbar.propTypes = {
  t: PropTypes.func,
  OtherPage: PropTypes.bool,
  homepage: PropTypes.bool,
  ServicesRef: PropTypes.any,
  banner_data: PropTypes.object,
};
export { HomePageNavbar };
