import { Card, Container } from 'react-bootstrap';
import React from 'react';
import 'assets/scss/page/_coupon_code.scss';
import 'assets/scss/page/_company_information_page1.scss';
import { LoadingShimmer, ShimmerSectionHeader } from 'common';

const CommonShimmer = () => {
  return (
    <>
      <Container className="company-info-one-step-container staff-details inner-section-container">
        <div className="inner-page-header-section d-flex inner-section-content mb-3">
          <ShimmerSectionHeader />
        </div>

        <Card className="inner-card-section">
          <div className="edit-profile-form d-flex justify-content-center m-4">
            <LoadingShimmer />
          </div>
        </Card>
      </Container>
    </>
  );
};
export default CommonShimmer;
