import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Order process routes, and pages
 */

// const OrderView = React.lazy(() => import('./AllOrders/OrderViewPage'));
// const ViewIntakeQuestions = React.lazy(() => import('./Questions/ViewIntakeQuestions'));
// const ViewMedicalQuestions = React.lazy(() => import('./Questions/ViewMedicalQuestions'));
// const OrderDetails = React.lazy(() => import('./AllOrders/OrderDetails/OrderDetails'));
// import PatientChartPage from 'pages/PatientChart/PatientChartPage';
// const AllOrderRoutes = ({ t }) => {
//   return (
//     <Routes>
//       <Route exact path="/" element={<OrderView t={t} />} />
//       <Route exact path="order-details/:order_id" element={<OrderDetails t={t} />} />
//       <Route exact path="/order/:order_id" element={<ViewIntakeQuestions t={t} />} />
//       <Route
//         exact
//         path="/order/:order_id/formulary/:formulary_id"
//         element={<ViewMedicalQuestions t={t} />}
//       />
//       <Route path="/patient-chart/:user_id" element={<PatientChartPage t={t} />} />
//       <Route exact path="*" element={<Navigate replace to="/404" />} />
//     </Routes>
//   );
// };
// AllOrderRoutes.propTypes = {
//   t: PropTypes.func,
// };
// export default AllOrderRoutes;

const OrderView = React.lazy(() => import('./OrderView/OrderViewPage'));
const ViewIntakeQuestions = React.lazy(() => import('./Questions/ViewIntakeQuestions'));
const ViewMedicalQuestions = React.lazy(() => import('./Questions/ViewMedicalQuestions'));
const OrderDetails = React.lazy(() => import('./OrderDetails/OrderDetails'));
import PatientChartPage from 'pages/PatientChart/PatientChartPage';

const AllOrderRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/" element={<OrderView t={t} />} />
      <Route exact path="/order-details/:order_id" element={<OrderDetails t={t} />} />
      <Route exact path="/order/:order_id" element={<ViewIntakeQuestions t={t} />} />
      <Route
        exact
        path="/order/:order_id/formulary/:formulary_id"
        element={<ViewMedicalQuestions t={t} />}
      />
      <Route path="/patient-chart/:user_id" element={<PatientChartPage t={t} />} />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AllOrderRoutes.propTypes = {
  t: PropTypes.func,
};
export default AllOrderRoutes;
