import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ShimmerThumbnail = ({ center, className, fitOnFrame, rounded, style }) => {
  return fitOnFrame ? (
    <div
      className={classNames({
        'h-100': fitOnFrame,
        'text-center ': center,
      })}>
      <div
        className={classNames({
          'h-100': fitOnFrame,
          'shimmer shimmer-thumbnail': true,
          'border-rounded': rounded,
          [className]: className,
        })}
        style={style}></div>
    </div>
  ) : (
    <div
      className={classNames({
        'h-100': fitOnFrame,
        'shimmer shimmer-thumbnail': true,
        'border-rounded': rounded,
        [className]: className,
      })}
      style={style}></div>
  );
};

ShimmerThumbnail.propTypes = {
  style: PropTypes.any,
  center: PropTypes.bool,
  className: PropTypes.string,
  fitOnFrame: PropTypes.bool,
  rounded: PropTypes.bool,
};

ShimmerThumbnail.defaultProps = {
  canter: false,
  fitOnFrame: false,
  rounded: false,
  style: null,
};

export default ShimmerThumbnail;
