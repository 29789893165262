import { t } from 'i18next';
import React from 'react';
export const rxStatusShow = (item) => {
  if (item.rx_status === 1)
    return <span className={'text-info'}>{t('front.order_view_rx_status_pending_label')}</span>;
  else if (item.rx_status === 2)
    return <span className={'text-primary'}>{t('front.order_view_rx_status_accept_label')}</span>;
  else if (item.rx_status === 3)
    return <span className={'text-danger'}>{t('front.order_view_rx_status_reject_label')}</span>;
  else if (item.rx_status === 4)
    return <span className={'text-warning'}>{t('front.order_view_rx_refund_label')}</span>;
  else if (item.rx_status === 5)
    return <span className={'text-danger'}>{t('front.order_view_rx_status_cancel_label')}</span>;
  else return '';
};
export const preStatusShow = (item) => {
  if (item.pre_status === 1)
    return (
      <span className={'text-warning'}>
        {t('front.order_view_pre_status_pending_patient_contact_label')}
      </span>
    );
  else if (item.pre_status === 2)
    return (
      <span className={'text-warning'}>
        {t('front.order_view_pre_status_pending_patient_contact_insurance_label')}
      </span>
    );
  else if (item.pre_status === 3)
    return (
      <span className={'text-warning'}>
        {t('front.order_view_pre_status_pending_patient_contact_shipping_label')}
      </span>
    );
  else if (item.pre_status === 4)
    return (
      <span className={'text-primary'}>
        {t('front.order_view_pre_status_processed_shipped_label')}
      </span>
    );
  else if (item.pre_status === 5)
    return <span className={'text-primary'}>{t('front.order_view_pre_status_shipped_label')}</span>;
  else if (item.pre_status === 6)
    return (
      <span className={'text-primary'}>{t('front.order_view_pre_status_delivered_label')}</span>
    );
  else if (item.pre_status === 7)
    return (
      <span className={'text-danger'}>
        {t('front.order_view_pre_status_closed_patient_cancelled_label')}
      </span>
    );
  else if (item.pre_status === 8)
    return (
      <span className={'text-danger'}>
        {t('front.order_view_pre_status_closed_patient_no_response_label')}
      </span>
    );
  else if (item.pre_status === 9)
    return (
      <span className={'text-danger'}>
        {t('front.order_view_pre_status_closed_patient_declined_therapy_label')}
      </span>
    );
  else if (item.pre_status === 10)
    return (
      <span className={'text-danger'}>
        {t('front.order_view_pre_status_closed_prescriber_no_response_label')}
      </span>
    );
  else if (item.pre_status === 11)
    return (
      <span className={'text-danger'}>
        {t('front.order_view_pre_status_closed_prescriber_cancelled_therapy_label')}
      </span>
    );
  else return '';
};
export const fillStatusShow = (item) => {
  if (item.transitionrx_fill_status === 1)
    return <span className={'text-warning'}>{t('front.order_view_fill_status_placed_label')}</span>;
  else if (item.transitionrx_fill_status === 2)
    return (
      <span className={'text-danger'}>{t('front.order_view_fill_status_replacement_label')}</span>
    );
  else if (item.transitionrx_fill_status === 3)
    return <span className={'text-primary'}>{t('front.order_view_fill_shipped_label')}</span>;
  else if (item.transitionrx_fill_status === 4)
    return <span className={'text-primary'}>{t('front.order_view_fill_delivered_label')}</span>;
  else if (item.transitionrx_fill_status === 5)
    return <span className={'text-danger'}>{t('front.order_view_fill_cancelled_label')}</span>;
  else if (item.transitionrx_fill_status === 6)
    return (
      <span className={'text-danger'}>{t('front.order_view_fill_exception_missing_rx_label')}</span>
    );
  else if (item.transitionrx_fill_status === 7)
    return (
      <span className={'text-danger'}>{t('front.order_view_fill_exception_expired_label')}</span>
    );
  else if (item.transitionrx_fill_status === 8)
    return (
      <span className={'text-danger'}>{t('front.order_view_fill_exception_too_soon_label')}</span>
    );
  else if (item.transitionrx_fill_status === 9)
    return <span className={'text-danger'}>{t('front.order_view_fill_exception_dob_label')}</span>;
  else if (item.transitionrx_fill_status === 10)
    return <span className={'text-danger'}>{t('front.order_view_fill_exception_name_label')}</span>;
  else if (item.transitionrx_fill_status === 11)
    return (
      <span className={'text-danger'}>{t('front.order_view_fill_exception_address_label')}</span>
    );
  else if (item.transitionrx_fill_status === 12)
    return (
      <span className={'text-danger'}>
        {t('front.order_view_fill_exception_rx_confirmation_label')}
      </span>
    );
  else if (item.transitionrx_fill_status === 13)
    return (
      <span className={'text-danger'}>{t('front.order_view_fill_exception_pending_label')}</span>
    );
  else return '';
};
export const carePointStatusShow = (item) => {
  if (item.carepoint_status === 1)
    return (
      <span className={'text-danger'}>
        {t('front.front_order_view_carepoint_status_error_label')}
      </span>
    );
  else if (item.carepoint_status === 2)
    return (
      <span className={'text-warning'}>
        {t('front.front_order_view_carepoint_status_created_label')}
      </span>
    );
  else if (item.carepoint_status === 3)
    return (
      <span className={'text-warning'}>
        {t('front.front_order_view_carepoint_status_processing_label')}
      </span>
    );
  else if (item.carepoint_status === 4)
    return (
      <span className={'text-primary'}>
        {t('front.front_order_view_carepoint_status_shipped_label')}
      </span>
    );
  else if (item.carepoint_status === 5)
    return (
      <span className={'text-primary'}>
        {t('front.front_order_view_carepoint_status_delivered_label')}
      </span>
    );
  else if (item.carepoint_status === 6)
    return (
      <span className={'text-danger'}>
        {t('front.front_order_view_carepoint_status_canceled_label')}
      </span>
    );
  else if (item.carepoint_status === 7)
    return (
      <span className={'text-warning'}>
        {t('front.front_order_view_carepoint_status_partial_payment_label')}
      </span>
    );
  else if (item.carepoint_status === 8)
    return (
      <span className={'text-primary'}>
        {t('front.front_order_view_carepoint_status_ready_for_pickup_label')}
      </span>
    );
  else if (item.carepoint_status === 9)
    return (
      <span className={'text-warning'}>
        {t('front.front_order_view_carepoint_status_awaiting_prior_authorization_label')}
      </span>
    );
  else if (item.carepoint_status === 10)
    return (
      <span className={'text-danger'}>
        {t('front.front_order_view_carepoint_status_return_to_sender_label')}
      </span>
    );
  else return '';
};
export const orderStatusShow = (order) => {
  if (order.order_status === 1)
    return (
      <h4 className="add-caption text-info">
        {t('front.org_order_view_order_status_draft_label')}
      </h4>
    );
  else if (order.order_status === 2)
    return (
      <h4 className="add-caption text-info">
        {t('front.org_order_view_order_status_placed_label')}
      </h4>
    );
  else if (order.order_status === 3)
    return (
      <h4 className="add-caption text-warning">
        {t('front.org_order_view_order_status_rx_accepted_label')}
      </h4>
    );
  else if (order.order_status === 4)
    return (
      <h4 className="add-caption text-primary">
        {t('front.org_order_view_order_status_pharmacy_placed_label')}
      </h4>
    );
  else if (order.order_status === 5)
    return (
      <h4 className="add-caption text-primary">
        {t('front.org_order_view_order_status_shipped_label')}
      </h4>
    );
  else if (order.order_status === 6)
    return (
      <h4 className="add-caption text-primary">
        {t('front.org_order_view_order_status_delivered_label')}
      </h4>
    );
  else if (order.order_status === 7)
    return (
      <h4 className="add-caption text-danger">
        {t('front.org_order_view_order_status_canceled_label')}
      </h4>
    );
  else '';
};
