import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { TemplateServices } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Cmg data
 */
const useGetTemplateData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`get-template`, params], () => TemplateServices.getTemplateData(params), {
    onSuccess,
    onError,
  });
};

const useUpdateTemplateData = (onSuccess, onError = onDefaultError) => {
  return useMutation(TemplateServices.updateTemplateData, {
    onSuccess,
    onError,
  });
};

const useGetPatientOrderTemplateData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-patient-order-email-template`, params],
    () => TemplateServices.getPatientOrderEmailTemplateData(params),
    {
      onSuccess,
      onError,
    }
  );
};

const useUpdatePatientOrderEmailTemplateData = (onSuccess, onError = onDefaultError) => {
  return useMutation(TemplateServices.updatePatientOrderEmailTemplateData, {
    onSuccess,
    onError,
  });
};

export {
  useUpdateTemplateData,
  useGetTemplateData,
  useGetPatientOrderTemplateData,
  useUpdatePatientOrderEmailTemplateData,
};
