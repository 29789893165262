import client from 'libs/HttpClient';

class FormularyCategoryService {
  static listFormularyCategory(params) {
    return client.get(`/formulary-category/list`, { params });
  }
  static addFormularyCategory(request) {
    return client.post(`/formulary-category/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getFormularyCategory(params) {
    return client.get(`/formulary-category/get`, { params });
  }
  static updateFormularyCategoryStatus(request) {
    return client.put(`/formulary-category/edit-status/`, request);
  }
  static updateFormularyCategory(request) {
    return client.put(`/formulary-category/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static listFormularyCategoryName(params) {
    return client.get(`/formulary-category/get-all-category`, { params });
  }
  static deleteFormularyCategory(params) {
    return client.delete(`/formulary-category/delete/`, { params });
  }
}
export { FormularyCategoryService };
