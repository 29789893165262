import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'react-bootstrap';

const OurTeam = ({ our_team_data, our_team_list_data }) => {
  return our_team_data &&
    our_team_list_data &&
    Object.values(our_team_data).length !== 0 &&
    Object.values(our_team_list_data).length !== 0 ? (
    <Container
      className="our-team-container"
      id="our_team_section"
      data-aos="fade-down"
      data-aos-offset="300">
      <div className="our-team">
        <h2 className="section-heading our-team-heading">
          {our_team_data?.home_page_our_team_header_title}
        </h2>
        <h4 className="our-team-heading-sub section-title">
          {our_team_data?.home_page_our_team_header_sub_title}
        </h4>
        <p className="our-team-para section-description">
          {our_team_data?.home_page_our_team_header_text}
        </p>
      </div>
      <Container>
        <Row className="our-team-cards g-3 m-0">
          {our_team_list_data?.map((team) => (
            <Col lg={4} md={6} xs={12} key={team.our_team_id.toString()}>
              <Card className="our-team-card-container">
                <Card.Img className="our-team-card-img" src={team.image} alt="flowers-Img" />
                <Card.Body className="our-team-card-body">
                  <Card.Title className="our-team-card-title">{team.name}</Card.Title>
                  <Card.Text className="our-team-card-text">{team.designation}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  ) : null;
};
OurTeam.propTypes = {
  our_team_data: PropTypes.object,
  our_team_list_data: PropTypes.array,
};
export { OurTeam };
