import * as Yup from 'yup';

let validationSchema = Yup.object({
  name: Yup.string('front.home_page_get_in_touch_form_name_validation_required').required(
    'front.home_page_get_in_touch_form_name_validation_required'
  ),
  email: Yup.string('front.home_page_get_in_touch_form_email_validation_required')
    .required('front.home_page_get_in_touch_form_email_validation_required')
    .email('front.home_page_get_in_touch_form_email_valid_email'),
  question: Yup.string().required('front.home_page_get_in_touch_form_message_validation_required'),
});
export default validationSchema;
