import client from 'libs/HttpClient';
class TemplateServices {
  static getTemplateData(params) {
    return client.get(`settings/get-template/`, { params });
  }

  static updateTemplateData(params) {
    return client.put(`settings/update-template`, params);
  }

  static getPatientOrderEmailTemplateData(params) {
    return client.get(`settings/get-patient-order-email-template/`, { params });
  }

  static updatePatientOrderEmailTemplateData(request) {
    return client.post(`settings/update-patient-order-email`, request, {
      headers: { 'Content-type': 'multipart/form-data' },
    });
  }
}
export { TemplateServices };
