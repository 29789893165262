import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loggedUser } from 'store';
import { requestForToken, onMessageListener } from './firebase';
import client from 'libs/HttpClient';
import { getDefaultTimezone } from 'helpers';

const Notification = () => {
  const checkLoggedInUser = useSelector(loggedUser);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast.info(<ToastDisplay />);
  const ToastDisplay = () => {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
    if (checkLoggedInUser.isLoggedIn) requestForToken();
  }, [notification, checkLoggedInUser]);
  if (checkLoggedInUser.isLoggedIn) requestForToken();
  if (checkLoggedInUser.user.timezone_id === '') {
    const CuurentTimezone = getDefaultTimezone();
    if (CuurentTimezone) {
      try {
        client.post('/token/store-timezone', { timezone: CuurentTimezone });
      } catch (error) {
        console.error('An error occurred in handleError:', error);
      }
    }
  }
  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));
};

export default Notification;
