// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import client from 'libs/HttpClient';
// var firebaseConfig = {
//   apiKey: 'AIzaSyDPUDR5vIuhyonvsahMiu86aIKi9XQrRA0',
//   authDomain: 'telepath-aa82c.firebaseapp.com',
//   projectId: 'telepath-aa82c',
//   storageBucket: 'telepath-aa82c.appspot.com',
//   messagingSenderId: '528958531400',
//   appId: '1:528958531400:web:65b25b231caea2c40af57d',
//   measurementId: 'G-WZLL1T7Z3K',
// };
// const firebaseConfig = {
//   apiKey: 'AIzaSyDbMkbF6FJ1Z4QQwbcMKfHCZj8358-PJSY',
//   authDomain: 'telepath-4b028.firebaseapp.com',
//   projectId: 'telepath-4b028',
//   storageBucket: 'telepath-4b028.appspot.com',
//   messagingSenderId: '478422866930',
//   appId: '1:478422866930:web:6c212caf5f7de22869a96b',
//   measurementId: 'G-D9P4JHT2MM',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyDDW3PQYgxLZMNIpI73-KePVUFCsidqDwQ',
  authDomain: 'telepath-web.firebaseapp.com',
  projectId: 'telepath-web',
  storageBucket: 'telepath-web.appspot.com',
  messagingSenderId: '951510277049',
  appId: '1:951510277049:web:e77aba13f213aad75f7468',
  measurementId: 'G-BK4LE8KKY6',
};

let messaging = '';
try {
  initializeApp(firebaseConfig);
  messaging = getMessaging();
} catch (error) {
  console.log(error);
}

export const requestForToken = () => {
  if ('Notification' in window) {
    // Notification.requestPermission(() => {
    //   if (Notification.permission === 'granted') {

    //   }
    // });
    if (messaging) {
      return getToken(messaging, {
        vapidKey: `BKxrll43fTFw_5uFRWYcf4gdga7WS-gGE3C9juxXrbTAsOSEcNFrMkLx-vUrS9to084Xf5DdH3q9TvjhMHYXEB0`,
      })
        .then(async (currentToken) => {
          if (currentToken) {
            localStorage.fcmToken = currentToken;
            try {
              await client.post('/token/store', { token: currentToken });
            } catch (error) {
              console.error('An error occurred in handleError:', error);
            }
            // Perform any other neccessary action with the token
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }
  } else {
    console.log('Browser does not support desktop notification');
  }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
