import client from 'libs/HttpClient';

class OrderServices {
  static getIntakeResponseData(params) {
    return client.get('order/organization-general-intake', { params });
  }
  static getOrderDetails(params) {
    return client.get('order/get-organization-order-detail', { params });
  }
  static getMedicalResponseData(params) {
    return client.get('order/organization-medical-intake', { params });
  }
  static getOrderListData(params) {
    return client.get('order/organization-all-orders', { params });
  }
  static getOrderDownloadData(params) {
    return client.get('order/organization-all-orders-download', { params });
  }
  static getOrderNotes(params) {
    return client.get('order/get-organization-order-note', { params });
  }
  static storeOrderNote(request) {
    return client.post('order/store-organization-order-note', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static deleteOrderNote(params) {
    return client.delete('order/delete-organization-order-note', { params });
  }
  static storeOrderChargeData(request) {
    return client.put('order/store-organization-order-charge', request);
  }
  static storeOrderRefundData(request) {
    return client.put('order/store-organization-order-refund', request);
  }
  static getOrderTransactionData(params) {
    return client.get('order/organization-order-transaction', { params });
  }
  static getPatientOrderCard(params) {
    return client.get('order/get-patient-card', { params });
  }
  static createPrescription(params) {
    return client.get('order/place-order-pharmacy', { params });
  }
  static orderItemRxStatusChange(params) {
    return client.get('order/order-item-rx-status-change', { params });
  }
  static storeOrderFillRequestData(request) {
    return client.put('order/place-order-pharmacy-fill', request);
  }
  static organizationOrderSlotBooking(request) {
    return client.put('order/organization-order-slot-book', request);
  }
  static organizationBookingDetails(params) {
    return client.get('order/get-organization-booking-details', { params });
  }
  static rescheduleOrderBookingOrganization(request) {
    return client.put(`order/organization-reschedule-order-booking`, request);
  }
  static organizationAppointmentList(params) {
    return client.get('order/get-organization-appointment-list', { params });
  }
  static orderAssignToStaff(request) {
    return client.put('order/order-assign-doctor', request);
  }
  static getPrescriptionValidities(params) {
    return client.get('order/get-prescription-validities', { params });
  }
  static cancelOrder(request) {
    return client.put(`order/order-cancel`, request);
  }
  static placeOrderToCarePoint(request) {
    return client.put('order/order-place-carepoint', request);
  }

  static getAllOrderListData(params) {
    return client.get('/order/all-orders', { params });
  }

  static getAllOrderDetails(params) {
    return client.get('/order/get-all-organization-order-detail', { params });
  }

  static getAllOrgOrderDownloadData(params) {
    return client.get('/order/all-organization-orders-download', { params });
  }
}
export { OrderServices };
