import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { formatPhoneNumber } from 'helpers';

const PhoneNumberInput = (props) => {
  return (
    <Form.Control
      {...props}
      type="text"
      onInput={(e) => {
        // var x = e.target.value.replace(/\D/g, '').match(/^\(\d{3}\) \d{3}-\d{4}$/);
        // e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

        var value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
        var formattedValue = '';

        // Check for the different possible formats and apply the mask
        if (value.length > 0) {
          formattedValue = '(' + value.substring(0, 3);
          if (value.length > 3) {
            formattedValue += ') ' + value.substring(3, 6);
            if (value.length > 6) {
              formattedValue += '-' + value.substring(6, 10);
            }
          }
        }
        e.target.value = formattedValue;
      }}
      value={formatPhoneNumber(props.value)}
    />
  );
};
PhoneNumberInput.propTypes = {
  value: PropTypes.any,
};
export default PhoneNumberInput;
