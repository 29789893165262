import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { OrderServices } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for user Question response view
 */

const useGetOrderListData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`orders-list-view`, [params]], () => OrderServices.getOrderListData(params), {
    onSuccess,
    onError,
    enabled: params.is_enabled,
  });
};
const useGetOrderDownloadData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.getOrderDownloadData, {
    onSuccess,
    onError,
  });
};
const useGetOrderDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`order-view`, [params]], () => OrderServices.getOrderDetails(params), {
    onSuccess,
    onError,
    enabled: params.is_enabled,
  });
};
const useGetIntakeResponse = (order_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`intake-response-view`, order_id],
    () => OrderServices.getIntakeResponseData({ order_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetMedicalResponse = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`medical-response-view`, [params]],
    () => OrderServices.getMedicalResponseData(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetOrderNotes = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`order-notes`, [params]], () => OrderServices.getOrderNotes(params), {
    onSuccess,
    onError,
  });
};
const useStoreOrderNote = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.storeOrderNote, {
    onSuccess,
    onError,
  });
};
const useDeleteOrderNote = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.deleteOrderNote, {
    onSuccess,
    onError,
  });
};

const useStoreOrderRefundData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.storeOrderRefundData, {
    onSuccess,
    onError,
  });
};
const useStoreOrderChargeData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.storeOrderChargeData, {
    onSuccess,
    onError,
  });
};
const useGetOrderTransaction = (order_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`order-transactions`, order_id],
    () => OrderServices.getOrderTransactionData({ order_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPatientOrderCard = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`order-patient-card`, [params]],
    () => OrderServices.getPatientOrderCard(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useCreatePrescription = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.createPrescription, {
    onSuccess,
    onError,
  });
};
const useOrderItemRxStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.orderItemRxStatusChange, {
    onSuccess,
    onError,
  });
};
const useStoreOrderFillRequestData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.storeOrderFillRequestData, {
    onSuccess,
    onError,
  });
};
const useOrganizationOrderSlotBooking = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.organizationOrderSlotBooking, {
    onSuccess,
    onError,
  });
};
const useGetOrgBookingDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.organizationBookingDetails, {
    onSuccess,
    onError,
  });
};
const useOrderBookingReschedule = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.rescheduleOrderBookingOrganization, {
    onSuccess,
    onError,
  });
};

const useAppointmentList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-organization-appointment-list`, [params]],
    () => OrderServices.organizationAppointmentList(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useOrderAssignToStaff = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.orderAssignToStaff, {
    onSuccess,
    onError,
  });
};
const userGetPrescriptionValidities = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.getPrescriptionValidities, {
    onSuccess,
    onError,
  });
};

const useCancelOrder = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.cancelOrder, {
    onSuccess,
    onError,
  });
};
const usePlaceOrderToCarePoint = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.placeOrderToCarePoint, {
    onSuccess,
    onError,
  });
};

const useGetAllOrderListData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`all-orders-list-view`, [params]],
    () => OrderServices.getAllOrderListData(params),
    {
      onSuccess,
      onError,
      enabled: params.is_enabled,
    }
  );
};

const useGetAllOrderDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`all-order-view`, [params]], () => OrderServices.getAllOrderDetails(params), {
    onSuccess,
    onError,
    enabled: params.is_enabled,
  });
};

const useGetAllOrgOrderDownloadData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderServices.getAllOrgOrderDownloadData, {
    onSuccess,
    onError,
  });
};

export {
  useGetOrderListData,
  useGetIntakeResponse,
  useGetMedicalResponse,
  useGetOrderDetails,
  useGetOrderNotes,
  useStoreOrderNote,
  useDeleteOrderNote,
  useStoreOrderRefundData,
  useStoreOrderChargeData,
  useGetOrderTransaction,
  useGetPatientOrderCard,
  useCreatePrescription,
  useOrderItemRxStatusChange,
  useStoreOrderFillRequestData,
  useOrganizationOrderSlotBooking,
  useGetOrgBookingDetails,
  useOrderBookingReschedule,
  useAppointmentList,
  useOrderAssignToStaff,
  userGetPrescriptionValidities,
  useGetOrderDownloadData,
  useCancelOrder,
  usePlaceOrderToCarePoint,
  useGetAllOrderListData,
  useGetAllOrderDetails,
  useGetAllOrgOrderDownloadData,
};
