import client from 'libs/HttpClient';

class PatientChartServices {
  static getPatientOrders(params) {
    return client.get('patient-chart/get-all-orders', { params });
  }
  static patientAllNotes(params) {
    return client.get(`patient-chart/get-all-notes`, { params });
  }
  static getGeneralDetails(params) {
    return client.get(`patient-chart/get-general-details`, { params });
  }
  static insuranceDetails(params) {
    return client.get(`patient-chart/get-insurance-details`, { params });
  }
  static getBasicDetails(params) {
    return client.get(`patient-chart/get-patient-details`, { params });
  }
  static getPatientAllOrders(params) {
    return client.get('patient-chart/get-orders', { params });
  }
  static storeOrderNote(request) {
    return client.post('patient-chart/store-organization-order-note', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static downloadPatientChart(params) {
    return client.get('common/chart/download/front/' + params.user_id, {
      params,
    });
  }
}
export { PatientChartServices };
