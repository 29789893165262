import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import CustomSelect from 'react-select';

const CustomInput = ({
  type = 'text', // Type of input (text, password, select, etc.)
  name,
  label,
  placeholder,
  options = [], // For select, checkbox, or radio
  isPasswordField = false, // Show password toggle
  className = '',
  classNameIcon = '',
  inputGroupText = null,
  touched = {},
  errors = {},
  children,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  // Determine the input type for password visibility toggle
  const inputTypeField = isPasswordField && showPassword ? 'text' : type;

  // Get class based on validation state
  const getClass = () => {
    if (_.get(touched, name) && !_.get(errors, name)) {
      return `${className} ${type === 'password' ? 'icon-field-success' : 'input-field-success'}`;
    }
    if (_.get(touched, name) && _.get(errors, name)) {
      return `${className} ${type === 'password' ? 'icon-field-error' : 'input-field-error'}`;
    }
    return className;
  };

  const getInputGroupClass = () => {
    if (_.get(touched, name) && _.get(errors, name)) {
      return `${classNameIcon} input-icon-error`;
    }
    if (_.get(touched, name) && !_.get(errors, name)) {
      return `${classNameIcon} input-icon-success`;
    }
    return classNameIcon;
  };

  // Render based on input type
  const renderInputField = () => {
    switch (type) {
      case 'select':
        return <CustomSelect {...props} name={name} id={name} className={getClass()} />;
      case 'textarea':
        return (
          <Form.Control
            {...props}
            as="textarea"
            name={name}
            id={name}
            placeholder={placeholder}
            className={getClass()}
          />
        );
      case 'checkbox':
        return options.map((option, index) => (
          <Form.Check
            {...props}
            type="checkbox"
            key={index}
            id={`${name}-${index}`}
            label={option.label}
            name={name}
            value={option.value}
            className={getClass()}
          />
        ));
      case 'radio':
        return options.map((option, index) => (
          <Form.Check
            {...props}
            type="radio"
            key={index}
            id={`${name}-${index}`}
            label={option.label}
            name={name}
            value={option.value}
            className={getClass()}
          />
        ));
      case 'switch':
        return (
          <Form.Check
            {...props}
            type="switch"
            id={`${name}`}
            label={label}
            name={name}
            className={getClass()}
          />
        );
      case 'number':
        return (
          <Form.Control
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            {...props}
            name={name}
            id={name}
            type={'text'}
            placeholder={placeholder}
            className={getClass()}
          />
        );
      case 'password':
      case 'text':
      case 'email':
      default:
        return isPasswordField || inputGroupText ? (
          <InputGroup>
            <Form.Control
              {...props}
              name={name}
              id={name}
              type={inputTypeField}
              placeholder={placeholder}
              className={getClass()}
            />
            <InputGroup.Text className={getInputGroupClass()}>
              {inputGroupText ? (
                inputGroupText
              ) : (
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        ) : (
          <Form.Control
            {...props}
            name={name}
            id={name}
            type={type}
            placeholder={placeholder}
            className={getClass()}
          />
        );
    }
  };

  return (
    <Form.Group className="">
      {label && (
        <Form.Label htmlFor={name} className="form-label">
          {label}
        </Form.Label>
      )}
      {renderInputField()}
      {/* Error Message */}
      {_.get(touched, name) && _.get(errors, name) && (
        <div className="text-danger">{_.get(errors, name)}</div>
      )}
      {children && <div>{children}</div>}
    </Form.Group>
  );
};

CustomInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  isPasswordField: PropTypes.bool,
  className: PropTypes.string,
  classNameIcon: PropTypes.string,
  inputGroupText: PropTypes.node,
  touched: PropTypes.object,
  errors: PropTypes.object,
  children: PropTypes.node,
};
const Text = (props) => <CustomInput type="text" {...props} />;
const Number = (props) => <CustomInput type="number" {...props} />;
const Password = (props) => <CustomInput type="password" isPasswordField {...props} />;
const Select = (props) => <CustomInput type="select" {...props} />;
const TextArea = (props) => <CustomInput type="textarea" {...props} />;
const Radio = (props) => <CustomInput type="radio" {...props} />;
const Checkbox = (props) => <CustomInput type="checkbox" {...props} />;
const Switch = (props) => <CustomInput type="switch" {...props} />;
CustomInput.Text = Text;
CustomInput.Password = Password;
CustomInput.Select = Select;
CustomInput.TextArea = TextArea;
CustomInput.Radio = Radio;
CustomInput.Checkbox = Checkbox;
CustomInput.Switch = Switch;
CustomInput.Number = Number;

export default CustomInput;
