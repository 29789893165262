import client from 'libs/HttpClient';
class CouponCodeSettingsService {
  static getAllCoupons(params) {
    return client.get('coupon-codes/list-coupons', { params });
  }
  static addCoupons(request) {
    return client.post('coupon-codes/add-coupon-code', request);
  }
  static updateCoupons(request) {
    return client.put(`coupon-codes/update-coupons/`, request);
  }
  static viewCoupons(params) {
    return client.get(`coupon-codes/get-coupon-code`, { params });
  }
  static updateCouponStatus(request) {
    return client.put(`coupon-codes/update-status/`, request);
  }
  static deleteCoupon(params) {
    return client.delete(`coupon-codes/delete`, { params });
  }
}

export { CouponCodeSettingsService };
