import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { OrganizationHomePageSettingsServices } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * General Page settings banner
 */
const useViewGeneralData = (onSuccess, onError = onDefaultError) => {
  return useQuery('general)data', OrganizationHomePageSettingsServices.viewGeneralData, {
    onSuccess,
    onError,
  });
};
const useUpdateGeneralSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateGeneralSettings, {
    onSuccess,
    onError,
  });
};
/**
 * Button Page settings banner
 */
const useViewButtonData = (onSuccess, onError = onDefaultError) => {
  return useQuery('button_data', OrganizationHomePageSettingsServices.viewButtonData, {
    onSuccess,
    onError,
  });
};
const useUpdateButtonSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateButtonSettings, {
    onSuccess,
    onError,
  });
};

/**
 * Home Redirect Page settings banner
 */
const useViewHomeRedirectData = (onSuccess, onError = onDefaultError) => {
  return useQuery('Home-Redirect-data', OrganizationHomePageSettingsServices.viewHomeRedirectData, {
    onSuccess,
    onError,
  });
};
const useUpdateHomeRedirectSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateHomeRedirectSettings, {
    onSuccess,
    onError,
  });
};
/**
 * Home Page settings banner
 */
const useViewHomeBannerData = (onSuccess, onError = onDefaultError) => {
  return useQuery('home_banner', OrganizationHomePageSettingsServices.viewHomeBannerData, {
    onSuccess,
    onError,
  });
};
const useUpdateHomeBannerSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateHomeBannerSettings, {
    onSuccess,
    onError,
  });
};
/**
 * Home Page Who we are Section Hooks
 */
const useViewHomeWhoWeAreData = (onSuccess, onError = onDefaultError) => {
  return useQuery('who_we_are', OrganizationHomePageSettingsServices.viewHomeWhoWeAreData, {
    onSuccess,
    onError,
  });
};
const useUpdateHomeWhoWeAreSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateHomeWhoWeAreSettings, {
    onSuccess,
    onError,
  });
};
/**
 * Home Page Client Section Hooks
 */
const useViewClientsData = (onSuccess, onError = onDefaultError) => {
  return useQuery('client_data', OrganizationHomePageSettingsServices.viewHomeClientData, {
    onSuccess,
    onError,
  });
};
const useUpdateClientsSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateHomeClientSettings, {
    onSuccess,
    onError,
  });
};
/**
 * Home Page Get In Touch and Footer Section Hooks
 */
const useViewGetInTouchData = (onSuccess, onError = onDefaultError) => {
  return useQuery('get_in_touch', OrganizationHomePageSettingsServices.viewHomeGetInTouchData, {
    onSuccess,
    onError,
  });
};
const useUpdateGetInTouchSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateHomeGetInTouchSettings, {
    onSuccess,
    onError,
  });
};
/**
 * Home Page settings banner
 */
const useViewHomeSeoData = (onSuccess, onError = onDefaultError) => {
  return useQuery('organization_seo', OrganizationHomePageSettingsServices.viewHomeSeoData, {
    onSuccess,
    onError,
  });
};
const useUpdateHomeSeoSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationHomePageSettingsServices.updateHomeSeoSettings, {
    onSuccess,
    onError,
  });
};
export {
  useViewHomeBannerData,
  useUpdateHomeBannerSettings,
  useViewHomeWhoWeAreData,
  useUpdateHomeWhoWeAreSettings,
  useViewClientsData,
  useUpdateClientsSettings,
  useViewGetInTouchData,
  useUpdateGetInTouchSettings,
  useViewHomeSeoData,
  useUpdateHomeSeoSettings,
  useViewGeneralData,
  useUpdateGeneralSettings,
  useViewHomeRedirectData,
  useUpdateHomeRedirectSettings,
  useViewButtonData,
  useUpdateButtonSettings,
};
