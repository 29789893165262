import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { settingData } from 'store';
import { useCheckMobileScreen, useCheckTabletScreen } from '../../Components/useCheckMobileScreen';
import { getContactUsCmsSlug, getPrivacyCmsSlug, getTermsCmsSlug } from 'store';
const OtherPageFooter = ({ t }) => {
  let about_cms_slug = useSelector(getContactUsCmsSlug);
  let term_cms_slug = useSelector(getTermsCmsSlug);
  let privacy_cms_slug = useSelector(getPrivacyCmsSlug);

  const getSettingData = useSelector(settingData);
  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabletScreen();
  return (
    <div
      className="other-footer-container"
      style={{
        background: isMobile ? '#4D8481' : 'transparent',
      }}>
      <Container fluid className="footer-container other-footer">
        {!isMobile && (
          <svg
            width="1920"
            height="160"
            viewBox={isTablet ? '0 0 1920 160' : '0 0 1920 100'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '100%', objectFit: 'cover', objectPosition: 'center center' }}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 108.872H78.8406C162.319 108.872 320 108.872 482.319 120.969C640 130.647 802.319 150.002 960 145.163C1122.32 140.324 1280 108.872 1442.32 94.3561C1600 79.8398 1762.32 79.8398 1841.16 79.8398H1920V229.842H1841.16C1762.32 229.842 1600 229.842 1442.32 229.842C1280 229.842 1122.32 229.842 960 229.842C802.319 229.842 640 229.842 482.319 229.842C320 229.842 162.319 229.842 78.8406 229.842H0V108.872Z"
              fill="#4D8481"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 30.4479L78.8406 40.1255C162.319 49.803 320 71.5774 482.319 86.0937C640 100.61 802.319 110.288 960 90.9325C1122.32 71.5774 1280 20.7704 1442.32 6.25411C1600 -10.6816 1762.32 11.0929 1841.16 20.7704L1920 30.4479V180.45H1841.16C1762.32 180.45 1600 180.45 1442.32 180.45C1280 180.45 1122.32 180.45 960 180.45C802.319 180.45 640 180.45 482.319 180.45C320 180.45 162.319 180.45 78.8406 180.45H0V30.4479Z"
              fill="#4D8481"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 51.726L80 46.7239C160 41.7218 320 31.7176 480 46.7239C640 61.7302 800 101.747 960 96.7449C1120 91.7428 1280 41.7218 1440 36.7197C1600 31.7176 1760 71.7344 1840 91.7428L1920 111.751V171.776H1840C1760 171.776 1600 171.776 1440 171.776C1280 171.776 1120 171.776 960 171.776C800 171.776 640 171.776 480 171.776C320 171.776 160 171.776 80 171.776H0V51.726Z"
              fill="url(#paint0_linear_2449_8067)"
              fillOpacity="0.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2449_8067"
                x1="960"
                y1="36.291"
                x2="960"
                y2="171.776"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#4D8481" />
                <stop offset="1" stopColor="#4D8481" />
              </linearGradient>
            </defs>
          </svg>
        )}
        <div className="other-footer-content">
          <div className="other-footer-content-details container">
            <div className="copy-right-section copy-right">
              <p className="copy-right-section-para mb-0">
                &#169; {getSettingData.home_page_general_copyright_text}
                <span className="dbt copyright-hover ps-2">
                  Developed By
                  <Link
                    to="https://www.technource.com/"
                    target="_blank"
                    className="tooltiplink"
                    rel="noopener noreferrer"
                    data-title="Web App | Mobile App | Custom Software | AI Development">
                    &nbsp; Technource
                  </Link>
                </span>
              </p>
            </div>
            <div className="scroll-top links">
              <Link to={`/page/${about_cms_slug}`}>
                <span>{t('front.other_footer_link_about')} </span>
              </Link>
              <Link to={`/page/${privacy_cms_slug}`}>
                <span>{t('front.other_footer_link_policy')} </span>
              </Link>
              <Link to={`/page/${term_cms_slug}`}>
                <span>{t('front.other_footer_link_terms')} </span>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
OtherPageFooter.propTypes = {
  t: PropTypes.func,
};
export { OtherPageFooter };
