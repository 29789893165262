import React from 'react';
import PropTypes from 'prop-types';

const TimeCounterDown = ({ endEvent }) => {
  const [counter, setCounter] = React.useState(60);

  React.useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter <= 1) {
            clearInterval(timer);
            endEvent();
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);

      // Clear interval on component unmount or when counter changes
      return () => clearInterval(timer);
    }
  }, [counter, endEvent]);

  return (
    <div id="countdown" className="d-inline-flex">
      <div className="counter" data-count="seconds">
        <span data-b="--">{counter}</span>
      </div>
    </div>
  );
};
TimeCounterDown.propTypes = {
  endEvent: PropTypes.any,
};
export default TimeCounterDown;
