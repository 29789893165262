import { SketchPicker } from 'react-color';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';

const TNColorPicker = ({ color, onChange, labelColor, colorKey, currentPicker, setPicker }) => {
  const isPickerOpen = currentPicker === colorKey;
  const styles = reactCSS({
    default: {
      button: {
        backgroundColor: '#4d8481',
        fontSize: '18px',
        borderRadius: '25px',
        fontWeight: '700',
        color: '#fff',
        border: '1px solid #4d8481',
        padding: '0.5rem 2rem',
        marginTop: '2rem !important',
      },
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
      },
      swatch: {
        height: '22px',
        display: 'inline-block',
        cursor: 'pointer',
        border: '1px solid #bebebe',
        backgroundColor: 'none',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });
  const handleClick = () => {
    if (isPickerOpen) {
      setPicker(null); // Close picker
    } else {
      setPicker(colorKey); // Open the respective picker
    }
  };

  return (
    <Form.Group className="color-form-group color-form-banner">
      <Form.Label className="color-form-label mb-2">{labelColor}:</Form.Label>
      <div
        style={{
          ...styles.swatch,
          ...{
            backgroundColor: `${color}`,
          },
        }}
        className="color-form-input form-control-color form-control mb-2"
        onClick={handleClick}
      />
      {isPickerOpen && (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={() => setPicker(null)} // Close picker when clicking outside
          />
          <SketchPicker color={color} onChange={(color) => onChange(color.hex)} />
        </div>
      )}
    </Form.Group>
  );
};
TNColorPicker.propTypes = {
  color: PropTypes.any,
  onChange: PropTypes.any,
  labelColor: PropTypes.any,
  colorKey: PropTypes.any,
  currentPicker: PropTypes.any,
  setPicker: PropTypes.any,
};

export default TNColorPicker;
