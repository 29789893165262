import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { StaffServices } from 'api';

/**
 * Hook for  Organization Staff
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListStaff = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`list-staff`, [params]], () => StaffServices.listStaff(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useAddStaff = (onSuccess, onError = onDefaultError) => {
  return useMutation(StaffServices.staffAdd, {
    onSuccess,
    onError,
  });
};
const useStaffStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(StaffServices.updateStatusStaff, {
    onSuccess,
    onError,
  });
};
const useUpdateStaff = (onSuccess, onError = onDefaultError) => {
  return useMutation(StaffServices.updateStaff, {
    onSuccess,
    onError,
  });
};
const useViewStaff = (user_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['get-staff', user_id], () => StaffServices.viewStaff({ user_id }), {
    onSuccess,
    onError,
  });
};
const useDeleteStaff = (onSuccess, onError = onDefaultError) => {
  return useMutation(StaffServices.deleteStaff, {
    onSuccess,
    onError,
  });
};
const useStoreStaffGeneralInfoData = (onSuccess, onError = onDefaultError) => {
  return useMutation(StaffServices.storeStaffGeneralInfoData, {
    onSuccess,
    onError,
  });
};
const useGetStaffGeneralData = (user_id, onSuccess, onError = onDefaultError) => {
  return useQuery('`get-staff-general-info', () => StaffServices.getStaffGeneralData({ user_id }), {
    onSuccess,
    onError,
  });
};
const useGetStaffDoctorList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-staff-and-doctor-list', [params]],
    () => StaffServices.getStaffAndDoctorList(params),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListStaff,
  useAddStaff,
  useStaffStatusChange,
  useUpdateStaff,
  useViewStaff,
  useDeleteStaff,
  useStoreStaffGeneralInfoData,
  useGetStaffGeneralData,
  useGetStaffDoctorList,
};
