import React, { useRef, useState } from 'react';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faXmark } from '@fortawesome/free-solid-svg-icons';

import {
  dateFormatCommon,
  decryptValues,
  encryptValues,
  imagePreviewFromik,
  toBase64,
} from 'helpers';
import TNDatePicker from './TNDatePicker';

const QuestionAnswer = ({ t, answer, index, setDateValue, attachmentNameRef }) => {
  const { values, setFieldValue, handleChange, handleBlur, errors, touched } = useFormikContext();
  const sigCanvas = useRef();
  const [show, setShow] = useState(false);
  const [penColor, setPenColor] = useState('black');
  const colors = ['black', '#4d8481', 'blue'];

  const handleAttachmentName = (index) => {
    attachmentNameRef.current[index]?.click();
  };
  const questionOPErrors = errors?.answers?.[index]?.ans_value || '';
  const questionOPTouched = touched?.answers?.[index]?.ans_value || '';
  const questionOptionErrors = errors?.answers?.[index]?.user_question_ans_option || '';
  const questionOptionTouched = touched?.answers?.[index]?.user_question_ans_option || '';
  const optionValue =
    answer.user_question_ans_option &&
    answer.user_question_ans_option.map((option) => {
      return {
        value: option.question_option_id,
        label: option.option_value,
      };
    });
  /**
   * This object contains styles
   */
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      border: '1.5px solid #4D8481',
      minHeight: '50px',
      padding: '5px',
      borderRadius: '16.3333px',
      maxWidth: '60%',
    }),
  };
  return (
    <>
      {![9, 10].includes(answer.question_type) && (
        <Form.Label className={`form-field-top`}>
          <span> {answer.label + (answer.is_required === 1 ? ' *' : ' (optional)')}</span>
        </Form.Label>
      )}
      {answer.question_type === 1 && (
        <Form.Control
          placeholder={answer.label}
          name={`answers.${index}.ans_value`}
          type="text"
          className={
            'auth-input-field step-input-field ' +
            (questionOPTouched && questionOPErrors
              ? 'input-field-error'
              : questionOPTouched && !questionOPErrors
              ? 'input-field-success'
              : '')
          }
          value={values.answers[index].ans_value}
          onChange={(e) => {
            if (e.currentTarget.value !== '') {
              setFieldValue(`answers.${index}.is_delete`, 1);
              setFieldValue(`answers.${index}.is_new`, 2);
            } else {
              setFieldValue(`answers.${index}.is_delete`, 2);
              setFieldValue(`answers.${index}.is_new`, 1);
            }
            handleChange(e);
          }}
          onBlur={handleBlur}
        />
      )}
      {answer.question_type === 2 && (
        <Form.Control
          placeholder={answer.label}
          name={`answers.${index}.ans_value`}
          type="text"
          className={
            ' step-input-field ' +
            (questionOPTouched && questionOPErrors
              ? 'input-field-error'
              : questionOPTouched && !questionOPErrors
              ? 'input-field-success'
              : '')
          }
          value={values.answers[index].ans_value}
          onChange={(e) => {
            if (e.currentTarget.value !== '') {
              setFieldValue(`answers.${index}.is_delete`, 1);
              setFieldValue(`answers.${index}.is_new`, 2);
            } else {
              setFieldValue(`answers.${index}.is_delete`, 2);
              setFieldValue(`answers.${index}.is_new`, 1);
            }
            handleChange(e);
          }}
          onBlur={handleBlur}
          as="textarea"
        />
      )}
      {answer.question_type === 3 && (
        <>
          <Select
            name={`answers.${index}.user_question_ans_option.${index}`}
            options={optionValue}
            styles={selectStyles}
            value={values.answers[index].user_question_ans_option
              .filter((value) => {
                return value.is_new == 2;
              })
              .map((value) => {
                return {
                  value: value.question_option_id,
                  label: value.option_value,
                };
              })}
            onChange={(e) => {
              if (e.value !== '') {
                values.answers[index].user_question_ans_option.map((value, i) => {
                  value.question_option_id == e.value
                    ? setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_delete`, 1)
                    : setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_delete`, 2);
                  value.question_option_id == e.value
                    ? setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_new`, 2)
                    : setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_new`, 1);

                  return value;
                });
                setFieldValue(`answers.${index}.is_new`, 2);
                setFieldValue(`answers.${index}.is_delete`, 1);
              } else {
                setFieldValue(`answers.${index}.is_new`, 1);
                setFieldValue(`answers.${index}.is_delete`, 2);
              }
            }}
            onBlur={handleBlur}
          />
          <div className="form-field-error-text">
            {questionOptionTouched &&
            questionOptionErrors &&
            typeof questionOptionErrors === 'string' ? (
              <div className="input-error-message">{t(questionOptionErrors)}</div>
            ) : null}
          </div>
        </>
      )}
      {answer.question_type === 4 && (
        <>
          {answer.user_question_ans_option &&
            answer.user_question_ans_option.map((option, i) => (
              <Form.Check
                key={option.question_option_id}
                name={`answers.${index}.user_question_ans_option.${i}.question_option_id`}
                type="radio"
                label={option.option_value}
                className={'step-input-field'}
                checked={option.is_new == 2 ? true : false}
                value={option.question_option_id}
                onChange={(e) => {
                  if (e.currentTarget.value !== '') {
                    values.answers[index].user_question_ans_option.map((value, i) => {
                      value.question_option_id == e.currentTarget.value
                        ? setFieldValue(
                            `answers.${index}.user_question_ans_option.${i}.is_delete`,
                            1
                          )
                        : setFieldValue(
                            `answers.${index}.user_question_ans_option.${i}.is_delete`,
                            2
                          );
                      value.question_option_id == e.currentTarget.value
                        ? setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_new`, 2)
                        : setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_new`, 1);

                      return value;
                    });
                    setFieldValue(`answers.${index}.is_new`, 2);
                    setFieldValue(`answers.${index}.is_delete`, 1);
                  } else {
                    setFieldValue(`answers.${index}.is_new`, 1);
                    setFieldValue(`answers.${index}.is_delete`, 2);
                  }
                }}
              />
            ))}
          <div className="form-field-error-text">
            {questionOptionTouched &&
            questionOptionErrors &&
            typeof questionOptionErrors === 'string' ? (
              <div className="input-error-message">{t(questionOptionErrors)}</div>
            ) : null}
          </div>
        </>
      )}
      {answer.question_type === 5 && (
        <>
          {answer.user_question_ans_option &&
            answer.user_question_ans_option.map((option, i) => (
              <Form.Check
                key={option.question_option_id}
                name={`answers.${index}.user_question_ans_option.${i}.question_option_id`}
                label={option.option_value}
                checked={option.is_new == 2 ? true : false}
                className={'step-input-field'}
                value={option.question_option_id}
                onChange={(e) => {
                  if (e.currentTarget.checked === true) {
                    setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_delete`, 1);
                    setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_new`, 2);
                    setFieldValue(`answers.${index}.is_new`, 2);
                    setFieldValue(`answers.${index}.is_delete`, 1);
                  } else {
                    setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_delete`, 2);
                    setFieldValue(`answers.${index}.user_question_ans_option.${i}.is_new`, 1);
                    setFieldValue(`answers.${index}.is_new`, 1);
                    setFieldValue(`answers.${index}.is_delete`, 2);
                  }
                }}
              />
            ))}
          <div className="form-field-error-text">
            {questionOptionTouched &&
            questionOptionErrors &&
            typeof questionOptionErrors === 'string' ? (
              <div className="input-error-message">{t(questionOptionErrors)}</div>
            ) : null}
          </div>
        </>
      )}
      {answer.question_type === 6 && (
        <>
          <Form.Control
            name={`answers.${index}.ans_value`}
            type="file"
            ref={(el) => (attachmentNameRef.current[index] = el)}
            accept=".png,.jpg,.jpeg,.pdf"
            className={'step-input-field d-none'}
            onChange={(event) => {
              toBase64(event.currentTarget.files[0]).then((result) => {
                setFieldValue(`answers.${index}.ans_value`, result);
                setFieldValue(`answers.${index}.is_new`, 2);
                setFieldValue(`answers.${index}.is_delete`, 1);
              });
            }}
          />
          <br />
          <Button
            type="button"
            className="upload-button"
            onClick={() => handleAttachmentName(index)}>
            {t('front.file_upload_button_text')}
          </Button>
          <div className="preview-image">
            {values?.answers[index]?.ans_value && (
              <div>
                <img
                  src={imagePreviewFromik(values?.answers[index]?.ans_value)}
                  width="100px"
                  alt="profile_img"
                />
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => {
                    setFieldValue(`answers.${index}.ans_value`, '');
                    setFieldValue(`answers.${index}.is_new`, 1);
                    setFieldValue(`answers.${index}.is_delete`, 2);
                    attachmentNameRef.current[index].value = null;
                  }}
                />
              </div>
            )}
          </div>
          <div className="form-field-error-text">
            {touched.answers?.i && errors.answers?.i ? (
              <div className="input-error-message">{t(errors.answers?.i)}</div>
            ) : null}
          </div>
        </>
      )}
      {answer.question_type === 7 && (
        <TNDatePicker
          placeholderText={answer.label}
          name={`answers.${index}.ans_value`}
          className={
            ' inner-page-inputs ' +
            (questionOPTouched && questionOPErrors
              ? 'input-field-error'
              : questionOPTouched && !questionOPErrors
              ? 'input-field-success'
              : '')
          }
          selected={
            values.answers[index].ans_value &&
            new Date(`${values.answers[index].ans_value.replace(/-/, '/').replace(/-/, '/')}`)
          }
          onMonthChange={setDateValue}
          dateFormat={dateFormatCommon()}
          maxDate={new Date()}
          // value={values.answers[index].ans_value}
          onYearChange={setDateValue}
          onBlur={(event) => {
            console.log(event, 'onBlur', 'datepicker');
          }}
          onSelect={setDateValue}
          onChange={setDateValue}
        />
      )}
      {answer.question_type === 9 && (
        <Form.Check
          name={`answers.${index}.ans_value`}
          checked={values.answers[index].ans_value == '1' ? true : false}
          type={'checkbox'}
          className="form-field-top form-label">
          <Form.Check.Input
            name={`answers.${index}.ans_value`}
            type={'checkbox'}
            checked={values.answers[index].ans_value == '1' ? true : false}
            onChange={(e) => {
              console.log('checked', e.currentTarget.checked);
              if (e.currentTarget.checked === true) {
                setFieldValue(`answers.${index}.ans_value`, '1');
                setFieldValue(`answers.${index}.is_delete`, 1);
                setFieldValue(`answers.${index}.is_new`, 2);
              } else {
                setFieldValue(`answers.${index}.ans_value`);
                setFieldValue(`answers.${index}.is_delete`, 2);
                setFieldValue(`answers.${index}.is_new`, 1);
              }
            }} // className={'step-input-field'}
            value={values.answers[index].ans_value}
          />
          <Form.Check.Label>
            <div
              dangerouslySetInnerHTML={{
                __html: `<span class="question-text">${answer.label}</span><span class='ms-1'>${
                  answer.is_required === 1 ? '*' : '(optional)'
                } </span> `,
              }}
            />
          </Form.Check.Label>
        </Form.Check>
      )}
      {answer.question_type === 10 && (
        <>
          <Row>
            <Col md={6} className="pe-0">
              <Form.Label className={`form-field-top`}>
                {answer.label + (answer.is_required === 1 ? ' *' : ' (optional)')}
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Label className={`form-field-top`}>
                {values.answers[index].ans_value ? (
                  <>
                    <div className="preview-image ms-2 preview-signature-image">
                      <Image
                        fluid
                        src={decryptValues(values.answers[index].ans_value)}
                        alt="Add your signature"
                        className="sigCanvas form-control"
                      />
                      <FontAwesomeIcon
                        className="svg-inline--fa "
                        icon={faXmark}
                        onClick={() => {
                          setTimeout(() => {
                            setFieldValue(`answers.${index}.ans_value`, '');
                            setShow(false);
                          }, 500);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <Button onClick={() => setShow(true)} className="btn-small ms-2 btn-signature">
                    {t('front.add_signature_button_text')}
                  </Button>
                )}
              </Form.Label>
            </Col>
          </Row>
          <Modal
            show={show}
            onHide={() => {
              setShow(false);
              setPenColor('black');
            }}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body className="text-center">
              <div className="sigPadContainer">
                <SignatureCanvas
                  penColor={penColor}
                  canvasProps={{ className: 'sigCanvas' }}
                  ref={sigCanvas}
                />
                <div className="sigPad__penColors">
                  <div>
                    <p className="mb-0">{t('front.signature_color_text')} </p>
                  </div>
                  <div>
                    {colors.map((color) => (
                      <span
                        key={color}
                        style={{
                          backgroundColor: color,
                          border: `${color === penColor ? `2px solid ${color}` : ''}`,
                        }}
                        onClick={() => setPenColor(color)}></span>
                    ))}
                  </div>
                  <Button
                    onClick={() => sigCanvas.current.clear()}
                    variant="secondary"
                    className="btn-medium btn-secondary float-end">
                    {t('front.clear_button_text')}
                  </Button>
                </div>
              </div>
              <Button
                className="create btn-medium me-2 btn-signature"
                variant="primary"
                onClick={() => {
                  const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
                  setFieldValue(`answers.${index}.ans_value`, encryptValues(URL));
                  setFieldValue(`answers.${index}.is_delete`, 1);
                  setFieldValue(`answers.${index}.is_new`, 2);
                  setShow(false);
                }}>
                {t('front.org_save_button_text')}
              </Button>
              <Button
                onClick={() => {
                  setShow(false);
                  setPenColor('black');
                }}
                variant="secondary"
                className="btn-medium btn-secondary">
                {t('front.org_cancel_button_text')}
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}
      <div className="form-field-error-text">
        {questionOPTouched && questionOPErrors ? (
          <div className="input-error-message">{t(questionOPErrors)}</div>
        ) : null}
      </div>
    </>
  );
};
QuestionAnswer.propTypes = {
  t: PropTypes.func,
  answer: PropTypes.any,
  index: PropTypes.any,
  attachmentNameRef: PropTypes.any,
  setDateValue: PropTypes.any,
};
export default QuestionAnswer;
