import { Container, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { LoadingShimmer } from 'common';

const CommonAuthShimmer = ({ t, type }) => {
  const message = (type) => {
    if (type === 'otp') return t('front.auth_otp_verification_text');
    if (type === 'reset') return t('front.auth_reset_password_text');
    if (type === 'set') return t('front.auth_reset_password_text');
    if (type === 'forgot') return t('front.auth_forget_password_text');
    if (type === 'login') return t('front.auth_login_text');
    if (type === 'signup') return t('front.auth_signup_text');
  };
  return (
    <Container fluid className="auth-main-container">
      <div className="auth-inner-container">
        <h2 className="auth-heading heading-text">{message(type)}</h2>
        <div className="text-center mt-5">
          <Form>
            <LoadingShimmer></LoadingShimmer>
          </Form>
        </div>
      </div>
    </Container>
  );
};
CommonAuthShimmer.propTypes = {
  t: PropTypes.func,
  type: PropTypes.any,
};
export default CommonAuthShimmer;
