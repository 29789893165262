import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CommonService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks to remove file
 */
const useRemoveFile = (onSuccess, onError = onDefaultError) => {
  return useMutation(CommonService.removeFile, {
    onSuccess,
    onError,
  });
};
const useGetStatesList = (onSuccess, onError = onDefaultError) => {
  return useQuery('states-list', CommonService.getStateList, {
    onSuccess,
    onError,
  });
};
const useGetPharmacyList = (onSuccess, onError = onDefaultError) => {
  return useQuery('pharmacy-list', CommonService.getPharmacyList, {
    onSuccess,
    onError,
  });
};
const useGetPaymentList = (onSuccess, onError = onDefaultError) => {
  return useQuery('payment-list', CommonService.getPaymentList, {
    onSuccess,
    onError,
  });
};
const useGetEmailCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('email-category-list', CommonService.getEmailCategoryList, {
    onSuccess,
    onError,
  });
};
const useGetFrequencyList = (onSuccess, onError = onDefaultError) => {
  return useQuery('frequency-type-list', CommonService.getFrequencyList, {
    onSuccess,
    onError,
  });
};
export {
  useRemoveFile,
  useGetStatesList,
  useGetPharmacyList,
  useGetEmailCategoryList,
  useGetFrequencyList,
  useGetPaymentList,
};
