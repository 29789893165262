/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import usePagination from './usePagination';
import 'assets/scss/components/_pagination.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { ReactComponent as LeftIcon } from 'assets/images/left.svg';
import { ReactComponent as LeftAllIcon } from 'assets/images/left_all.svg';
import { ReactComponent as RightIcon } from 'assets/images/right.svg';
import { ReactComponent as RightAllIcon } from 'assets/images/right_all.svg';
const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, page, pageSize } = props;

  const paginationRange = usePagination({
    page,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (page === 0 || paginationRange.length < 2) {
    return null;
  }
  const onNext = () => {
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };
  const gotoPage = (page) => {
    onPageChange(page);
  };
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <div className="pagination-table text-center">
        <Row className={totalCount === 0 ? 'd-none' : ''}>
          <Col lg={12} xs={12}>
            <div className="table-pagination">
              <Button variant="primary" onClick={() => gotoPage(1)} disabled={page === 1}>
                <LeftAllIcon />
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => onPrevious()} disabled={page === 1}>
                <LeftIcon />
              </Button>
              &nbsp;&nbsp;&nbsp;
              <span>
                Page
                <strong>
                  {page} of {lastPage}
                </strong>
              </span>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => onNext()} disabled={page === lastPage}>
                <RightIcon />
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => gotoPage(lastPage)} disabled={page === lastPage}>
                <RightAllIcon />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.any,
  totalCount: PropTypes.any,
  siblingCount: PropTypes.any,
  page: PropTypes.any,
  pageSize: PropTypes.any,
  className: PropTypes.any,
};
export default Pagination;
