import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { AuthService } from 'api';
import { useSelector } from 'react-redux';
import { loggedUser } from 'store';

/**
 * Hook for user authentication process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useRegister = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.register, {
    onSuccess,
    onError,
  });
};
const useLogin = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.login, {
    onSuccess,
    onError,
  });
};
const useForgotPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.forgotPassword, {
    onSuccess,
    onError,
  });
};

const useOTPVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.otpVerify, {
    onSuccess,
    onError,
  });
};
const useSignUpOTPVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.otpVerifySignUp, {
    onSuccess,
    onError,
  });
};

const useResetPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resetPassword, {
    onSuccess,
    onError,
  });
};
const useSetPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.setPassword, {
    onSuccess,
    onError,
  });
};

const useResendOtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resendOtp, {
    onSuccess,
    onError,
  });
};
const useSignUpResendOtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resendSignUpOtp, {
    onSuccess,
    onError,
  });
};
const useGetProfile = (onSuccess, onError = onDefaultError) => {
  const checkLoggedInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggedInUser.isLoggedIn || false;
  return useQuery('get-profile', AuthService.getProfile, {
    onSuccess,
    onError,
    enabled: isLoggedIn,
  });
};
const useGenerateRefreshToken = (onSuccess, onError = onDefaultError) => {
  return useMutation('generate-refresh-token', AuthService.generateRefreshToken, {
    onSuccess,
    onError,
  });
};

export {
  useLogin,
  useResendOtp,
  useForgotPassword,
  useOTPVerify,
  useResetPassword,
  useRegister,
  useSignUpOTPVerify,
  useSetPassword,
  useSignUpResendOtp,
  useGetProfile,
  useGenerateRefreshToken,
};
