import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import 'assets/scss/styles.scss';
import { PublicLayout } from 'common';
import { loader } from 'store';
import { loggedUser } from 'store';
import { LoadingSpinner } from 'common';
import { encryptValues } from 'helpers';
import { useLocationHistory } from 'context/LocationHistoryContext';
import { getHowItCmsSlug } from 'store';
import { routes } from 'config';

const AuthRoute = () => {
  let redirectUrl = '/';
  const { push } = useLocationHistory();
  const location = useLocation();

  useEffect(() => {
    push(location.pathname);
  }, [location]);
  /**
   * using selector hooks from redux to get data
   */
  const checkLoading = useSelector(loader);
  const checkLoggedInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggedInUser.isLoggedIn;
  /**
   * This function will call on page load, and set data to the page
   */
  let how_its_work_cms_slug = useSelector(getHowItCmsSlug);

  if (localStorage.getItem('link')) {
    redirectUrl = localStorage.getItem('link');
  }
  if (
    checkLoggedInUser.user.profile_setup === 2 &&
    [5, 6].includes(checkLoggedInUser.user.user_type)
  ) {
    const user_id = encryptValues(checkLoggedInUser.user.user_id);
    redirectUrl = routes.AUTH_COMPANY_STAFF_INFO + '/' + user_id;
  } else if (checkLoggedInUser.user.profile_setup == 2 && checkLoggedInUser.user.user_type === 2) {
    if (checkLoggedInUser.user.organization_info === '') {
      redirectUrl = `/auth/${how_its_work_cms_slug}`;
    } else if (
      checkLoggedInUser.user.organization_info !== '' &&
      checkLoggedInUser.user.organization_info.fill_step === 1
    ) {
      redirectUrl = routes.AUTH_SUBSCRIPTION_DETAILS;
    } else if (
      checkLoggedInUser.user.organization_info !== '' &&
      checkLoggedInUser.user.organization_info.fill_step === 2
    ) {
      redirectUrl = routes.AUTH_COMPANY_INFORMATION_PAGE1;
    } else if (
      checkLoggedInUser.user.organization_info !== '' &&
      checkLoggedInUser.user.organization_info.fill_step === 3
    ) {
      redirectUrl = routes.AUTH_COMPANY_INFORMATION_PAGE2;
    } else if (
      checkLoggedInUser.user.organization_info !== '' &&
      checkLoggedInUser.user.organization_info.fill_step === 4
    ) {
      redirectUrl = routes.AUTH_PRACTICE_INFORMATION;
    } else if (
      checkLoggedInUser.user.organization_info !== '' &&
      checkLoggedInUser.user.organization_info.fill_step === 5
    ) {
      redirectUrl = routes.AUTH_COMPANY_INTAKE_INFO;
    } else if (
      checkLoggedInUser.user.organization_info !== '' &&
      checkLoggedInUser.user.organization_info.fill_step === 6
    ) {
      redirectUrl = routes.AUTH_FORMULARY_PHARMACY;
    } else if (
      checkLoggedInUser.user.organization_info !== '' &&
      checkLoggedInUser.user.organization_info.fill_step === 7
    ) {
      redirectUrl = routes.AUTH_FORMULARY_PHARMACY;
    } else if (
      checkLoggedInUser.multipleAccount == true &&
      checkLoggedInUser.isMultipleDefaultCheck === true
    ) {
      redirectUrl = routes.CLINIC;
    } else {
      if (
        checkLoggedInUser.multipleAccount == true &&
        checkLoggedInUser.clinic_data &&
        checkLoggedInUser.clinic_data.length > 0
      ) {
        redirectUrl = '/all-orders/';
      } else {
        redirectUrl = routes.DASHBOARD;
      }
    }
  } else {
    if (
      checkLoggedInUser.multipleAccount == true &&
      checkLoggedInUser.isMultipleDefaultCheck === true
    ) {
      redirectUrl = routes.CLINIC;
    } else {
      if (
        checkLoggedInUser.multipleAccount == true &&
        checkLoggedInUser.clinic_data &&
        checkLoggedInUser.clinic_data.length > 0
      ) {
        redirectUrl = '/all-orders/';
      } else {
        redirectUrl = routes.DASHBOARD;
      }
    }
  }

  return !isLoggedIn ? (
    <PublicLayout>
      {checkLoading ? <LoadingSpinner /> : ''} <Outlet />
    </PublicLayout>
  ) : (
    <Navigate
      to={{
        pathname: redirectUrl,
      }}
      replace
    />
  );
};

export default AuthRoute;
