import { useContext, createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { loggedUser } from 'store';
import { useGenerateRefreshToken } from 'hooks';
import { logoutSuccess, updateToken } from 'store';
import { Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { TNButton, TimeCounterDown } from 'common';

import { defaultTimezone } from 'helpers';
import { setAuthToken, setClinicId, setUserType } from '../libs/HttpClient';
import { LOGIN } from 'config';
import { updateUserData } from 'store';
const UserActivity = createContext({
  clearAllUserData: () => {},
  LoggedInUser: {},
  isLoggedIn: false,
  updateUserProfileData: () => {},
});
const UserActivityProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const updateUserProfileData = (data) => {
    const dataStore = {
      userData: data.userData,
      cancelSubscription: data.userCancelSubscription,
      userSubscription: data.userSubscription,
      multipleAccount: data.multipleAccount || false,
      clinic_data: data.clinic_data,
      user_type_data: data.user_type_data,
    };
    dispatch(updateUserData(dataStore));
  };

  const checkLoggedInUser = useSelector(loggedUser);
  console.log(checkLoggedInUser, 'checkLoggedInUser');
  const LoggedInUser = checkLoggedInUser.user || {};
  const isLoggedIn = checkLoggedInUser.isLoggedIn || false;
  const timezone = LoggedInUser.timezones ? LoggedInUser.timezones.utc : defaultTimezone();
  const timezone_id = LoggedInUser.timezones ? LoggedInUser.timezones.timezone_id : 4;
  const history = useLocation();
  const [show, setShow] = useState(false);

  const [state, setState] = useState('Active');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const decodedJwt = parseJwt(checkLoggedInUser.accessToken || '');
  const created_time = moment(decodedJwt ? decodedJwt.created_at || '' : '');
  let current_time = moment();
  let duration = moment.duration(current_time.diff(created_time));
  let minutes = parseInt(duration.asMinutes() || 0, 10);
  console.log(decodedJwt?.created_at, duration, minutes, 'decodedJwt');
  if (!decodedJwt) {
    minutes = 0;
  }
  console.log(minutes, decodedJwt, 'minutes');
  const clearAllUserData = () => {
    setShow(false);
    setClinicId('');
    setUserType('');
    setAuthToken('');
    dispatch(logoutSuccess());
  };
  const timeout = 660_000;
  const promptBeforeIdle = 60_000;

  const onIdle = () => {
    if (
      localStorage.getItem('video-call-id') &&
      history.pathname &&
      history.pathname.split('/')[1] === 'video-call'
    ) {
      console.log(history, 'history', 'onIdle If');
      activate();
    } else {
      if (minutes >= 60) {
        if (history.pathname && history.pathname.split('/')[1] === 'video-call') {
          console.log(history, 'history', 'onIdle token update If');
          doTokenData();
        } else {
          console.log(history, 'history', 'onIdle token update else');
          setClinicId('');
          setUserType('');
          setAuthToken('');
          window.localStorage.removeItem('link');
          if (window.socket) {
            window.socket.emit('user_disconnect', { user_id: checkLoggedInUser?.user.user_id });
          }
          dispatch(logoutSuccess());
          localStorage.loginActivityError = t('front.org_auto_logout_by_in_activty');
          toast.error(t('front.org_auto_logout_by_in_activty'));
          // Logout weird message
          // navigation('/login');
          setShow(false);
        }
      }
    }
  };

  const onActive = () => {
    setState('Active');
    setShow(false);
  };
  const onPrompt = () => {
    if (minutes >= 60) {
      if (history.pathname && history.pathname.split('/')[1] === 'video-call') {
        console.log(history, 'history', 'onPrompt If');
        doTokenData();
      } else {
        console.log(history, 'history', 'onPrompt else');
        clearAllUserData();
        window.localStorage.removeItem('link');
        if (window.socket) {
          window.socket.emit('user_disconnect', { user_id: checkLoggedInUser?.user.user_id });
        }
        dispatch(logoutSuccess());
        localStorage.loginActivityError = t('front.org_auto_logout_by_in_timeout');
        toast.error(t('front.org_auto_logout_by_in_timeout'));
        navigation(LOGIN);
      }
    } else if (history.pathname && history.pathname.split('/')[1] === 'video-call') {
      console.log(history, 'history', 'onPrompt else if');
      activate();
    } else {
      setShow(true);
    }
    setState('Prompt');
  };
  const onAction = () => {
    current_time = moment();
    duration = moment.duration(current_time.diff(created_time));
    minutes = parseInt(duration.asMinutes(), 10);
    if (minutes >= 58) {
      if (history.pathname && history.pathname.split('/')[1] === 'video-call') {
        doTokenData();
        console.log(history, 'history', 'onAction if');
      } else {
        setState('Action');
        setShow(true);
      }
    }
  };

  const { activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onAction,
    timeout,
    promptBeforeIdle,
    throttle: 600,
    disabled: !decodedJwt ? true : false,
  });

  /**
   * This API call will generate Token.
   */
  const { mutate: doTokenData } = useGenerateRefreshToken((res) => {
    dispatch(updateToken(res.data.refreshToken));
  });

  if (minutes >= 60) {
    if (history.pathname && history.pathname.split('/')[1] === 'video-call') {
      doTokenData();
    } else {
      console.log('else timeout', minutes, history, 'history');
      clearAllUserData();
      window.localStorage.removeItem('link');
      if (window.socket) {
        window.socket.emit('user_disconnect', { user_id: checkLoggedInUser?.user.user_id });
      }
      dispatch(logoutSuccess());

      localStorage.loginActivityError = t('front.org_auto_logout_by_in_timeout');
      toast.error(t('front.org_auto_logout_by_in_timeout'));
      window.location.reload();
    }
  }

  useEffect(() => {
    if (localStorage.isShowingError) {
      toast.error(localStorage.isShowingError);
      localStorage.removeItem('isShowingError');
    }
    duration = moment.duration(current_time.diff(created_time));
    minutes = parseInt(duration.asMinutes() || 0, 10);
    if (minutes >= 60) {
      if (history.pathname && history.pathname.split('/')[1] === 'video-call') {
        doTokenData();
      } else {
        setClinicId('');
        setUserType('');
        setAuthToken('');
        window.localStorage.removeItem('link');
        if (window.socket) {
          window.socket.emit('user_disconnect', { user_id: checkLoggedInUser?.user.user_id });
        }
        dispatch(logoutSuccess());
        localStorage.loginActivityError = t('front.org_auto_logout_by_in_timeout');
        toast.error(t('front.org_auto_logout_by_in_timeout'));
      }
    }
  }, []);
  return (
    <UserActivity.Provider
      value={{
        clearAllUserData,
        LoggedInUser,
        isLoggedIn,
        timezone,
        timezone_id,
        updateUserProfileData,
      }}>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="mt-5"
        centered>
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter" className="modal-title-auto-logout">
            {state === 'Action'
              ? t('front.auto_logout_session_timeout_title_text')
              : t('front.auto_logout_title_text')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="fw-light">
            {state === 'Action'
              ? t('front.auto_logout_session_timeout_description_text')
              : t('front.auto_logout_description_text')}
          </p>
          {t('front.auto_logout_countdown_text')}
          <TimeCounterDown
            endEvent={() => {
              clearAllUserData();
              window.localStorage.removeItem('link');
              window.socket.emit('user_disconnect', { user_id: checkLoggedInUser?.user.user_id });
              setShow(false);
              navigation(LOGIN);
            }}
          />
          <div className="inner-page-button button-auto-logout">
            <TNButton
              onClick={() => {
                clearAllUserData();
                window.localStorage.removeItem('link');
                window.socket.emit('user_disconnect', { user_id: checkLoggedInUser?.user.user_id });
                dispatch(logoutSuccess());

                navigation(LOGIN);
                setShow(false);
              }}
              className="button-rounded-logout cancel-button settings-btn">
              {t('front.auto_logout_popup_logout_button')}
            </TNButton>
            <TNButton
              onClick={() => {
                if (minutes >= 58) {
                  doTokenData();
                }
                activate();
                setShow(false);
              }}
              className="button-rounded checkout-submit-button settings-btn section-button me-2"
              type="button">
              {`${t('front.auto_logout_popup_stay_log_in_button')}`}
            </TNButton>
          </div>
        </Modal.Body>
      </Modal>
      {children}
    </UserActivity.Provider>
  );
};
UserActivityProvider.propTypes = {
  children: PropTypes.any,
};
const UserActivityContext = () => {
  return useContext(UserActivity);
};
export { UserActivityProvider, UserActivityContext };
