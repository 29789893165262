import CryptoJS from 'crypto-js';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
const key = CryptoJS.enc.Utf8.parse('12345678901234561234567890123456'); // 32 bytes key
const iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes IV
const encryptValues = (text) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return base64_encode(encrypted.toString());
  } catch (error) {
    return text;
  }
};
const decryptValues = (text) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(base64_decode(text), key, {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return CryptoJS.enc.Utf8.stringify(decrypted) || text;
  } catch (error) {
    return text;
  }
};
function aesEncrypt(content) {
  const parsedkey = CryptoJS.enc.Utf8.parse('12345678901234561234567890123456');
  const iv = CryptoJS.enc.Utf8.parse('12345678901234561234567890123456');
  const encrypted = CryptoJS.AES.encrypt(content, parsedkey, {
    iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return JSON.parse(encrypted.toString())[0].text;
}

function aesDecrypt(word) {
  var keys = CryptoJS.enc.Utf8.parse('12345678901234561234567890123456');
  let base64 = CryptoJS.enc.Base64.parse(word);
  let src = CryptoJS.enc.Base64.stringify(base64);
  var decrypt = CryptoJS.AES.decrypt(src, keys, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}
export { encryptValues, decryptValues, aesEncrypt, aesDecrypt };
