import client from 'libs/HttpClient';

class WebhookService {
  static getWebhookSettingData(params) {
    return client.get(`webhook_setting`, { params });
  }
  static saveWebhookSettingData(request) {
    return client.put(`webhook_setting/save`, request);
  }
}

export { WebhookService };
