import client from 'libs/HttpClient';

class AuthService {
  static register(request) {
    return client.post('auth/signup', request);
  }
  static resendSignUpOtp(request) {
    return client.put('auth/resend-otp', request);
  }
  static otpVerifySignUp(request) {
    return client.put('auth/verify-otp', request);
  }
  static login(loginData) {
    return client.post('auth/login', loginData);
  }
  static forgotPassword(request) {
    return client.put('auth/forgot-password', request);
  }
  static resendOtp(request) {
    return client.put('auth/forgot-password/resend-otp', request);
  }
  static otpVerify(request) {
    return client.put('auth/verify-forgot-otp', request);
  }
  static resetPassword(request) {
    return client.patch('auth/reset-password', request);
  }
  static setPassword(request) {
    return client.patch('auth/set-password', request);
  }
  static getProfile(request) {
    return client.get('auth/get-profile', request);
  }
  static generateRefreshToken(request) {
    return client.get('common/generate-refresh-token', request);
  }
}

export { AuthService };
