import client from 'libs/HttpClient';

class AccountSettingsServices {
  static getUserProfile(request) {
    return client.get('auth/get-profile', request);
  }
  static updateUserPassword(request) {
    return client.put('auth/organization-change-password', request);
  }
  static updateUserEmail(request) {
    return client.put('auth/add-new-organization-email', request);
  }
  static updateUserEmailVerify(request) {
    return client.put('auth/verify-new-organization-email', request);
  }
  static resendOtp(request) {
    return client.put('auth/organization-resend-email', request);
  }
  static updateUserProfile(request) {
    return client.post('auth/edit-organization-profile', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getCountryCodeList(request) {
    return client.get('common/country-code-list', request);
  }

  static getTimezoneList(request) {
    return client.get('common/timezone-list', request);
  }
  static updateUserStatus(request) {
    return client.put('auth/organization-deactivate', request);
  }
}

export { AccountSettingsServices };
