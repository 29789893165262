import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { SmtpService } from 'api';
/**
 * Hook for user Informations after signup
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetSmtpProvider = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-smtp-provider', SmtpService.getSmtpProvider, {
    onSuccess,
    onError,
  });
};
const useGetSmtpDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-smtp-details', SmtpService.getSmtpDetail, {
    onSuccess,
    onError,
  });
};
const useStoreSmtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmtpService.storeSmtpProvider, {
    onSuccess,
    onError,
  });
};
const useApproveSmtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmtpService.approveSmtpProvider, {
    onSuccess,
    onError,
  });
};
const useDeleteSmtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmtpService.deleteSmtpProvider, {
    onSuccess,
    onError,
  });
};
const useTestSmtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(SmtpService.testSmtpProvider, {
    onSuccess,
    onError,
  });
};
export {
  useGetSmtpProvider,
  useStoreSmtp,
  useGetSmtpDetails,
  useApproveSmtp,
  useDeleteSmtp,
  useTestSmtp,
};
