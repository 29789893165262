export const intakeOption = (t) => {
  return [
    { value: '', label: t('front.intake_question_select_label') },
    { value: 1, label: t('front.intake_question_select_text') },
    { value: 2, label: t('front.intake_question_select_textarea') },
    { value: 3, label: t('front.intake_question_select_dropdown') },
    { value: 4, label: t('front.intake_question_select_radio_button') },
    { value: 5, label: t('front.intake_question_select_multiple_choice') },
    { value: 6, label: t('front.intake_question_select_file') },
    { value: 7, label: t('front.intake_question_select_date') },
    { value: 9, label: t('front.intake_question_select_agreement_check_box') },
    { value: 10, label: t('front.intake_question_select_signature_box') },
  ];
};
export const optionCheckout = (t) => [
  {
    value: 2,
    label: t('front.organization_patient_register_checkout_yes'),
  },
  {
    value: 1,
    label: t('front.organization_patient_register_checkout_no'),
  },
];
export const optionPassword = (t) => [
  {
    value: 1,
    label: t('front.organization_patient_register_password_yes'),
  },
  {
    value: 2,
    label: t('front.organization_patient_register_password_no'),
  },
];
export const optionPatientRegister = (t) => [
  {
    value: 1,
    label: t('front.organization_patient_register_setting_all_in_one'),
  },
  {
    value: 2,
    label: t('front.organization_patient_register_setting_individual'),
  },
];

export const optionFontFamily = () => [
  {
    value: 'Roboto',
    label: 'Roboto',
  },
  {
    value: 'Open Sans',
    label: 'Open Sans',
  },
  {
    value: 'Lato',
    label: 'Lato',
  },
  {
    value: 'Noto Sans JP',
    label: 'Noto Sans JP',
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    value: 'Roboto Condensed',
    label: 'Roboto Condensed',
  },
  {
    value: 'Oswald',
    label: 'Oswald',
  },
  {
    value: 'Poppins',
    label: 'Poppins',
  },
  {
    value: 'Raleway',
    label: 'Raleway',
  },
  {
    value: 'Material Icons',
    label: 'Material Icons',
  },
];
export const CouponTypeOptions = (t) => [
  { value: '', label: `${t('front.coupon_select_type')}` },
  { value: 1, label: `${t('front.fixed_type_name')}` },
  { value: 2, label: `${t('front.percentage_type_name')}` },
];

export const optionActiveInactive = (t) => [
  { value: '', label: `${t('front.org_select_status')}` },
  { value: 1, label: `${t('front.org_active_status_name')}` },
  { value: 2, label: `${t('front.org_in_active_status_name')}` },
];
export const optionQuestionType = (t) => [
  { value: '', label: `${t('front.org_patient_question_question_type_select_label')}` },
  { value: 1, label: `${t('front.org_patient_question_type_text')}` },
  { value: 2, label: `${t('front.org_patient_question_type_textarea')}` },
  { value: 3, label: `${t('front.org_patient_question_type_dropdown')}` },
  { value: 4, label: `${t('front.org_patient_question_type_radio')}` },
  { value: 5, label: `${t('front.org_patient_question_type_multiple_choice')}` },
  { value: 6, label: `${t('front.org_patient_question_type_upload')}` },
  { value: 7, label: `${t('front.org_patient_question_type_date')}` },
  { value: 8, disabled: true, label: t('front.org_patient_question_type_state_drop_down') },
  { value: 9, disabled: true, label: t('front.org_patient_question_type_agreement_check_box') },
  { value: 10, disabled: true, label: t('front.org_patient_question_type_signature_box') },
];
