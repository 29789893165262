// import { toast } from 'react-toastify';

import { PAYMENT_API_KEY } from 'config';

let myPayTheory;
const STYLES = {
  default: {
    color: '#212529',
    fontSize: '1rem',
    fontWeight: 450,
    border: '1px solid #ced4da',
    background: 'rgba(77,132,129,.07)',
    borderRadius: '60px',
    paddingLeft: '1.5rem',
  },
  success: {
    color: 'rgb(24, 87, 24)',
    fontSize: '1rem',
    fontWeight: 450,
    background: 'rgba(77,132,129,.07)',
    border: '1px solid rgb(24, 87, 24)',
    borderRadius: '60px',
    paddingLeft: '1.5rem',
  },
  error: {
    color: 'rgb(148, 48, 48)',
    fontSize: '1rem',
    fontWeight: 450,
    background: 'rgba(77,132,129,.07)',
    border: '1px solid rgb(148, 48, 48)',
    borderRadius: '60px',
    paddingLeft: '1.5rem',
  },
  radio: {
    width: 18,
    fill: 'blue',
    stroke: 'grey',
    text: {
      fontSize: '18px',
      color: 'grey',
    },
  },
};
// const FEE_MODE = window.paytheory.SERVICE_FEE;

const paymentInit = async (user) => {
  /**
   * initialize the SDK (can also be called as a promise)
   *
   * if providing metadata but no styles, provide an empty object
   * as a placeholder
   **/
  console.log(user);
  const SESSION_METADATA = {
    page_key: 'card-payment',
    user_id: user.user_id,
  };
  if (!myPayTheory) {
    myPayTheory = await window.paytheory.create(PAYMENT_API_KEY, STYLES, SESSION_METADATA);
    // mount the hosted fields into the container
  }
  if (myPayTheory) {
    myPayTheory.mount();
    myPayTheory.errorObserver(() => {
      /**
       * error is false or a message
       * fires every time the error state/message changes
       **/
      // toast.warning('payment some issue');
      // const paymentButton = document.getElementById('initiate-payment');
      // console.log(paymentButton.hasAttribute('disabled'));
      setTimeout(() => {
        // location.reload();
      }, 1000);
    });
    // handle callbacks

    myPayTheory.validObserver((valid) => {
      const paymentButton = document.getElementById('initiate-payment');
      const paymentButtonNext = document.querySelectorAll('.initiate-payment-next');
      if (paymentButton) {
        if (valid) {
          paymentButton.removeAttribute('disabled');
        } else {
          paymentButton.setAttribute('disabled', '');
        }
      }
      if (paymentButtonNext) {
        if (valid) {
          for (const button of paymentButtonNext) {
            button.removeAttribute('disabled');
          }
        } else {
          for (const button of paymentButtonNext) {
            button.setAttribute('disabled', '');
          }
        }
      }
    });
  }
};
const handleCardAdd = async (e, user) => {
  e.preventDefault();
  return new Promise((resolve, reject) => {
    //   const PAYMENT_METADATA = {
    //     'pay-theory-account-code': 'code-123456789',
    //     'pay-theory-reference': 'field-trip',
    //     'payment-parameters-name': 'expires-in-30-days',
    //     'pay-theory-environment': 'start-paytheory',
    //     'pt-number': 'pt-start-paytheory-rj09eo004af1',
    //   };
    const PAYOR_INFO = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      // personal_address: {
      //   city: 'Somewhere',
      //   country: 'USA',
      //   region: 'OH',
      //   line1: '123 Street St',
      //   line2: 'Apartment 17',
      //   postal_code: '12345',
      // },
    };
    const TOKENIZE_PAYMENT_METHOD_PARAMETERS = {
      payorInfo: PAYOR_INFO, // optional
      // payorId: "pt_pay_24GshTjHdnMGdT3OKLVfY9", // optional
      // metadata: PAYMENT_METADATA, // optional
    };
    myPayTheory.readyObserver(() => {
      /**
       * ready is a boolean indicator
       * fires when SDK is loaded and ready
       * this is where you would associate any listeners
       * to trigger initTransaction
       * or optionally confirmation
       **/
    });
    myPayTheory.tokenizePaymentMethod(TOKENIZE_PAYMENT_METHOD_PARAMETERS);
    myPayTheory.tokenizeObserver((card) => {
      const confirmation = `Are you sure you want to make a payment on ${card.brand} card beginning with ${card.first_six}`;
      if (window.confirm(confirmation)) {
        myPayTheory.confirm();
      } else {
        myPayTheory.cancel();
      }
    });
    const completionListener = (transactionResult) => {
      resolve(transactionResult);
      // const statusContainer = document.getElementById('result-text');
      // statusContainer.textContent = '';
      // statusContainer.appendChild(document.createTextNode(JSON.stringify(transactionResult)));
    };
    myPayTheory.captureObserver(completionListener);
    myPayTheory.errorObserver(async (error) => {
      console.log(error);
      reject(error);
      /**
       * error is false or a message
       * fires every time the error state/message changes
       **/
    });
    //Handles response after initTransaction is called without confirmation step
    myPayTheory.transactedObserver(completionListener);
  });
  //Handles response after capture function is called in confirmation step
};
const handlePayment = async (user, address, card, amount = 0) => {
  return new Promise((resolve) => {
    //   const PAYMENT_METADATA = {
    //     'pay-theory-account-code': 'code-123456789',
    //     'pay-theory-reference': 'field-trip',
    //     'payment-parameters-name': 'expires-in-30-days',
    //     'pay-theory-environment': 'start-paytheory',
    //     'pt-number': 'pt-start-paytheory-rj09eo004af1',
    //   };
    const PAYOR_INFO = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      personal_address: {
        city: address.city,
        line1: address.address,
        line2: address.address,
        postal_code: address.zipcode,
      },
    };
    myPayTheory.readyObserver(() => {
      /**
       * ready is a boolean indicator
       * fires when SDK is loaded and ready
       * this is where you would associate any listeners
       * to trigger initTransaction
       * or optionally confirmation
       **/
    });
    myPayTheory.transact({
      amount: parseInt(parseFloat(amount) * 100),
      payorId: card.pay_id.toString(),
      payorInfo: PAYOR_INFO,
      // metadata: TRANSACTION_METADATA,
      confirmation: false,
    });
    myPayTheory.tokenizeObserver((card) => {
      const confirmation = `Are you sure you want to make a payment on ${card.brand} card beginning with ${card.first_six}`;
      if (window.confirm(confirmation)) {
        myPayTheory.confirm();
      } else {
        myPayTheory.cancel();
      }
    });
    const completionListener = (transactionResult) => {
      resolve(transactionResult);
      // const statusContainer = document.getElementById('result-text');
      // statusContainer.textContent = '';
      // statusContainer.appendChild(document.createTextNode(JSON.stringify(transactionResult)));
    };
    myPayTheory.captureObserver(completionListener);

    //Handles response after initTransaction is called without confirmation step
    myPayTheory.transactedObserver(completionListener);
  });
  //Handles response after capture function is called in confirmation step
};

export { handleCardAdd, paymentInit, handlePayment };
