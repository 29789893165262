import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import { HeroSection } from './HeroSection';
import PlaceholderImage from 'assets/images/whiteSquare.png';

const Header = (props) => {
  const { t, banner_data } = props;
  return (
    <Container fluid className="header-container" id="Home_header_section">
      <img
        className="header-img"
        alt={'Banner Image'}
        src={banner_data?.home_page_banner_image || PlaceholderImage}
      />
      <div className="nav-hero-container">
        <HeroSection t={t} banner_data={banner_data} />
      </div>
    </Container>
  );
};
Header.propTypes = {
  t: PropTypes.func,
  banner_data: PropTypes.object,
};
export { Header };
