import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CardService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useStoreCardData = (onSuccess, onError = onDefaultError) => {
  return useMutation(CardService.storeCardData, {
    onSuccess,
    onError,
  });
};
const useGetCardData = (onSuccess, onError = onDefaultError) => {
  return useQuery(['get-card'], CardService.getCardData, {
    onSuccess,
    onError,
  });
};
const useDeleteCardData = (onSuccess, onError = onDefaultError) => {
  return useMutation(CardService.deleteCardData, {
    onSuccess,
    onError,
  });
};
const useDefaultCardData = (onSuccess, onError = onDefaultError) => {
  return useMutation(CardService.defaultCardData, {
    onSuccess,
    onError,
  });
};
export { useStoreCardData, useGetCardData, useDeleteCardData, useDefaultCardData };
