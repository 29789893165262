import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { PagesRoutes } from 'routes';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from 'store';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/styles.scss';
import { setAuthToken } from 'libs/HttpClient';
import { LoadingSpinner, NoInternetPage } from 'common';
import { UserActivityProvider } from 'context/UserActivityContext';
import { setClinicId, setUserType } from 'libs/HttpClient';
import { LocationHistoryProvider } from 'context/LocationHistoryContext';
import { LoaderProvider } from '../context/LoaderContext';
import Notification from '../libs/Notification';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { t } = useTranslation();

  if (!navigator.onLine) {
    return <NoInternetPage />;
  }

  window.onoffline = () => {
    return <NoInternetPage />;
  };

  window.ononline = () => {
    window.location.reload(true);
  };
  const handleOnBeforeLift = () => {
    if (
      store.getState().user.accessToken !== undefined &&
      store.getState().user.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
    }
    if (store.getState().user.clinic_id !== undefined && store.getState().user.clinic_id !== null) {
      setClinicId(store.getState().user.clinic_id);
    }
    if (store.getState().user.user_type !== undefined && store.getState().user.user_type !== null) {
      setUserType(store.getState().user.user_type);
    }
  };
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor} onBeforeLift={handleOnBeforeLift}>
              <LocationHistoryProvider>
                <UserActivityProvider>
                  <LoaderProvider>
                    <PagesRoutes t={t} />
                  </LoaderProvider>
                  <ToastContainer />
                  <Notification />
                </UserActivityProvider>
              </LocationHistoryProvider>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  );
}
export default App;
