import { createSlice } from '@reduxjs/toolkit';
import { setAuthToken, setClinicId, setUserType } from 'libs/HttpClient';

/**
 * Initial Values of globally declared states in redux
 */
const initialState = {
  user: '',
  isLoggedIn: false,
  multipleAccount: false,
  accessToken: '',
  cancelSubscription: '',
  userSubscription: '',
  clinic_data: '',
  user_type_data: '',
  clinic_id: '',
  user_type: '',
  organization_info: '',
  isDefaultUser: '',
};

/**
 * Slice for authentication Info
 */
const authSlice = createSlice({
  name: 'user',
  initialState: initialState,
  /**
   * Reducer functions
   */

  reducers: {
    loginSuccess: (state, { payload }) => {
      state.payload = payload;
      state.user = payload.userData;
      state.isLoggedIn = true;
      state.multipleAccount = payload.multipleAccount || false;
      state.accessToken = payload.accessToken;
      state.cancelSubscription = payload.cancelSubscription;
      state.userSubscription = payload.userSubscription;
      state.clinic_data = payload.clinic_data;
      state.user_type_data = payload.user_type_data;
      state.organization_info = payload.organization_info;
      state.isMultipleDefaultCheck = payload.isMultipleDefaultCheck;
      state.isDefaultUser =
        payload.isDefaultUser && payload.isDefaultUser.length > 0 ? payload.isDefaultUser[0] : {};
      setAuthToken(payload.accessToken);
      return state;
    },
    updateUserData: (state, { payload }) => {
      state.user = payload.userData;
      state.multipleAccount = payload.multipleAccount || false;
      state.cancelSubscription = payload.cancelSubscription;
      state.userSubscription = payload.userSubscription;
      // state.isMultipleDefaultCheck = payload.isMultipleDefaultCheck;
      // state.clinic_data = payload.clinic_data;
      // state.user_type_data = payload.user_type_data;
      // state.organization_info = payload.organization_info;
      console.log(payload.clinic_data, state.clinic_data, 'clinic_data');

      return state;
    },
    updateClinic: (state, { payload }) => {
      state.clinic_id = payload.clinic_id;
      setClinicId(payload.clinic_id);
      return state;
    },
    updateUserType: (state, { payload }) => {
      state.user_type = payload.user_type;
      setUserType(payload.user_type);
      return state;
    },
    updateToken: (state, { payload }) => {
      state.isLoggedIn = true;
      state.accessToken = payload;
      setAuthToken(payload);
      return state;
    },
    logoutSuccess: () => initialState,
  },
});
const { loginSuccess, logoutSuccess, updateUserData, updateToken, updateClinic, updateUserType } =
  authSlice.actions;
const loggedUser = (state) => state.user;
export {
  authSlice,
  loginSuccess,
  logoutSuccess,
  updateUserData,
  updateToken,
  loggedUser,
  updateClinic,
  updateUserType,
};
export default authSlice.reducer;
