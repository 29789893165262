import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import OrderItem from './OrderItem';
import { useGetPatientChartOrders } from 'hooks';
import { Pagination } from 'common';
import {
  currencyType,
  decryptValues,
  encryptValues,
  orderStatusShow,
  setFormatUtcDate,
} from 'helpers';
import { useSelector } from 'react-redux';
import { loggedUser } from 'store/features/authSlice';

const OrderList = ({ t, user_id }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlPath = location.pathname.split('/');
  const loggedInUser = useSelector(loggedUser);

  const [page, setPage] = useState(
    localStorage.OrgPatientChartTable !== undefined && localStorage.OrgPatientChartTable !== ''
      ? JSON.parse(localStorage.OrgPatientChartTable).user_id === decryptValues(user_id)
        ? JSON.parse(localStorage.OrgPatientChartTable).page
        : 1
      : 1
  );
  let LoginUserId = loggedInUser.user.user_id;
  if (urlPath.includes('all-orders')) {
    if (loggedInUser.clinic_data && loggedInUser.clinic_data.length > 0) {
      LoginUserId =
        loggedInUser.isDefaultUser && loggedInUser.isDefaultUser.user_id
          ? loggedInUser.isDefaultUser.user_id
          : loggedInUser.user.user_id;
    } else {
      LoginUserId = loggedInUser.user.user_id;
    }
  } else {
    LoginUserId = loggedInUser.user.user_id;
  }

  let orderData = {};
  let paginationData = {};
  const { isLoading: isLoadingOrderList, data: Order_data } = useGetPatientChartOrders(
    {
      user_id: decryptValues(user_id),
      page,
      allOrderShow: urlPath.includes('all-orders') ? 'true' : 'false',
      loggedUserId: LoginUserId,
    },
    (data) => {
      orderData = data.order_list;
      paginationData = data.pagination;
    }
  );
  if (!isLoadingOrderList && Order_data) {
    orderData = Order_data.data.order_list;
    paginationData = Order_data.data.pagination;
  }

  useEffect(() => {
    let OrgPatientChartTable = {
      page: page,
      user_id: decryptValues(user_id),
    };
    localStorage.OrgPatientChartTable = JSON.stringify(OrgPatientChartTable);
  }, [page]);

  useEffect(() => {
    localStorage.removeItem('OrgPatientChartTable');
    setPage(1);
  }, []);

  return (
    <Card.Body className="py-0">
      <h5 className="chart-inner-heading py-3">{t('front.order_view_history_header_text')}</h5>
      {isLoadingOrderList ? (
        <div className="page-not-found">
          <div className="error-page-text">{t('front.orders_loading_data_sub_text')}</div>
        </div>
      ) : !isLoadingOrderList && Order_data ? (
        <>
          {orderData && orderData.length > 0 ? (
            orderData.map((order, i) => {
              const quantities = order.order_items.reduce((val, object) => {
                return val + object.qty;
              }, 0);
              const getOrderAllItems = order.order_items;
              let orderItemData = getOrderAllItems;
              // orderItemData = [
              //   ...new Set(getOrderAllItems.map((item) => item.transitionrx_fill_id)),
              // ].map((v) => ({
              //   name: v || 'all',
              //   values: [],
              // }));
              // orderItemData = orderItemData.map((element) => {
              //   getOrderAllItems.map((v) => {
              //     if (
              //       element.name === (v.transitionrx_fill_id ? v.transitionrx_fill_id : 'all')
              //     ) {
              //       element.values = [...element.values, ...[v]];
              //     }
              //     return v;
              //   });
              //   return element;
              // });
              return (
                <Card key={i} className="mt-2 mb-3 body-card  ">
                  <Card.Header className="order-inner-card p-3 pt-4 item-align-center card-head-caption">
                    {orderStatusShow(order)}
                    <Row className="item-align-center">
                      <Col lg={6}>
                        <Row>
                          <Col lg={3} sm={4} xs={6} className="card-single-item">
                            <div className="order-view-details">
                              <div className="inner-card-header">
                                <h4>{t('front.order_view_order_received_text')}</h4>
                                <span>
                                  {setFormatUtcDate(
                                    order.order_status_histories?.created_at ||
                                      order.created_at ||
                                      ''
                                  )}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={3} sm={4} xs={6} className="card-single-item">
                            <div className="order-view-details">
                              <div className="inner-card-header">
                                <h4>{t('front.order_view_order_patient_name_text')}</h4>
                                <span>
                                  {order.users &&
                                    `${order.users.first_name} ${order.users.last_name}`}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={2} sm={4} xs={6} className="card-single-item">
                            <div className="order-view-details">
                              <div className="inner-card-header">
                                <h4>{t('front.order_view_order_patient_id_text')}</h4>
                                <span>{decryptValues(user_id)}</span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={2} sm={4} xs={6} className="card-single-item">
                            <div className="order-view-details">
                              <div className="inner-card-header">
                                <h4>{t('front.order_view_order_patient_items_text')}</h4>
                                <span>{quantities}</span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={2} sm={4} xs={6} className="card-single-item">
                            <div className="order-view-details">
                              <div className="inner-card-header">
                                <h4>{t('front.order_view_order_total_text')}</h4>
                                <span>{`${currencyType()}${order.total_amount}`}</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} className="px-sm-2 p-0">
                        <Row className="mobile-col-reverse my-2">
                          <Col lg={7} md={6} className="mt-2">
                            {order.intake_question_count > 0 &&
                            order.user_intake_question_count > 0 &&
                            order.is_intake_question_fill === 1 ? (
                              <div className="feature-single-item d-flex justify-content-center">
                                <span
                                  onClick={() =>
                                    navigate(
                                      urlPath.includes('all-orders')
                                        ? `/all-orders/order/${encryptValues(order.order_id)}`
                                        : `/orders/order/${encryptValues(order.order_id)}`
                                    )
                                  }>
                                  {t('front.order_view_order_general_intake_text')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                          <Col lg={5} md={3} className="card-single-item pe-3">
                            <div className="order-details text-md-end text-start">
                              <div className="order-id">
                                {t('front.order_view_order_order_id_text')}
                                {` #${order.order_id}`}
                              </div>
                              <span
                                onClick={() =>
                                  navigate(
                                    urlPath.includes('all-orders')
                                      ? `/all-orders/order-details/${encryptValues(order.order_id)}`
                                      : `/orders/order-details/${encryptValues(order.order_id)}`
                                  )
                                }
                                className="order-detail-link">
                                {t('front.order_view_order_details_button_text')}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <OrderItem t={t} order_item={orderItemData} order={order} />
                </Card>
              );
            })
          ) : (
            <div className="page-not-found">
              <div className="error-page-text">{t('front.order_no_data_found_text')} </div>
            </div>
          )}
          {orderData && orderData.length > 0 && (
            <Pagination
              className="pagination-bar"
              page={page}
              totalCount={paginationData.last_page}
              pageSize={paginationData.count}
              onPageChange={(page) => setPage(page)}
            />
          )}
        </>
      ) : (
        ''
      )}
    </Card.Body>
  );
};
OrderList.propTypes = {
  t: PropTypes.any,
  user_id: PropTypes.any,
};
export default OrderList;
