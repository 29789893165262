import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { PatientChartServices } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetPatientChartOrders = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`patient-orders-list`, [params]],
    () => PatientChartServices.getPatientOrders(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetAllNotes = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['patients-all-notes', [params]],
    () => PatientChartServices.patientAllNotes(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useGetGeneralDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['patients-general-details', [params]],
    () => PatientChartServices.getGeneralDetails(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useDownloadChartDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(PatientChartServices.downloadPatientChart, {
    onSuccess,
    onError,
  });
};
const useGetInsuranceDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['patients-insurance-details', [params]],
    () => PatientChartServices.insuranceDetails(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useGetBasicDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['patients-basic-details', [params]],
    () => PatientChartServices.getBasicDetails(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useGetPatientAllOrders = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`patient-all-orders-list`, [params]],
    () => PatientChartServices.getPatientAllOrders(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useStoreOrderNotePatientChart = (onSuccess, onError = onDefaultError) => {
  return useMutation(PatientChartServices.storeOrderNote, {
    onSuccess,
    onError,
  });
};

export {
  useGetPatientChartOrders,
  useGetAllNotes,
  useGetGeneralDetails,
  useGetInsuranceDetails,
  useGetBasicDetails,
  useGetPatientAllOrders,
  useStoreOrderNotePatientChart,
  useDownloadChartDetails,
};
