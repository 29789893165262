import { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'common';

const Loader = createContext();
const LoaderProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);
  return (
    <Loader.Provider value={{ setShowLoader, showLoader }}>
      {showLoader ? <LoadingSpinner /> : ''}
      {children}
    </Loader.Provider>
  );
};
LoaderProvider.propTypes = {
  children: PropTypes.any,
};
const LoaderContext = () => {
  return useContext(Loader);
};
export { LoaderProvider, LoaderContext };
