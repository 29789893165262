import { React } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { OtherPageNavbar } from '../Header/OtherPageNavbar';
import { OtherPageFooter } from '../Footer/OtherPageFooter';
import AfterSignupPageNavbar from '../Header/AfterSignupPageNavbar';
import { loggedUser } from 'store';
import { useBookingCallStatusChanges } from 'hooks';
import { leave, setBookingId } from 'pages/VideoCall/Agora_RTC';
import { CHAT_URL } from 'config';
import { io } from 'socket.io-client';
import { useLocation } from 'react-router';
import { useLocationHistory } from 'context/LocationHistoryContext';
import { CLINIC } from 'config';

const PrivateLayout = (props) => {
  const checkLoggedInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggedInUser.isLoggedIn;
  let stepsCompleted = checkLoggedInUser.user.profile_setup;
  const cancelSubscription = checkLoggedInUser.cancelSubscription;
  const userSubscription = checkLoggedInUser.userSubscription;
  if (!window.socket) {
    window.socket = io.connect(CHAT_URL, { secure: true, transports: ['websocket', 'polling'] });
    window.socket.on('disconnect', () => {
      window.socket.emit('user_disconnect', { user_id: checkLoggedInUser.user.user_id });
    }); //prints "transport close"
  }
  const { t } = useTranslation();
  const history = useLocation();
  const { myHistory } = useLocationHistory();
  let is_subscription_active = 2;

  if (!userSubscription && !cancelSubscription) {
    is_subscription_active = 1;
  } else if (
    userSubscription &&
    !cancelSubscription &&
    moment(userSubscription.renewed_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
  ) {
    is_subscription_active = 1;
  } else if (
    !userSubscription &&
    cancelSubscription &&
    moment(cancelSubscription.renewed_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
  ) {
    is_subscription_active = 1;
  }
  if (checkLoggedInUser.user.profile_setup == 2) {
    is_subscription_active = 2;
  }

  const { mutate: bookingCallStatusChanges } = useBookingCallStatusChanges();
  if (
    localStorage.getItem('video-call-id') &&
    history &&
    history.pathname &&
    history.pathname.split('/')[1] !== 'video-call'
  ) {
    setBookingId(localStorage.getItem('video-call-id'));
    leave(bookingCallStatusChanges);
    localStorage.removeItem('video-call-id');
  }
  if (
    localStorage.getItem('timeInterval') &&
    history &&
    history.pathname &&
    history.pathname.split('/')[1] !== 'video-call'
  ) {
    clearInterval(localStorage.getItem('timeInterval'));
    localStorage.removeItem('timeInterval');
  }
  if (
    localStorage.getItem('timeIntervalJoin') &&
    history &&
    history.pathname &&
    history.pathname.split('/')[1] !== 'video-call'
  ) {
    clearInterval(localStorage.getItem('timeIntervalJoin'));
    localStorage.removeItem('timeIntervalJoin');
  }
  if (history && history.pathname && history.pathname === CLINIC) stepsCompleted = 2;
  console.log(myHistory, 'myHistory');
  return (
    <>
      <div {...props} className="common-container">
        {isLoggedIn === true && stepsCompleted === 1 && is_subscription_active === 2 ? (
          <AfterSignupPageNavbar t={t} />
        ) : (
          <OtherPageNavbar t={t} />
        )}
        <section className="main-section">{props.children}</section>
        <OtherPageFooter t={t} />
      </div>
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any,
  active: PropTypes.string,
};

export { PrivateLayout };
