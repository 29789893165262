import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

const VideoCall = React.lazy(() => import('./VideoCall'));

const BookingRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/:bookings_id" element={<VideoCall t={t} />} />
    </Routes>
  );
};
BookingRoutes.propTypes = {
  t: PropTypes.func,
};
export default BookingRoutes;
