import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import addIcon from 'assets/images/add_square.png';

const Pricing = ({ sub_scripting_plan_data }) => {
  /**
   * Setting props data, and parsing it to get data format
   */
  let subscription_features =
    sub_scripting_plan_data?.home_page_subscription_plan_what_you_will_get_add_new &&
    JSON.parse(sub_scripting_plan_data?.home_page_subscription_plan_what_you_will_get_add_new);
  return (
    <Container fluid className="pricing-container" id="pricing_section">
      <Row className="pricing">
        <Col lg={5} md={6} sm={12} xs={12} className="pricing-detail-section">
          <h2 className="section-heading pricing-heading">
            {sub_scripting_plan_data?.home_page_subscription_title}
          </h2>
          <h4 className="pricing-heading-sub section-title">
            {sub_scripting_plan_data?.home_page_subscription_sub_title}
          </h4>
          <p className="pricing--para section-description">
            {sub_scripting_plan_data?.home_page_subscription_text}
          </p>
          <Link
            to={`${sub_scripting_plan_data?.home_page_subscription_button_link}`}
            className="btn learn-more-btn button-rounded">
            {sub_scripting_plan_data?.home_page_subscription_button_text}
          </Link>
        </Col>
        <Col lg={7} md={6} sm={12} xs={12} className="pricing-points">
          <Card className="pricing-card">
            <Card.Title className="icon-and-title">
              <img src={addIcon} alt="add-icon" />
              <span>{sub_scripting_plan_data?.home_page_subscription_plan_title}</span>
            </Card.Title>
            <Card.Text className="pricing-card-subtext">
              {sub_scripting_plan_data?.home_page_subscription_plan_sub_title}
            </Card.Text>
            <Card.Body className="pricing-card-body">
              <p className="pricing-card-body-title">
                {sub_scripting_plan_data?.home_page_subscription_plan_text}
              </p>
              <ul className="pricing-card-body-lists">
                {subscription_features && (
                  <>
                    {Object.values(subscription_features).length > 0 &&
                      subscription_features?.map((value, key) => {
                        return (
                          <li key={key}>
                            <span>{value.subscription_text}</span>
                          </li>
                        );
                      })}
                  </>
                )}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
Pricing.propTypes = {
  sub_scripting_plan_data: PropTypes.object,
};
export { Pricing };
