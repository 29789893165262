import client from 'libs/HttpClient';

class CardService {
  static storeCardData(request) {
    return client.post('card/add', request);
  }
  static getCardData() {
    return client.get('card/get');
  }
  static deleteCardData(params) {
    return client.delete('card/delete', { params });
  }
  static defaultCardData(request) {
    return client.put('card/default', request);
  }
}
export { CardService };
