import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import GoToTopIcon from 'assets/images/go-to-top-icon.png';
import { getPrivacyCmsSlug, getTermsCmsSlug } from 'store';

const Footer = ({ t, get_in_touch_data }) => {
  let term_cms_slug = useSelector(getTermsCmsSlug);
  let privacy_cms_slug = useSelector(getPrivacyCmsSlug);
  return (
    <Container fluid className="footer-container pb-0" data-aos="fade-down" data-aos-offset="300">
      <Row className="footer-brand-section">
        <Col>
          <Link to="/" className="brand-logo">
            <img
              src={get_in_touch_data?.home_page_get_in_touch_header_logo}
              className="brand-logo--img"
              alt="brand-logo"
            />
          </Link>
        </Col>
      </Row>
      <Row className="footer-content">
        <Col sm={4} xs={12} className="footer-content-address">
          <div className="address">
            <h5 className="address-heading">
              {get_in_touch_data?.home_page_get_in_touch_header_brand_title}
            </h5>
            <p className="address-para">
              {get_in_touch_data?.home_page_get_in_touch_header_headquarters_text}
            </p>
          </div>
        </Col>
        <Col sm={8} xs={12} className="footer-content-more-details">
          <div className="more-info">
            <h5 className="more-info-heading">
              {get_in_touch_data?.home_page_get_in_touch_footer_title}
            </h5>
            <p>{get_in_touch_data?.home_page_get_in_touch_footer_text}</p>
            <p>{get_in_touch_data?.home_page_get_in_touch_header_brand_text}</p>
            <p>{get_in_touch_data?.home_page_get_in_touch_footer_sub_text}</p>
          </div>
        </Col>
      </Row>
      <div className="copy-right-section">
        <p className="copy-right-section-para">
          &#169; {get_in_touch_data?.home_page_get_in_touch_footer_copyright}
          <span className="dbt copyright-hover ps-2">
            Developed By
            <Link
              to="https://www.technource.com/"
              target="_blank"
              className="tooltiplink"
              rel="noopener noreferrer"
              data-title="Web App | Mobile App | Custom Software | AI Development">
              &nbsp; Technource
            </Link>
          </span>
        </p>
        <div className="scroll-top">
          <Link to={`/page/${privacy_cms_slug}`}>
            <span>{t('front.home_page_footer_privacy_policy')} </span>
          </Link>
          <Link to={`/page/${term_cms_slug}`}>
            <span>{t('front.home_page_footer_terms')} </span>
          </Link>
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <span>{t('front.home_page_footer_go_to_top')} </span>
            <img src={GoToTopIcon} alt="go-to-top-icon"></img>
            {/* <i className="fa-solid fa-arrow-up-from-bracket"></i> */}
          </Link>
        </div>
      </div>
    </Container>
  );
};
Footer.propTypes = {
  t: PropTypes.func,
  get_in_touch_data: PropTypes.object,
};
export { Footer };
