import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ShimmerThumbnail } from '../Image';
import ShimmerTitle from '../Title';
import ShimmerText from '../Text';
import ShimmerButton from '../Button';
import ShimmerCard from '../Card';

const ShimmerCartContentBlock = ({
  card,
  title,
  text,
  cta,
  thumbnailWidth,
  thumbnailHeight,
  reverse,
  className,
}) => {
  const renderContent = () => {
    return (
      <div
        className={classNames({
          flex: true,
          'flex-reverse': reverse,
        })}>
        <div
          className={classNames({
            flex: true,
            'w-50': !thumbnailWidth,
          })}>
          <ShimmerThumbnail
            style={{ width: thumbnailWidth, height: thumbnailHeight }}
            className="h-100 m-0"
          />
        </div>
        <div className=" d-flex p-20 w-100">
          <div className="w-50">
            {title && <ShimmerTitle className="w-40" line={1} />}
            {text && <ShimmerText line={3} gap={10} />}
          </div>
          <div className="w-50">
            {cta && (
              <>
                <ShimmerButton className={'mb-4'} />
                <ShimmerButton className={''} />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (!card) return renderContent();

  return (
    <ShimmerCard
      className={classNames({
        [className]: className,
      })}>
      {renderContent()}
    </ShimmerCard>
  );
};

ShimmerCartContentBlock.propTypes = {
  card: PropTypes.bool,
  title: PropTypes.bool,
  text: PropTypes.bool,
  cta: PropTypes.bool,
  thumbnailWidth: PropTypes.number,
  thumbnailHeight: PropTypes.number,
  reverse: PropTypes.bool,
  className: PropTypes.string,
};

ShimmerCartContentBlock.defaultProps = {
  card: true,
  title: false,
  text: false,
  cta: false,
  reverse: false,
  className: false,
};
export default ShimmerCartContentBlock;
