import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial Values of globally declared states in redux
 */
const initialState = {
  privacy_cms_data: '',
  privacy_slug: '',
  is_privacy: false,
  terms_cms_data: '',
  terms_slug: '',
  is_terms: false,
  how_it_cms_data: '',
  how_it_slug: '',
  is_how_it: false,
  contact_us_cms_data: '',
  contact_us_slug: '',
  is_contact_us: false,
};
/**
 * Slice for Privacy Info
 */
const CmsSlice = createSlice({
  name: 'all_cms_data',
  initialState: initialState,
  /**
   * Reducer functions
   */
  reducers: {
    addUpdatePrivacyData: (state, { payload }) => {
      state.privacy_cms_data = payload.cms_data;
      state.privacy_slug = payload.cms_data.slug;
      state.is_privacy = true;
      return state;
    },
    addUpdateTermsData: (state, { payload }) => {
      state.terms_cms_data = payload.cms_data;
      state.terms_slug = payload.cms_data.slug;
      state.is_terms = true;
      return state;
    },
    addUpdateHowItData: (state, { payload }) => {
      state.how_it_cms_data = payload.cms_data;
      state.how_it_slug = payload.cms_data.slug;
      state.is_how_it = true;
      return state;
    },
    addUpdateContactUsData: (state, { payload }) => {
      state.contact_us_cms_data = payload.cms_data;
      state.contact_us_slug = payload.cms_data.slug;
      state.is_contact_us = true;
      return state;
    },
  },
});
const { addUpdatePrivacyData, addUpdateTermsData, addUpdateHowItData, addUpdateContactUsData } =
  CmsSlice.actions;
const getPrivacyCmsData = (state) =>
  state.all_cms_data ? state.all_cms_data.privacy_cms_data : '';
const getIsTerms = (state) => (state.all_cms_data ? state.all_cms_data.is_terms : false);
const getIsPrivacy = (state) => (state.all_cms_data ? state.all_cms_data.is_privacy : false);
const getIsHowIt = (state) => (state.all_cms_data ? state.all_cms_data.is_how_it : false);
const getIsContactUs = (state) => (state.all_cms_data ? state.all_cms_data.is_contact_us : false);
const getPrivacyCmsSlug = (state) => (state.all_cms_data ? state.all_cms_data.privacy_slug : '');
const getTermsCmsData = (state) => (state.all_cms_data ? state.all_cms_data.terms_cms_data : '');
const getTermsCmsSlug = (state) => (state.all_cms_data ? state.all_cms_data.terms_slug : '');
const getHowItCmsData = (state) => (state.all_cms_data ? state.all_cms_data.how_it_cms_data : '');
const getHowItCmsSlug = (state) => (state.all_cms_data ? state.all_cms_data.how_it_slug : '');
const getContactUsCmsData = (state) =>
  state.all_cms_data ? state.all_cms_data.contact_us_cms_data : '';
const getContactUsCmsSlug = (state) =>
  state.all_cms_data ? state.all_cms_data.contact_us_slug : '';
export {
  getPrivacyCmsData,
  getPrivacyCmsSlug,
  getTermsCmsData,
  getTermsCmsSlug,
  getHowItCmsData,
  getHowItCmsSlug,
  getContactUsCmsData,
  getContactUsCmsSlug,
  addUpdatePrivacyData,
  addUpdateTermsData,
  addUpdateHowItData,
  addUpdateContactUsData,
  CmsSlice,
  getIsTerms,
  getIsPrivacy,
  getIsHowIt,
  getIsContactUs,
};
export default CmsSlice.reducer;
