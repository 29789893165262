import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CMSService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Cmg data
 */
const useGetOrganizationCMSData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`get-cms`, params], () => CMSService.getOrganizationCMSData(params), {
    onSuccess,

    onError,
  });
};
const useGetCMSData = (params, onSuccess, onError = onDefaultError) => {
  console.log(params, 'params');
  return useQuery([`cms-view`, params], () => CMSService.getCMSData(params), {
    onSuccess,
    onError,
    enabled: !params.enabled,
  });
};
const useUpdateCmsData = (onSuccess, onError = onDefaultError) => {
  return useMutation(CMSService.updateCMSData, {
    onSuccess,

    onError,
  });
};
export { useGetCMSData, useUpdateCmsData, useGetOrganizationCMSData };
