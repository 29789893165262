import client from 'libs/HttpClient';
class CMSService {
  static getCMSData(params) {
    return client.get(`cms/get`, { params });
  }
  static getOrganizationCMSData(params) {
    console.log(params, 'slug');
    return client.get(`cms/get-organization-cms-data/`, { params });
  }
  static updateCMSData(params) {
    return client.put(`cms/update-cms`, params);
  }
}
export { CMSService };
