import client from 'libs/HttpClient';
class CommonService {
  static removeFile(params) {
    return client.delete(`common/file-upload-remove`, { params });
  }
  static getStateList() {
    return client.get(`common/state-list`);
  }
  static getPharmacyList() {
    return client.get(`common/get-pharmacy-data`);
  }
  static getPaymentList() {
    return client.get(`common/get-payment-data`);
  }
  static getEmailCategoryList(request) {
    return client.get('common/email-category/organization', request);
  }
  static getFrequencyList(request) {
    return client.get('common/frequency-type-list', request);
  }
}
export { CommonService };
