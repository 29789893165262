import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const HowItWorks = ({ how_its_work_data }) => {
  const {
    home_page_how_its_work_favicon_1,
    home_page_how_its_work_favicon_2,
    home_page_how_its_work_favicon_3,
    home_page_how_its_work_favicon_4,
    home_page_how_its_work_header_title_1,
    home_page_how_its_work_header_title_2,
    home_page_how_its_work_header_title_3,
    home_page_how_its_work_header_title_4,
    home_page_how_its_work_header_text_1,
    home_page_how_its_work_header_text_2,
    home_page_how_its_work_header_text_3,
    home_page_how_its_work_header_text_4,
    home_page_how_its_work_header_button_link_1,
    home_page_how_its_work_header_button_link_2,
    home_page_how_its_work_header_button_link_3,
    home_page_how_its_work_header_button_link_4,
    home_page_how_its_work_header_button_text_1,
    home_page_how_its_work_header_button_text_2,
    home_page_how_its_work_header_button_text_3,
    home_page_how_its_work_header_button_text_4,
    home_page_how_its_work_header_title,
    home_page_how_its_work_header_sub_title,
    home_page_how_its_work_header_text,
  } = how_its_work_data;

  const cardData = [
    {
      favicon: home_page_how_its_work_favicon_1,
      title: home_page_how_its_work_header_title_1,
      text: home_page_how_its_work_header_text_1,
      link: home_page_how_its_work_header_button_link_1,
      buttonText: home_page_how_its_work_header_button_text_1,
    },
    {
      favicon: home_page_how_its_work_favicon_2,
      title: home_page_how_its_work_header_title_2,
      text: home_page_how_its_work_header_text_2,
      link: home_page_how_its_work_header_button_link_2,
      buttonText: home_page_how_its_work_header_button_text_2,
    },
    {
      favicon: home_page_how_its_work_favicon_3,
      title: home_page_how_its_work_header_title_3,
      text: home_page_how_its_work_header_text_3,
      link: home_page_how_its_work_header_button_link_3,
      buttonText: home_page_how_its_work_header_button_text_3,
    },
    {
      favicon: home_page_how_its_work_favicon_4,
      title: home_page_how_its_work_header_title_4,
      text: home_page_how_its_work_header_text_4,
      link: home_page_how_its_work_header_button_link_4,
      buttonText: home_page_how_its_work_header_button_text_4,
    },
  ];

  const renderCard = ({ favicon, title, text, link, buttonText }, index) => (
    <Col key={index} md={6} sm={12} xs={12}>
      <Card className="card-container m-0 card-section">
        <div className="card-icon mb-2">
          <Image src={favicon} alt={title} />
        </div>
        <Card.Body className="card-body-container">
          <Card.Title className="card-heading">{title}</Card.Title>
          <Card.Text className="card-para">{text}</Card.Text>
          <div className="card-link-and-icon">
            <Link to={link} className="card-link">
              <span>{buttonText}</span>
              <FontAwesomeIcon icon={faPlay} />
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <div id="how_it_works_section">
      <Container fluid className="how-it-works-container">
        <Row className="how-it-works g-5">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="how-it-works-box"
            data-aos="fade-up"
            data-aos-offset="300">
            <Row className="g-4">{cardData.map((data, index) => renderCard(data, index))}</Row>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="how-it-works-text"
            data-aos="fade-down"
            data-aos-offset="300">
            <h2 className="section-heading how-it-works-heading">
              {home_page_how_its_work_header_title}
            </h2>
            <h4 className="how-it-works-heading--two section-title">
              {home_page_how_its_work_header_sub_title}
            </h4>
            <p className="how-it-works-para section-description">
              {home_page_how_its_work_header_text}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

HowItWorks.propTypes = {
  how_its_work_data: PropTypes.object,
};

export { HowItWorks };
