import React from 'react';
import PropTypes from 'prop-types';
import instagram from 'assets/images/instagram.png';
import twitter from 'assets/images/twitter.png';
import LinkedIn from 'assets/images/linkedin.png';
import facebook from 'assets/images/facebook.png';

const SocialMediaIcons = ({ links }) => (
  <div className="get-in-touch-media-icons">
    {links.instagram && (
      <a href={links.instagram} rel="noreferrer">
        <img src={instagram} alt="instagram-icon" loading="lazy" />
      </a>
    )}
    {links.twitter && (
      <a href={links.twitter} rel="noreferrer">
        <img src={twitter} alt="twitter-icon" loading="lazy" />
      </a>
    )}
    {links.linkedin && (
      <a href={links.linkedin} rel="noreferrer">
        <img src={LinkedIn} alt="linkedin-icon" loading="lazy" />
      </a>
    )}
    {links.facebook && (
      <a href={links.facebook} rel="noreferrer">
        <img src={facebook} alt="facebook-icon" loading="lazy" />
      </a>
    )}
  </div>
);

SocialMediaIcons.propTypes = {
  links: PropTypes.shape({
    instagram: PropTypes.string,
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    facebook: PropTypes.string,
  }),
};

export default SocialMediaIcons;
