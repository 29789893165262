import client from 'libs/HttpClient';

class DomainService {
  static getDomainProvider() {
    return client.get('domain/get-domain-provider');
  }
  static assignDomain(request) {
    return client.put('domain/assign', request);
  }
  static sslInstallDomain() {
    return client.get('domain/ssl-install');
  }
  static sslRemoveDomain() {
    return client.get('domain/ssl-remove');
  }
}
export { DomainService };
