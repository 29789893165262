import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import { ReactComponent as PreviouArrow } from 'assets/images/previous-arrow.svg';
import { ShimmerSectionHeader, ShimmerText } from 'common';

const CMSViewShimmer = () => {
  let navigate = useNavigate();
  return (
    <Container className="cms-container">
      <div className="cms-page">
        <div className="cms-page-inner">
          <PreviouArrow className="cms-page-inner-icon" onClick={() => navigate(-1)} />
          <h2 className="auth-heading cms-page-inner-heading text-start">
            <ShimmerSectionHeader title={true} center={false} />
          </h2>
          <p></p>
        </div>
        <p className="cms-page-description">
          <ShimmerText line={15} gap={10} />
        </p>
      </div>
    </Container>
  );
};
export default CMSViewShimmer;
