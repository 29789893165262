import Yup from 'helpers/customValidation';

const validationSchema = Yup.object().shape(
  {
    file_path: Yup.array().when('note', {
      is: (note) => !note || note.length === 0,
      then: Yup.array().required('front.note_image_required'),
      otherwise: Yup.array(),
    }),
    note: Yup.string().when('file_path', {
      is: (file_path) => !file_path || file_path.length === 0,
      then: Yup.string('front.order_note_required').required('front.order_note_required').trim(),
      otherwise: Yup.string(),
    }),
  },
  ['file_path', 'note']
);
export default validationSchema;
