import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import CMSViewShimmer from './CMSViewShimmer';

/**
 * CMS Pages, and Routes
 */
const CMSViewPage = React.lazy(() => import('./CMSViewPage'));
const CmsRoutes = ({ t }) => {
  return (
    <Routes>
      <Route
        exact
        path={`/:slug`}
        element={
          <Suspense fallback={<CMSViewShimmer t={t} />}>
            <CMSViewPage t={t} />
          </Suspense>
        }
      />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CmsRoutes.propTypes = {
  t: PropTypes.func,
};
export default CmsRoutes;
