export const SIGNUP = '/signup/';
export const LOGIN = '/login/';
export const SIGNUP_OTP_VERIFY = '/signup/otp-verification';
export const FORGOT_PASSWORD = '/forget-password';
export const OTP_VERIFY = '/otp-verification';
export const RESET_PASSWORD = '/reset-password';
export const SET_PASSWORD = '/set-password/';
export const DASHBOARD = '/dashboard';
export const SUBSCRIPTION = '/subscription';
export const CLINIC = '/clinic';
export const AUTH = '/auth';
export const SETTINGS = '/settings';
export const PATIENT = '/patient';
export const LIST = '/list';
export const SUBSCRIPTION_DETAILS = '/subscription-details';
export const PROFILE = '/profile';
export const GENERAL = '/general';
export const EDIT_PROFILE = '/edit-profile';
export const UPDATE_EMAIL = '/update-email';
export const VERIFY_EMAIL = '/verify-email';
export const CHANGE_PASSWORD = '/change-password';
export const SETTINGS_EDIT_PROFILE = SETTINGS + EDIT_PROFILE;
export const SETTINGS_UPDATE_EMAIL = SETTINGS + PROFILE + UPDATE_EMAIL;
export const SETTINGS_VERIFY_EMAIL = SETTINGS + PROFILE + VERIFY_EMAIL;
export const SETTINGS_CHANGE_PASSWORD = SETTINGS + PROFILE + CHANGE_PASSWORD;
export const BOOKING_LIST = '/booking-list';
export const SETTINGS_BOOKING_LIST = SETTINGS + BOOKING_LIST;
export const PRESCRIPTION_VALIDATE = '/prescription-validities-list';
export const SETTINGS_PRESCRIPTION_VALIDATE = SETTINGS + PRESCRIPTION_VALIDATE;
export const FORMULARY = '/formulary';
export const INTAKE_QUESTIONS = '/intake-question';
export const PRODUCTS = '/products/';
export const MY_ORDERS = '/my-orders';
export const ORDERS = '/orders';
export const DETAILS = '/details';
export const MY_ORDERS_DETAILS = MY_ORDERS + DETAILS;
export const MY_ORDERS_INTAKE = MY_ORDERS + INTAKE_QUESTIONS;
export const CHAT = '/chat';
export const VIDEO_CALL = '/video-call';
export const PATIENT_CHART = '/patient-chart';
export const PAYMENT_INFO = '/payment-info';
export const BRAND_COLOR_DETAIL = '/brand-color-detail';
export const STAFF = '/staff';
export const COUPON = '/coupon';
export const TEMPLATE = '/template';
export const FORMULARY_PHARMACY = '/formulary-pharmacy';
export const AUTH_FORMULARY_PHARMACY = AUTH + FORMULARY_PHARMACY;
export const AUTH_SUBSCRIPTION_DETAILS = AUTH + SUBSCRIPTION_DETAILS;
export const COMPANY_INTAKE_INFO = '/company-intake-info';
export const FORMULARY_QUESTION = '/formulary-questions';
export const ALL_SET = '/all-set';
export const COMPANY_INFORMATION_PAGE1 = '/company-information-general';
export const COMPANY_INFORMATION_PAGE2 = '/company-information-questions';
export const PRACTICE_INFORMATION = '/practice-information';
export const LIST_QUESTION_FORMULARY = '/list-questionary-formulary';
export const COMPANY_STAFF_INFO = '/company-staff-info';
export const AUTH_COMPANY_INFORMATION_PAGE1 = AUTH + COMPANY_INFORMATION_PAGE1;
export const AUTH_COMPANY_INFORMATION_PAGE2 = AUTH + COMPANY_INFORMATION_PAGE2;
export const AUTH_PRACTICE_INFORMATION = AUTH + PRACTICE_INFORMATION;
export const AUTH_COMPANY_INTAKE_INFO = AUTH + COMPANY_INFORMATION_PAGE2;
export const AUTH_COMPANY_STAFF_INFO = AUTH + COMPANY_STAFF_INFO;
export const AUTH_ALL_SET = AUTH + ALL_SET;
export const ADD = '/add';
export const EDIT = '/edit';
export const BANNER = '/banner';
export const WHO_WE_ARE = '/who-we-are';
export const CLIENT = '/client';
export const GET_IN_TOUCH = '/get-in-touch';
export const SEO = '/seo-data';
export const CMS = '/cms';
export const HOME_REDIRECT = '/home-redirect';
export const REDIRECT = '/general';
export const GENERAL_DETAILS = '/general-details';
export const BASIC_DETAILS = '/basic-details';
export const INSURANCE_DETAILS = '/insurance-details';
export const QUESTIONARY_LIST = '/questionary-list';
export const WEBSITE_SETTINGS = '/website-settings';
export const ORDER_EMAIL = '/order-email';
export const ORDER_ACCEPT_EMAIL = '/order-accept-email';
export const COMPANY_INFORMATION = '/company-information';
export const SETTINGS_STAFF_LIST = SETTINGS + STAFF + LIST;
export const SETTINGS_WEBSITE_SETTINGS = SETTINGS + WEBSITE_SETTINGS + BANNER;
export const SETTINGS_FORMULARY_LIST = SETTINGS + FORMULARY + LIST;
export const SETTINGS_PATIENT_LIST = SETTINGS + PATIENT + LIST;
export const SETTINGS_COUPON_LIST = SETTINGS + COUPON + LIST;
export const SETTINGS_PATIENT_GENERAL_DETAILS = SETTINGS + PATIENT + GENERAL_DETAILS;
export const SETTINGS_PATIENT_BASIC_DETAILS = SETTINGS + PATIENT + BASIC_DETAILS;
export const SETTINGS_PATIENT_INSURANCE_DETAILS = SETTINGS + PATIENT + INSURANCE_DETAILS;
