import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return text ? (
    text.length <= 50 ? (
      text
    ) : (
      <p className="text">
        {isReadMore ? text.slice(0, 50) : text}
        <a
          href="javascript:void(0);"
          onClick={toggleReadMore}
          className="read-or-hide text-primary">
          {isReadMore ? '...read more' : ' show less'}
        </a>
      </p>
    )
  ) : (
    '-'
  );
};
ReadMore.propTypes = {
  children: PropTypes.string,
};
export default ReadMore;
