import client from 'libs/HttpClient';
class DrVisitFeeSettingsService {
  static getAllDrVisitFee(params) {
    return client.get('dr-visit-fee/list-dr-visit-fee', { params });
  }
  static getAllOrgState(params) {
    return client.get('dr-visit-fee/list-org-state', { params });
  }

  static getAllOrgSelectedState(params) {
    return client.get('dr-visit-fee/list-selected-org-state', { params });
  }

  static getAllDrVisitType(params) {
    return client.get('dr-visit-fee/list-dr-visit-type', { params });
  }

  static getDrVisitType(params) {
    return client.get('dr-visit-fee/list-dr-visit-name', { params });
  }

  static addDrVisitFee(request) {
    return client.post('dr-visit-fee/add-dr-visit-fee', request);
  }
  static updateDrVisitFee(request) {
    return client.put(`dr-visit-fee/update-dr-visit-fee`, request);
  }
  static viewDrVisitFee(params) {
    return client.get(`dr-visit-fee/get-dr-visit-fee`, { params });
  }
  static deleteDrVisitFee(params) {
    return client.delete(`dr-visit-fee/delete`, { params });
  }
  static viewPreFieldData(params) {
    return client.get(`dr-visit-fee/pre-field-data`, { params });
  }
}

export { DrVisitFeeSettingsService };
