import client from 'libs/HttpClient';

class StaffServices {
  static listStaff(params) {
    return client.get('staff/list-staff', { params });
  }
  static staffAdd(request) {
    return client.put('staff/add-staff', request);
  }
  static updateStatusStaff(request) {
    return client.put(`staff/update-status`, request);
  }
  static updateStaff(request) {
    return client.put(`staff/edit-staff`, request);
  }
  static viewStaff({ user_id }) {
    return client.get(`staff/get-staff/${user_id}`);
  }
  static deleteStaff(params) {
    return client.delete(`staff/delete-staff`, { params });
  }
  static storeStaffGeneralInfoData(request) {
    return client.put('staff/store-general-info', request);
  }
  static getStaffGeneralData({ user_id }) {
    return client.get(`staff/get-staff-general-info/${user_id}`);
  }
  static getStaffAndDoctorList(params) {
    return client.get(`staff/get-staff-doctor-list`, { params });
  }
}

export { StaffServices };
