import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Chat Module process routes, and pages
 */

const ChatMainPage = React.lazy(() => import('./ChatMainPage/ChatMainPage'));
const ChatUser = React.lazy(() => import('./ChatUser'));

const ChatRoutes = ({ t }) => {
  const location = useLocation();
  return (
    <Routes>
      <Route exact path="/" element={<ChatUser t={t} />} forceRefresh={true} key={location.key} />
      <Route
        exact
        path="/:type/:user_chat_room_id"
        element={<ChatMainPage t={t} forceRefresh={true} key={location.key} />}
      />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ChatRoutes.propTypes = {
  t: PropTypes.func,
};
export default ChatRoutes;
