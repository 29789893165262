import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const AboutUsPage = ({ about_us_data }) => {
  return (
    <div id="about_us_section">
      <Container fluid className="about-container">
        <Row className="about-us m-0 g-4">
          <Col
            lg={4}
            md={5}
            sm={12}
            xs={12}
            className="about-us-text"
            data-aos="fade-up"
            data-aos-offset="300">
            <h2 className="section-heading about-heading">
              {about_us_data?.home_page_about_us_header_title}
            </h2>
            <h4 className="about-heading-subheading section-title">
              {about_us_data?.home_page_about_us_header_sub_title}
            </h4>
            <p className="about-us-para section-description">
              {about_us_data?.home_page_about_us_header_text}
            </p>
            <Link
              to={`${about_us_data?.home_page_about_us_button_link}`}
              className="btn learn-more-btn button-rounded">
              {about_us_data?.home_page_about_us_button_text}
            </Link>
          </Col>
          <Col
            lg={4}
            md={5}
            sm={12}
            xs={12}
            className="about-us-image"
            data-aos="fade-down"
            data-aos-offset="300">
            <img
              className="about-us-img"
              src={about_us_data?.home_page_about_us_image}
              alt="vector-img"
              data-aos="fade-up"
              data-aos-delay={300}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
AboutUsPage.propTypes = {
  about_us_data: PropTypes.object,
};
export { AboutUsPage };
