import client from 'libs/HttpClient';
class AnalyticsSettingsService {
  static getAllAnalytics(params) {
    return client.get('custom-analytics/list-analytics', { params });
  }
  static addAnalytics(request) {
    return client.post('custom-analytics/add-custom-analytics', request);
  }
  static updateAnalytics(request) {
    return client.put(`custom-analytics/update-analytics/`, request);
  }
  static viewAnalytics(params) {
    return client.get(`custom-analytics/get-analytics`, { params });
  }
  static deleteAnalytics(params) {
    return client.delete(`custom-analytics/delete-custom-analytics`, { params });
  }
}

export { AnalyticsSettingsService };
