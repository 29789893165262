import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import 'assets/scss/styles.scss';
import { PublicLayout } from 'common';
import { LoadingSpinner } from 'common';
import { useSelector } from 'react-redux';
import { loader } from 'store';
import { useLocationHistory } from '../context/LocationHistoryContext';

const PublicRoute = () => {
  const checkLoading = useSelector(loader);
  const location = useLocation();
  const { push } = useLocationHistory();

  useEffect(() => {
    push(location.pathname);
  }, [location]);
  return (
    <PublicLayout>
      {checkLoading ? <LoadingSpinner /> : ''} <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
