import client from 'libs/HttpClient';

class Availabilities {
  static getAvailabilitiesResponseData(params) {
    return client.get('availability/get-list-availability', { params });
  }
  static updateAvailabilityData(request) {
    return client.post('availability/save', request);
  }
  static getDateSpecificAvailabilities(params) {
    return client.get('availability/date-specific-availability-get', { params });
  }
  static updateDateSpecificAvailabilityData(request) {
    return client.post('availability/date-specific-availability-save', request);
  }
}

export { Availabilities };
