import client from 'libs/HttpClient';
import { encryptValues } from '../helpers';

class SmtpService {
  static getSmtpProvider() {
    return client.get('/smtp/get-smtp-provider');
  }
  static getSmtpDetail() {
    return client.get('/smtp/get-smtp-details');
  }
  static storeSmtpProvider(request) {
    const encryptedObj = {};
    for (const key in request) {
      if (!['smtp_provider_id', 'secure', 'status'].includes(key) && request[key]) {
        encryptedObj[key] = encryptValues(request[key]);
      } else {
        encryptedObj[key] = request[key];
      }
    }
    return client.put('/smtp/store', encryptedObj);
  }
  static approveSmtpProvider(request) {
    return client.put('/smtp/approve', request);
  }
  static testSmtpProvider(request) {
    return client.put('/smtp/test', request);
  }
  static deleteSmtpProvider(request) {
    return client.delete('/smtp/delete', request);
  }
}
export { SmtpService };
