import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial Values of globally declared states in redux
 */
const initialState = {
  setting_get: true,
  home_page_general_header_logo: '',
  home_page_general_header_sub_logo: '',
  home_page_general_email_logo: '',
  home_page_general_favicon_logo: '',
  home_page_general_step_image: '',
  home_page_general_seo_title: '',
  home_page_general_seo_description: '',
  home_page_general_copyright_text: '',
  home_page_general_doctor_visit_fees: '',
};

/**
 * Slice for Setting Data
 */
const settingSlice = createSlice({
  name: 'setting',
  initialState: initialState,
  /**
   * Reducer functions for settings info
   */
  reducers: {
    addSetting: (state, { payload }) => {
      state.home_page_general_header_logo = payload.home_page_general_header_logo;
      state.home_page_general_header_sub_logo = payload.home_page_general_header_sub_logo;
      state.home_page_general_email_logo = payload.home_page_general_email_logo;
      state.home_page_general_favicon_logo = payload.home_page_general_favicon_logo;
      state.home_page_general_step_image = payload.home_page_general_step_image;
      state.home_page_general_seo_title = payload.home_page_general_seo_title;
      state.home_page_general_seo_description = payload.home_page_general_seo_description;
      state.home_page_general_copyright_text = payload.home_page_general_copyright_text;
      state.home_page_general_doctor_visit_fees = payload.home_page_general_doctor_visit_fees;
      state.setting_get = payload.setting_get;

      return state;
    },
    clearSetting: () => initialState,
  },
});
const { addSetting, clearSetting } = settingSlice.actions;
const settingData = (state) => state.setting;
export { addSetting, clearSetting, settingData, settingSlice };
export default settingSlice.reducer;
