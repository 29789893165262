import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'assets/scss/styles.scss';
import { PrivateLayout } from 'common';
import { loader } from 'store';
import { loggedUser } from 'store';
import { LoadingSpinner } from 'common';
import { useLocationHistory } from '../context/LocationHistoryContext';
import { checkSubscription } from 'helpers';
import { SETTINGS } from 'config';
import { LOGIN } from 'config';
import { SUBSCRIPTION } from 'config';
import { CLINIC } from 'config';

const PrivateRoute = () => {
  /**
   * using selector hooks from redux to get data
   */
  const location = useLocation();
  const { push } = useLocationHistory();

  useEffect(() => {
    push(location.pathname);
  }, [location]);
  const checkLoading = useSelector(loader);
  const checkLoggedInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggedInUser.isLoggedIn;
  const cancelSubscription = checkLoggedInUser.cancelSubscription;
  const userSubscription = checkLoggedInUser.userSubscription;
  let is_subscription_active = checkSubscription(userSubscription, cancelSubscription);
  if (checkLoggedInUser.user.profile_setup == 2) {
    is_subscription_active = 2;
  }
  if (isLoggedIn === false) {
    if (location.pathname?.includes('video-call')) {
      const link = location.pathname;
      localStorage.setItem('link', link);
    } else {
      localStorage.removeItem('link');
    }
  }
  console.log(location, 'private route');
  const route = location.pathname.split('/');
  const findRoute = route.includes('all-orders');
  return isLoggedIn ? (
    is_subscription_active === 1 &&
    location &&
    location.pathname !== SUBSCRIPTION &&
    location.pathname !== CLINIC ? (
      <Navigate
        to={{
          pathname: SUBSCRIPTION,
          state: { from: location },
        }}
      />
    ) : checkLoggedInUser.user.user_type === 4 &&
      location &&
      location.pathname.includes('/settings/staff-') ? (
      <Navigate
        to={{
          pathname: SETTINGS,
          state: { from: location },
        }}
      />
    ) : (
      <PrivateLayout>
        {checkLoading ? <LoadingSpinner /> : ''}
        <Outlet />
      </PrivateLayout>
    )
  ) : (
    <Navigate
      to={{
        pathname: isLoggedIn && findRoute ? location.pathname : LOGIN,
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
