import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const LocationHistoryContext = createContext();

const LocationHistoryProvider = ({ children }) => {
  const [myHistory, setMyHistory] = useState([]);

  const push = (location) => {
    const arr = [...myHistory, location];
    // setMyHistory([...new Set(arr.map((obj) => obj))]);
    setMyHistory(arr);
  };
  console.log(myHistory, myHistory[myHistory.length - 2], 'private');
  return (
    <LocationHistoryContext.Provider value={{ myHistory, push }}>
      {children}
    </LocationHistoryContext.Provider>
  );
};
LocationHistoryProvider.propTypes = {
  children: PropTypes.any,
};
const useLocationHistory = () => useContext(LocationHistoryContext);
export { LocationHistoryProvider, useLocationHistory };
