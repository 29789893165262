import React from 'react';
import { Spinner } from 'react-bootstrap';
const LoadingShimmer = () => {
  return (
    <>
      <div className="loader-container">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <div className="loader-text sub-text">Loading.....</div>
      </div>
    </>
  );
};
export default LoadingShimmer;
