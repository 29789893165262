import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { AnalyticsSettingsService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Cmg data
 */
const useGetAllAnalytics = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-analytics`, params],
    () => AnalyticsSettingsService.getAllAnalytics(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useUpdateAnalytics = (onSuccess, onError = onDefaultError) => {
  return useMutation(AnalyticsSettingsService.updateAnalytics, {
    onSuccess,
    onError,
  });
};

const useAddAnalytics = (onSuccess, onError = onDefaultError) => {
  return useMutation(AnalyticsSettingsService.addAnalytics, {
    onSuccess,
    onError,
  });
};

const useViewAnalytics = (organization_analytics_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['analytics-view', organization_analytics_id],
    () => AnalyticsSettingsService.viewAnalytics({ organization_analytics_id }),
    {
      onSuccess,
      onError,
    }
  );
};

const useAnalyticsDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(AnalyticsSettingsService.deleteAnalytics, {
    onSuccess,
    onError,
  });
};

export {
  useGetAllAnalytics,
  useUpdateAnalytics,
  useAddAnalytics,
  useViewAnalytics,
  useAnalyticsDelete,
};
