import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';

import { defaultValue } from 'helpers';
import PhoneNumberInput from './PhoneNumberInput';
import { useGetCountryCodeList } from 'hooks';
import TNInput from './TNInput';

const CustomPhoneNumber = (props) => {
  const {
    t,
    country_id,
    setFieldValue,
    touched_country_id,
    error_country_id,
    touched_phone,
    error_phone,
    handleChange,
    handleBlur,
    phone,
    labelPhone,
    classNameNumber,
    labelCode,
    className,
    classNamePrefix,
    label,
    md,
    lg,
    // type,
  } = props;
  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();
  console.log(touched_country_id, error_country_id, touched_phone, error_phone, 'errors');
  const options = [];
  if (countryCodeList && countryCodeList.data.length > 0) {
    countryCodeList.data.map((val) => {
      return options.push({
        value: val.country_id,
        label: val.phone_code + ' (' + val.name + ')',
      });
    });
  }
  return (
    <>
      <Row className="pe-0">
        {label && <Form.Label className="edit-profile-label">{label}</Form.Label>}
        <Col md={md || 2}>
          <TNInput.Select
            label={labelCode ? labelCode : ''}
            name="country_id"
            value={defaultValue(options, country_id)}
            defaultValue={country_id}
            placeholder={t('front.auth_signup_country_id_placeholder')}
            onChange={(selectedOption) => {
              if (selectedOption !== null) {
                setFieldValue('country_id', selectedOption.value);
              } else {
                setFieldValue('country_id', '');
              }
            }}
            classNamePrefix={`${classNamePrefix ? classNamePrefix : ''}`}
            className={className ? className : ''}
            options={countryIsLoad ? options : options}
            errors={error_country_id}
            touched={touched_country_id}
          />
        </Col>
        <Col md={lg || 4} className="form-fields">
          <Form.Group>
            {labelPhone && <Form.Label className={`form-label`}>{labelPhone} </Form.Label>}
            <PhoneNumberInput
              name="phone"
              placeholder={t('front.auth_signup_contact_placeholder')}
              type="text"
              className={`${classNameNumber ? classNameNumber : 'auth-input-field'}  `}
              onChange={handleChange}
              onBlur={handleBlur}
              value={phone}
            />
            <div className="form-field-error-text">
              {touched_phone && error_phone ? (
                <div className="input-error-message">{t(error_phone)}</div>
              ) : null}
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
CustomPhoneNumber.propTypes = {
  t: PropTypes.any,
  country_id: PropTypes.any,
  phone: PropTypes.any,
  setFieldValue: PropTypes.any,
  touched_country_id: PropTypes.any,
  error_country_id: PropTypes.any,
  touched_phone: PropTypes.any,
  error_phone: PropTypes.any,
  handleChange: PropTypes.any,
  handleBlur: PropTypes.any,
  labelPhone: PropTypes.any,
  labelCode: PropTypes.any,
  className: PropTypes.any,
  classNameNumber: PropTypes.string,
  md: PropTypes.any,
  lg: PropTypes.any,
  label: PropTypes.any,
  classNamePrefix: PropTypes.string,
};
export default CustomPhoneNumber;
