export const API_URL = process.env.REACT_APP_API_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;
export const CHAT_URL = process.env.REACT_APP_CHAT_URL;
export const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;
export const ABOUT_PAGE_ID = 1;
export const TERMS_PAGE_ID = 2;
export const PRIVACY_PAGE_ID = 3;
export const HOW_IT_WORKS_PAGE_ID = 4;
export * from './routes';
export * as routes from './routes';
export * from './option';
