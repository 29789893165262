import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { AccountSettingsServices } from 'api';

/**
 * Hook for user Information after signup
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetUserProfile = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-profile', AccountSettingsServices.getUserProfile, {
    onSuccess,
    onError,
  });
};

const useGetCountryCodeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('country-code-list', AccountSettingsServices.getCountryCodeList, {
    onSuccess,
    onError,
  });
};

const useGetTimezoneList = (onSuccess, onError = onDefaultError) => {
  return useQuery('timezone-list', AccountSettingsServices.getTimezoneList, {
    onSuccess,
    onError,
  });
};

const useUpdateUserProfile = (onSuccess, onError = onDefaultError) => {
  return useMutation(AccountSettingsServices.updateUserProfile, {
    onSuccess,
    onError,
  });
};

const useChangeUserPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AccountSettingsServices.updateUserPassword, {
    onSuccess,
    onError,
  });
};
const useUpdateUserEmail = (onSuccess, onError = onDefaultError) => {
  return useMutation(AccountSettingsServices.updateUserEmail, {
    onSuccess,
    onError,
  });
};
const useUpdateUserEmailVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(AccountSettingsServices.updateUserEmailVerify, {
    onSuccess,
    onError,
  });
};
const useResendEmailOtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AccountSettingsServices.resendOtp, {
    onSuccess,
    onError,
  });
};
const useDeactivateUserStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(AccountSettingsServices.updateUserStatus, {
    onSuccess,
    onError,
  });
};
export {
  useGetUserProfile,
  useUpdateUserEmail,
  useUpdateUserProfile,
  useChangeUserPassword,
  useGetCountryCodeList,
  useGetTimezoneList,
  useUpdateUserEmailVerify,
  useResendEmailOtp,
  useDeactivateUserStatus,
};
