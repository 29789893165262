import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { DomainService } from 'api';
/**
 * Hook for user Information after signup
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetDomainProvider = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-domain-provider', DomainService.getDomainProvider, {
    onSuccess,
    onError,
  });
};
const useSslInstallDomain = (onSuccess, onError = onDefaultError) => {
  return useMutation(DomainService.sslInstallDomain, {
    onSuccess,
    onError,
  });
};
const useSslRemoveDomain = (onSuccess, onError = onDefaultError) => {
  return useMutation(DomainService.sslRemoveDomain, {
    onSuccess,
    onError,
  });
};
const useAssignDomain = (onSuccess, onError = onDefaultError) => {
  return useMutation(DomainService.assignDomain, {
    onSuccess,
    onError,
  });
};
export { useGetDomainProvider, useAssignDomain, useSslInstallDomain, useSslRemoveDomain };
