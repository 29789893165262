import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import PlaceholderImage from 'assets/images/whiteSquare.png';
import { settingData } from 'store';
import { encryptValues, setFormatDate, currencyType } from 'helpers';
import { carePointStatusShow, fillStatusShow, preStatusShow, rxStatusShow } from 'helpers';

const OrderItem = ({ t, order_item, order }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlPath = location.pathname.split('/');

  const checkOrganization = useSelector(settingData);
  return order_item.map((item, index) => {
    return (
      <>
        <Card.Body key={index} className="card-product-details">
          <div className="inner-details">
            <Row className="item-align-center">
              <Col lg={2} md={3} className="card-single-item">
                <div className="order-imgs">
                  <LazyLoadImage
                    alt={'Formulary Image'}
                    key={
                      item.formulary.featured_image
                        ? item.formulary.featured_image
                        : checkOrganization.home_page_general_header_logo
                    }
                    placeholderSrc={PlaceholderImage}
                    className="img-fluid"
                    src={
                      item.formulary.featured_image
                        ? item.formulary.featured_image
                        : checkOrganization.home_page_general_header_logo
                    }
                  />
                </div>
              </Col>
              <Col lg={5} md={6} className="card-single-item">
                <div className="order-product-details">
                  <h3>{item.formulary.name}</h3>
                  {item.formulary.short_description && <p>{item.formulary.short_description}</p>}
                  {item.question_count > 0 &&
                  item.use_question_count > 0 &&
                  item.is_question_fill === 1 ? (
                    <span
                      onClick={() =>
                        navigate(
                          urlPath.includes('all-orders')
                            ? `/all-orders/order/${encryptValues(
                                order.order_id
                              )}/formulary/${encryptValues(item.formulary_id)}`
                            : `/orders/order/${encryptValues(
                                order.order_id
                              )}/formulary/${encryptValues(item.formulary_id)}`
                        )
                      }
                      className="view-link">
                      {t('front.order_view_order_single_intake_response_text')}
                    </span>
                  ) : (
                    ''
                  )}

                  {item?.order_item_info && item?.order_item_info.frequency_type !== 1 && (
                    <>
                      <p
                        style={{ textDecorationLine: 'none', fontSize: '14px' }}
                        className="item-view-sec">
                        {`${item?.order_item_info?.frequency_types?.frequency_name} ${t(
                          'front.org_recurring_text'
                        )} (${t('front.org_recurring_count')} ${
                          item?.order_item_info.frequency_type !== 4
                            ? item?.order_item_info?.recurring_count
                            : t('front.org_recurring_until_cancel')
                        })`}
                      </p>
                      {item?.order_item_info?.next_payment_at !== '' &&
                        item?.order_item_info?.recurring_count !== 0 && (
                          <p style={{ textDecorationLine: 'none' }}>
                            {t('front.org_recurring_next_payment_at')} :
                            {` ${setFormatDate(item?.order_item_info?.next_payment_at)}`}
                          </p>
                        )}
                    </>
                  )}
                  {item?.order_item_info && item.is_cancelled == true && (
                    <p style={{ textDecorationLine: 'none', color: '#A30000' }}>
                      {t('front.org_recurring_order_cancelled_text')}
                    </p>
                  )}
                  {item?.order_item_info && item.is_cancelled_prescription == true && (
                    <p className="mb-1" style={{ color: '#A30000' }}>
                      {t('front.org_recurring_order_prescription_validities_cancelled_text')}
                    </p>
                  )}

                  {item?.order_item_info &&
                    item?.order_item_info.frequency_type !== 1 &&
                    [3, 5].includes(item.rx_status) && (
                      <span
                        style={{
                          textDecorationLine: 'none',
                          color: '#A30000',
                        }}>
                        {t('front.org_recurring_order_reject_by_organization')}
                      </span>
                    )}
                </div>
              </Col>
              <Col lg={2} md={3} className="text-center card-single-item">
                <div className="qty-price mobile-flex-d-row">
                  <span className="qty-details">
                    {t('front.order_view_order_qty_text')} : {item.qty}
                  </span>
                  <br />
                  <span className="price-details mt-1">{`${currencyType()}${item.sub_total}`}</span>
                </div>
              </Col>
              <Col lg={3} className="card-single-item">
                <div className="inner-card-header text-center">
                  <h4>
                    {t('front.order_view_rx_status_label')}: {rxStatusShow(item)}
                  </h4>
                  {item.pharmacy_providers ? (
                    <h4>
                      {t('front.order_view_pharmacy_name_label')}:{' '}
                      <span className={'text-primary'}>{item.pharmacy_providers.name || ''}</span>
                    </h4>
                  ) : (
                    ''
                  )}
                  {item.rx_status === 2 && (item.pre_status || item.carepoint_status) ? (
                    <>
                      <h4>
                        {t('front.order_view_pre_status_label')}:{' '}
                        {item.pre_status && preStatusShow(item)}{' '}
                        {item.transitionrx_fill_status ? (
                          <span>/ {fillStatusShow(item)}</span>
                        ) : null}
                        {item.carepoint_status && carePointStatusShow(item)}
                      </h4>

                      {item.shipped_date && (
                        <h4>
                          {t('front.order_view_shipped_date_label')}:{' '}
                          <span className="text-primary">{setFormatDate(item.shipped_date)}</span>
                        </h4>
                      )}
                      {item.delivered_date && (
                        <h4>
                          {t('front.order_view_delivered_date_label')}:{' '}
                          <span className="text-success">{setFormatDate(item.delivered_date)}</span>
                        </h4>
                      )}
                    </>
                  ) : null}
                </div>

                {item?.order_item_info?.order_item_id === item?.order_item_id && (
                  <>
                    <div className="inner-card-header text-center">
                      {item?.parent_order_id && (
                        <h4>
                          {t('front.org_child_order_label')}:
                          <span>{`#${item.parent_order_id}`}</span>
                        </h4>
                      )}
                    </div>
                    <div className="inner-card-header text-center">
                      {item?.parent_order &&
                        !item.parent_order_id &&
                        item.parent_order.length > 0 && (
                          <h4>
                            {t('front.org_main_order_label')}:
                            {item.parent_order.map((obj, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && ', '}
                                <span style={{ cursor: 'pointer' }}>{`#${obj.order_id}`}</span>
                              </React.Fragment>
                            ))}
                          </h4>
                        )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Card.Body>
      </>
    );
  });
};
OrderItem.propTypes = {
  t: PropTypes.any,
  order_item: PropTypes.any,
  order: PropTypes.any,
};
export default OrderItem;
