import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { ShimmerCategoryList, ShimmerSectionHeader } from 'common';

const SettingShimmers = () => {
  return (
    <Container className="company-info-second-step-container inner-section-container">
      <ShimmerSectionHeader />
      <ShimmerCategoryList items={1} categoryStyle="STYLE_SIX" />
      <ShimmerCategoryList items={1} categoryStyle="STYLE_SIX" />
      <ShimmerCategoryList items={1} categoryStyle="STYLE_SIX" />
      <ShimmerCategoryList items={1} categoryStyle="STYLE_SIX" />
      <ShimmerCategoryList items={1} categoryStyle="STYLE_SIX" />
      <ShimmerCategoryList items={1} categoryStyle="STYLE_SIX" />
    </Container>
  );
};
SettingShimmers.propTypes = {
  t: PropTypes.func,
};
export default SettingShimmers;
