import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Nav, Navbar, Dropdown, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { settingData } from 'store';
import userIcon from 'assets/images/place-holder.png';
import { ReactComponent as HomeIcon } from 'assets/images/home-icon.svg';
import { ReactComponent as MailIcon } from 'assets/images/message-icon.svg';
import { ReactComponent as MailBlankIcon } from 'assets/images/message-icon-blank.svg';
import { ReactComponent as ProfileSvg } from 'assets/images/profile.svg';

import { ReactComponent as OrderIcon } from 'assets/images/order-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings-icon.svg';
import { ReactComponent as AvailabilityIcon } from 'assets/images/availability.svg';
import { loggedUser } from 'store';
import { userGetUnreadMessageCount } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useGetUserProfile } from 'hooks';
import { updateUserType } from 'store';
import { useNavigate } from 'react-router-dom';

import { updateClinic, updateUserData } from 'store';
import { routes } from 'config';

const AfterSignupPageNavbar = ({ t }) => {
  const userTypeText = (user_type) => {
    if (!user_type) return '';
    if (user_type === 1) return t('front.user_type_admin_name');
    if (user_type === 2) return t('front.user_type_organization_name');
    if (user_type === 3) return t('front.user_type_patient_name');
    if (user_type === 4) return t('front.user_type_staff_name');
    if (user_type === 5) return t('front.user_type_super_prescriber_name');
    if (user_type === 6) return t('front.user_type_doctor_name');

    return '';
  };
  const getUserData = useSelector(loggedUser);
  const checkLoggedInUser = useSelector(loggedUser);
  const location = useLocation();
  let allOrderPath = location.pathname.split('/');
  const socket = window.socket;
  socket.on('disconnect', () => {
    socket.emit('user_disconnect', { user_id: checkLoggedInUser.user.user_id });
  }); //prints "transport close"
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [isLoggedIn, setIsLoggedIn] = useState(false);
  let [messageCount, setMessageCount] = useState(0);
  let [isOffline, setIsOffline] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [navBgColor, setNavBgColor] = useState(false);
  const [profilePicture, setProfilePicture] = useState('');

  if (checkLoggedInUser.isLoggedIn && !isLoggedIn) {
    setIsLoggedIn(true);
    if (!isOffline) {
      socket.emit('disconnected', {});
      setIsOffline(true);
    }
  }
  let options = [];
  let optionsType = [];
  if (checkLoggedInUser.clinic_data && checkLoggedInUser.clinic_data.length !== 0) {
    options = checkLoggedInUser.clinic_data.map((item) => {
      return {
        value: item.user_id,
        label: item.company_name,
      };
    });
  }
  if (checkLoggedInUser.user_type_data && checkLoggedInUser.user_type_data.length !== 0) {
    optionsType = checkLoggedInUser.user_type_data.map((item) => {
      return {
        value: item.user_type,
        label: userTypeText(item.user_type),
      };
    });
  }
  const { refetch: doGetProfile } = useGetUserProfile(({ data: profile }) => {
    if (profile) {
      formik.values.clinic_data = profile.clinic_data;
      formik.values.user_type_data = profile.user_type_data;
      formik.values.user_type = profile.userData.user_type;
      if (profile.userData.organization_id) {
        formik.values.clinic_id = parseInt(profile.userData.organization_id);
      }
      formik.values.clinic_user_type = profile.userData.user_type;
    }
    setProfilePicture(profile.userData.profile_image);
    const dataStore = {
      userData: profile.userData,
      cancelSubscription: profile.userCancelSubscription,
      userSubscription: profile.userSubscription,
      multipleAccount: profile.multipleAccount || false,
      clinic_data: profile.clinic_data,
      user_type_data: profile.user_type_data,
    };
    dispatch(updateUserData(dataStore));
  });

  const formik = useFormik({
    initialValues: {
      profile_image: profilePicture,
      clinic_id: checkLoggedInUser.clinic_id,
      clinic_user_type: checkLoggedInUser.user_type,
      clinic_check: options.length !== 0 ? true : false,
      user_type_check: optionsType.length !== 0 ? true : false,
    },
  });
  /**
   * This api will call on update button, and will update Profile data
   */

  const { refetch: chatMessageRefetch } = userGetUnreadMessageCount((res) => {
    setTimeout(() => {
      setMessageCount(res.data.unread_message_count);
    }, 1000);
  });
  if (checkLoggedInUser.isLoggedIn && isLoggedIn) {
    socket.emit('user_connected', {
      from_user_id: getUserData.user.user_id,
      user_chat_room_id: 0,
    });
  }
  useEffect(() => {
    setNavBgColor(false);
    chatMessageRefetch();
    socket.on('check_message', (data) => {
      if (data.to_user_id == getUserData.user.user_id) chatMessageRefetch();
    });
    window.addEventListener('scroll', changeBackground);
  }, [socket, getUserData, chatMessageRefetch]);
  const getSettingData = useSelector(settingData);
  const changeBackground = () => {
    if (window.scrollY >= 3) {
      setNavBgColor(true);
    } else {
      setNavBgColor(false);
    }
  };
  // const setNavExpandedClick = (expanded) => {
  //   setIsActive(expanded);
  // };

  const setNavClose = () => {
    setIsActive(false);
  };

  return (
    <Container fluid className="navbar-container registered-user-navbar">
      <Navbar
        // collapseOnSelect
        expand="md"
        // onToggle={setNavExpandedClick}
        expanded={isActive}
        className={navBgColor || isActive ? 'navbar-dark-bg' : 'navbar-light-bg'}>
        <NavLink to="/" className="brand-logo navbar-brand flex-adjustment">
          <img
            src={
              getSettingData &&
              (navBgColor || isActive
                ? getSettingData.home_page_general_header_sub_logo
                : getSettingData.home_page_general_header_logo)
            }
            className="brand-logo-img"
            alt={'Header Logo'}
          />
        </NavLink>
        {!allOrderPath.includes('all-orders') ? (
          <NavLink to={routes.SETTINGS_EDIT_PROFILE} className="user-image-and-name">
            <span className="nav-link user-image">
              <LazyLoadImage
                alt={'Profile Image'}
                key={getUserData.user.profile_image ? getUserData.user.profile_image : userIcon}
                placeholderSrc={userIcon}
                src={getUserData.user.profile_image ? getUserData.user.profile_image : userIcon}
                width={'40px'}
                height={'40px'}
              />
            </span>
            <span className="user-name">
              <h4 className="name">
                {t('front.registered_user_navbar_user_name_label')}
                &nbsp;
                {getUserData.user.first_name && getUserData.user.first_name},
              </h4>
              <p className="sub-text">
                {t('front.registered_user_navbar_user_name_sub_text_label')}
              </p>
            </span>
          </NavLink>
        ) : (
          <NavLink className="user-image-and-name"></NavLink>
        )}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setIsActive(isActive ? false : true)}>
          <FontAwesomeIcon className="custom-icon" icon={isActive === true ? faXmark : faBars} />
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={' flex-adjustment ' + (isActive ? 'd-block' : 'd-none')}>
          <Nav className="navbar-icons" onSelect={setNavClose}>
            {!allOrderPath.includes('all-orders') && (
              <>
                <NavLink to={routes.DASHBOARD} className="nav-link icon home-icon icon-header">
                  <HomeIcon />
                  <span className="mobile-navbar-links">
                    {t('front.mobile_header_home_link_text')}
                  </span>
                </NavLink>
                <NavLink
                  onClick={setNavClose}
                  to="/chat"
                  target="_blank"
                  className="nav-link icon icon-header">
                  <MailBlankIcon
                    id="messageCounterNo"
                    className={messageCount !== 0 ? 'd-none' : ''}
                  />
                  <span className={messageCount === 0 ? 'd-none' : ''} id="messageCounter">
                    <MailIcon />
                    <span
                      className={`message-item-number ${
                        messageCount === 0 || messageCount <= 99 ? 'itm-for-zero' : ''
                      } ${messageCount >= 100 ? 'itm-more-99' : ''}`}>
                      {messageCount >= 100
                        ? '99+'
                        : messageCount > 0 && messageCount < 10
                        ? `0${messageCount}`
                        : messageCount == 0
                        ? '0'
                        : `${messageCount}`}
                    </span>
                  </span>
                  <span className="mobile-navbar-links">
                    {t('front.mobile_header_message_link_text')}
                  </span>
                </NavLink>
                <NavLink
                  onClick={setNavClose}
                  to={routes.ORDERS}
                  className="nav-link order-icon icon-header">
                  <OrderIcon />
                  <span className="mobile-navbar-links">
                    {t('front.mobile_header_order_link_text')}
                  </span>
                </NavLink>
                <NavLink onClick={setNavClose} to="/settings" className="nav-link icon icon-header">
                  <SettingsIcon />
                  <span className="mobile-navbar-links">
                    {t('front.mobile_header_setting_link_text')}
                  </span>
                </NavLink>
                <NavLink
                  onClick={setNavClose}
                  to="/settings/booking-list"
                  className="nav-link icon icon-header">
                  <AvailabilityIcon />
                  <span className="mobile-navbar-links">
                    {t('front.mobile_header_appointment_link_text')}
                  </span>
                </NavLink>
              </>
            )}
            {options.length !== 0 && formik?.values?.clinic_data?.length > 1 && (
              <NavDropdown
                id="dropdown-link"
                className="nav-link w-100 dropdown-setting p-0 all-orders"
                title={
                  <>
                    <ProfileSvg
                      className={navBgColor || isActive === true ? 'img-svg' : 'profile-icon'}
                    />
                    <span className="mobile-navbar-links">
                      {t('front.mobile_header_choose_profile_setting_link_text')}
                    </span>
                  </>
                }
                onSelect={(eventKey) => {
                  if (eventKey !== null) {
                    formik.setFieldValue('clinic_id', eventKey);

                    if (formik.values.clinic_check) {
                      dispatch(updateClinic({ clinic_id: eventKey }));
                    }
                    doGetProfile(); // Perform any additional actions
                    navigate(routes.DASHBOARD);
                  } else {
                    formik.setFieldValue('clinic_id', '');
                  }
                  setNavClose(true);
                }}>
                <Dropdown.Item
                  as={NavLink}
                  to="/all-orders"
                  className="nav-item-dropdown"
                  active={allOrderPath.includes('all-orders')}>
                  {t('front.all_orders')}
                </Dropdown.Item>
                {options.map((option) => (
                  <>
                    <Dropdown.Item
                      className="nav-item-dropdown"
                      key={option.value}
                      eventKey={option.value}
                      active={
                        option.value === formik.values.clinic_id &&
                        !allOrderPath.includes('all-orders')
                      }>
                      {option.label}
                    </Dropdown.Item>
                  </>
                ))}
              </NavDropdown>
            )}
            {optionsType.length !== 0 && formik?.values?.user_type_data?.length > 1 && (
              <NavDropdown
                id="dropdown-link"
                className="nav-link w-100 dropdown-setting p-0"
                title={
                  <>
                    <ProfileSvg
                      className={navBgColor || isActive === true ? 'img-svg' : 'profile-icon'}
                    />
                    <span className="mobile-navbar-links">
                      {t('front.mobile_header_choose_profile_setting_link_text')}
                    </span>
                  </>
                }
                onSelect={(eventKey) => {
                  if (eventKey !== null) {
                    formik.setFieldValue('clinic_user_type', eventKey);

                    if (formik.values.user_type_check) {
                      dispatch(updateUserType({ user_type: eventKey }));
                    }
                    doGetProfile(); // Perform any additional actions
                    navigate(routes.DASHBOARD);
                  } else {
                    formik.setFieldValue('clinic_user_type', '');
                  }
                  setNavClose(true);
                }}>
                {optionsType.map((option) => (
                  <Dropdown.Item
                    className="nav-item-dropdown"
                    key={option.value}
                    eventKey={option.value}
                    active={option.value === formik.values.user_type}>
                    {option.label}
                  </Dropdown.Item>
                ))}
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};
AfterSignupPageNavbar.propTypes = {
  t: PropTypes.func,
};
export default AfterSignupPageNavbar;
