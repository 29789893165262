import React, { useEffect, useState } from 'react';
import { Container, Card, Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

import 'assets/scss/page/_order_pages.scss';
import 'assets/scss/components/_modal.scss';
import 'assets/scss/page/_view_question_response.scss';
import { ReactComponent as BackIcon } from 'assets/images/previous-arrow.svg';

import { decryptValues } from 'helpers';

import { useDownloadChartDetails } from 'hooks';
import { LoadingSpinner } from 'common';
import { downloadFile } from 'helpers';
import GeneralDetails from './components/GeneralDetails';
import Notes from './components/Notes';
import OrderList from './components/OrderList';
import { useSelector } from 'react-redux';
import { loggedUser } from 'store/features/authSlice';

const PatientChartPage = ({ t }) => {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState('');

  const location = useLocation();
  const urlPath = location.pathname.split('/');

  const loggedInUser = useSelector(loggedUser);
  let LoginUserId = loggedInUser.user.user_id;
  if (urlPath.includes('all-orders')) {
    if (loggedInUser.clinic_data && loggedInUser.clinic_data.length > 0) {
      LoginUserId =
        loggedInUser.isDefaultUser && loggedInUser.isDefaultUser.user_id
          ? loggedInUser.isDefaultUser.user_id
          : loggedInUser.user.user_id;
    } else {
      LoginUserId = loggedInUser.user.user_id;
    }
  } else {
    LoginUserId = loggedInUser.user.user_id;
  }
  const handleClick = () => {
    doDownloadChart({
      user_id: decryptValues(user_id),
      allOrderShow: urlPath.includes('all-orders') ? 'true' : 'false',
      loggedUserId: LoginUserId,
    });
  };

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(timezone);
  }, []);
  const { isLoading: isLoadingDownload, mutate: doDownloadChart } = useDownloadChartDetails(
    ({ data }) => {
      if (data.url) {
        downloadFile(data.url, `PatientChart-${decryptValues(user_id)}.pdf`);
      }
    }
  );

  return (
    <>
      {isLoadingDownload ? <LoadingSpinner></LoadingSpinner> : ''}

      <Container className="order-page inner-section-container">
        <div className="inner-section-content mb-3">
          <div className="inner-previous-icon m-0 mb-sm-0 mb-5 ">
            <BackIcon className="custom-icon" onClick={() => navigate(-1)} />
          </div>
          <div className="inner-head  d-sm-flex justify-content-between align-items-center">
            <h2 className="inner-page-heading text-start mb-sm-0 mb-3">
              {t('front.patient_chart_page_heading_text')}
            </h2>
            <Button
              type="button"
              className="inner-header-button download-btn"
              onClick={handleClick}>
              {t('front.organization_patient_chart_pdf_download')}
            </Button>
          </div>
        </div>

        <Card className="card-with-box-shadow p-sm-3 ">
          <GeneralDetails
            t={t}
            user_id={user_id}
            setModalImgUrl={setModalImgUrl}
            setModalShow={setModalShow}
          />
          <Notes
            t={t}
            user_id={user_id}
            setModalImgUrl={setModalImgUrl}
            setModalShow={setModalShow}
          />
          <OrderList t={t} user_id={user_id} />
          <Modal
            show={modalShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-z-index">
            <Modal.Body className="text-center">
              <div className="close-popup">
                <FontAwesomeIcon
                  icon={faClose}
                  className="custom-icon"
                  onClick={() => setModalShow(false)}
                />
              </div>
              <img className="text-center" src={modalImgUrl} alt="profile_img" />
            </Modal.Body>
          </Modal>
        </Card>
      </Container>
    </>
  );
};
PatientChartPage.propTypes = {
  t: PropTypes.func,
};
export default PatientChartPage;
