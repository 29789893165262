import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Container, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from 'react-router-dom';
import { Formik } from 'formik';

import { ReactComponent as UserAccountIcon } from 'assets/images/user-account.svg';
import { ReactComponent as LockIcon } from 'assets/images/lock.svg';
import { ReactComponent as CreditCardIcon } from 'assets/images/credit-card.svg';
import { TNButton, TNInput } from 'common';
import { useDeactivateUserStatus } from 'hooks';
import { logoutSuccess } from 'store';
import validationSchema from './StatusUpdateValidations';
import { setAuthToken, setClinicId, setUserType } from 'libs/HttpClient';
import { loggedUser } from 'store';
import { SETTINGS_EDIT_PROFILE, LOGIN } from 'config';

const SideBarTabs = ({ t }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(loggedUser);

  /**
   * This will call on Submit Button, and update User's Status to inactive and user will be logged out
   */
  const { mutate: doDeactivateStatus, isLoading: isLoading } = useDeactivateUserStatus(
    (response) => {
      toast.success(response.message);
      setClinicId('');
      setUserType('');
      setAuthToken('');
      window.localStorage.removeItem('link');
      window.socket.emit('user_disconnect', { user_id: user.user_id });
      dispatch(logoutSuccess());
      navigation(LOGIN);
    }
  );

  /**
   * This function will call on Activate and deactivate account button, on confirm then user will logged out,
   * data will get removed from redux store as well.
   */
  const handleStatus = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-alert-box">
            <div className="confirm-alert-heading">
              <span>{t('front.settings_status_update_confirm_message')}</span>
            </div>
            <Formik
              initialValues={{ reason: '' }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                let reason = values.reason;
                doDeactivateStatus({ reason, user_id: user.user_id });
                onClose();
              }}>
              {({ handleSubmit, values, touched, errors, handleChange, handleBlur }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <TNInput.TextArea
                      errors={errors}
                      touched={touched}
                      placeholder="Mansion reason"
                      className={'spacing-between '}
                      name="reason"
                      row={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.reason}
                    />
                    <div className="confirm-alert-operations">
                      <button className="confirm-button" type="submit">
                        {t('front.settings_status_update_confirm_yes_button_text')}
                      </button>
                      <button className="cancel-button" onClick={onClose}>
                        {t('front.settings_status_update_confirm_no_button_text')}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        );
      },
    });
  };
  return (
    <Container fluid>
      <Row>
        <Col xl={12} md={12} sm={12} className="column-responsive-padding text-center side-custom">
          <Nav variant="pills" className="flex-column">
            <Nav.Item className="inner-section-content spacing-between">
              <NavLink className="nav-link" to={SETTINGS_EDIT_PROFILE}>
                <div className="sidebar-tabs-link inner-page-header-section individual">
                  <div className="inner-section-content">
                    <div className="inner-back-icon setting-icon profile-icon">
                      <UserAccountIcon />
                    </div>
                    <div className="inner-head page-heading grid-order">
                      <span>{t('front.organization_settings_sidebar_tabs_edit_profile')} </span>
                      <p className="inner-sub-heading ">
                        {t('front.organization_sidebar_my_account_edit_profile_text')}
                      </p>
                    </div>
                    <div className="next-icon grid-row-col">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                </div>
              </NavLink>
            </Nav.Item>
            <Nav.Item className="inner-section-content spacing-between">
              <NavLink to="/settings/change-password" className="nav-link">
                <div className="sidebar-tabs-link inner-page-header-section individual">
                  <div className="inner-section-content">
                    <div className="inner-back-icon setting-icon profile-icon">
                      <LockIcon />
                    </div>
                    <div className="inner-head page-heading grid-order">
                      <span>{t('front.organization_settings_sidebar_tabs_change_password')}</span>
                      <p className="inner-sub-heading ">
                        {t('front.organization_sidebar_my_account_change_password_text')}
                      </p>
                    </div>
                    <div className="next-icon grid-row-col">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                </div>
              </NavLink>
            </Nav.Item>
            <Nav.Item className="inner-section-content spacing-between">
              <NavLink to="/settings/payment-info" className="nav-link">
                <div className="sidebar-tabs-link inner-page-header-section individual">
                  <div className="inner-section-content">
                    <div className="inner-back-icon setting-icon profile-icon">
                      <CreditCardIcon />
                    </div>
                    <div className="inner-head page-heading grid-order">
                      <span>{t('front.organization_settings_sidebar_tabs_payment_info')}</span>
                      <p className="inner-sub-heading ">
                        {t('front.organization_sidebar_my_account_payment_info_text')}
                      </p>
                    </div>
                    <div className="next-icon grid-row-col">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                </div>
              </NavLink>
            </Nav.Item>
          </Nav>
          <TNButton
            loading={isLoading}
            onClick={handleStatus}
            className="inner-button deactivate-button "
            type="submit">
            {t('front.settings_deactivate_status_button_text')}
          </TNButton>
        </Col>
      </Row>
    </Container>
  );
};
SideBarTabs.propTypes = {
  t: PropTypes.func,
};
export default SideBarTabs;
