import React from 'react';

/**
 * Routes, and pages for authentications
 */

import CommonAuthShimmer from './CommonAuthShimmer';
const LoginPage = React.lazy(() => import('./Login/LoginPage'));
const SignUpPage = React.lazy(() => import('./Signup/SignUpPage'));
const OtpVerificationSignUpPage = React.lazy(() =>
  import('./OtpVerificationSignUp/OtpVerificationSignUpPage')
);
const ForgotPasswordPage = React.lazy(() => import('./ForgetPassword/ForgotPasswordPage'));
const OtpVerificationPage = React.lazy(() => import('./OtpVerification/OtpVerificationPage'));
const ResetPasswordPage = React.lazy(() => import('./ResetPassword/ResetPasswordPage'));
const SetPasswordPage = React.lazy(() => import('./SetPassword/SetPasswordPage'));
const StaffGeneralDetailsPage = React.lazy(() => import('./Staff/StaffGeneralDetailsPage'));
const ClinicPage = React.lazy(() => import('./Clinic/ClinicPage'));

export {
  LoginPage,
  ForgotPasswordPage,
  OtpVerificationPage,
  OtpVerificationSignUpPage,
  ResetPasswordPage,
  SignUpPage,
  SetPasswordPage,
  StaffGeneralDetailsPage,
  ClinicPage,
  CommonAuthShimmer,
};
