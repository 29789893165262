import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { DrVisitFeeSettingsService } from '../api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Cmg data
 */
const useGetAllDrVisitFee = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-doctor-fee`, params],
    () => DrVisitFeeSettingsService.getAllDrVisitFee(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useGetAllOrgState = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-org-state`, params],
    () => DrVisitFeeSettingsService.getAllOrgState(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useGetAllSelectedState = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-org-selected-state`, params],
    () => DrVisitFeeSettingsService.getAllOrgSelectedState(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useGetAllDrVisitType = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-dr-visit-type`, params],
    () => DrVisitFeeSettingsService.getAllDrVisitType(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useGetDrVisitType = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-dr-visit-name`, params],
    () => DrVisitFeeSettingsService.getDrVisitType(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useUpdateDrVisitFee = (onSuccess, onError = onDefaultError) => {
  return useMutation(DrVisitFeeSettingsService.updateDrVisitFee, {
    onSuccess,
    onError,
  });
};

const useAddDrVisitFee = (onSuccess, onError = onDefaultError) => {
  return useMutation(DrVisitFeeSettingsService.addDrVisitFee, {
    onSuccess,
    onError,
  });
};

const useViewDrVisitFee = (org_dr_visit_fee_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['doctor-fee-view', org_dr_visit_fee_id],
    () => DrVisitFeeSettingsService.viewDrVisitFee({ org_dr_visit_fee_id }),
    {
      onSuccess,
      onError,
    }
  );
};

const useViewPreFieldData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['pre-field-data', params],

    () => DrVisitFeeSettingsService.viewPreFieldData(params),
    {
      onSuccess,
      onError,
    }
  );
};

const useDeleteDrVisitFee = (onSuccess, onError = onDefaultError) => {
  return useMutation(DrVisitFeeSettingsService.deleteDrVisitFee, {
    onSuccess,
    onError,
  });
};

export {
  useGetAllDrVisitFee,
  useUpdateDrVisitFee,
  useAddDrVisitFee,
  useViewDrVisitFee,
  useDeleteDrVisitFee,
  useGetAllOrgState,
  useGetAllDrVisitType,
  useGetAllSelectedState,
  useViewPreFieldData,
  useGetDrVisitType,
};
