import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import {
  ForgotPasswordPage,
  LoginPage,
  OtpVerificationPage,
  OtpVerificationSignUpPage,
  ResetPasswordPage,
  SetPasswordPage,
  SignUpPage,
  StaffGeneralDetailsPage,
  ClinicPage,
} from 'pages/Auth';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { useGetSettingData } from 'hooks';
import { PageNotFound } from 'common';
import { addSetting, settingData } from 'store';
import CmsRoutes from 'pages/CMS/CMSRoutes';
import AuthRoute from './AuthRoute';
import SignUpRoute from 'pages/SignupSteps/SignUpRoute';
import OrderRoutes from 'pages/Order/OrderRoutes';
import AllOrderRoutes from 'pages/Order/AllOrderRoutes';
import SettingsRoutes from 'pages/SettingsPages';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import ChatRoutes from 'pages/Chat/ChatRoutes';
import PatientChartPage from 'pages/PatientChart/PatientChartPage';
// import Print from 'pages/PatientChart/print';
import BookingRoutes from 'pages/VideoCall/VideoCallRoute';
// import { LoadingSpinner } from '../common';
import { useGetCMSData } from '../hooks';
import {
  ABOUT_PAGE_ID,
  DASHBOARD,
  FORGOT_PASSWORD,
  HOW_IT_WORKS_PAGE_ID,
  LOGIN,
  OTP_VERIFY,
  PRIVACY_PAGE_ID,
  RESET_PASSWORD,
  SETTINGS,
  SIGNUP_OTP_VERIFY,
  SUBSCRIPTION,
  TERMS_PAGE_ID,
} from 'config';
import {
  addUpdateContactUsData,
  addUpdateHowItData,
  addUpdatePrivacyData,
  addUpdateTermsData,
  getIsContactUs,
  getIsHowIt,
  getIsPrivacy,
  getIsTerms,
} from 'store';
import { CommonAuthShimmer } from 'pages';
import { routes } from 'config';
import { CLINIC } from 'config';

//  home page route with lazy
const HomePage = React.lazy(() => import('pages/Home/HomePage'));

const SubscriptionPage = React.lazy(() => import('pages/Subscription/SubscriptionPage'));

import {
  Footer,
  AboutUsPage,
  GetInTouchPage,
  Header,
  HomePageNavbar,
  HowItWorks,
  OurTeam,
  Pricing,
} from '../../src/pages/Home/Components';

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();
  /**
   * !This API will call when page set. When response come we are Set General Setting Data
   */
  const { isLoading } = useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_step_image: general_data?.home_page_general_step_image,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        home_page_general_copyright_text: general_data.home_page_general_copyright_text,
        home_page_general_doctor_visit_fees: general_data.home_page_general_doctor_visit_fees,
        home_page_general_header_logo_link: general_data.home_page_general_header_logo_link,
        setting_get: true,
      };
      dispatch(addSetting(dataStore));
    }
  });
  useGetCMSData({ cms_id: PRIVACY_PAGE_ID, enabled: useSelector(getIsPrivacy) }, ({ data }) => {
    dispatch(
      addUpdatePrivacyData({
        cms_data: data,
        slug: data.slug,
      })
    );
  });
  useGetCMSData({ cms_id: TERMS_PAGE_ID, enabled: useSelector(getIsTerms) }, ({ data }) => {
    dispatch(
      addUpdateTermsData({
        cms_data: data,
        slug: data.slug,
      })
    );
  });
  useGetCMSData({ cms_id: ABOUT_PAGE_ID, enabled: useSelector(getIsContactUs) }, ({ data }) => {
    dispatch(
      addUpdateContactUsData({
        cms_data: data,
        slug: data.slug,
      })
    );
  });
  useGetCMSData({ cms_id: HOW_IT_WORKS_PAGE_ID, enabled: useSelector(getIsHowIt) }, ({ data }) => {
    dispatch(
      addUpdateHowItData({
        cms_data: data,
        slug: data.slug,
      })
    );
  });
  /**
   * Getting data from Redux store
   */
  const getSettingData = useSelector(settingData);

  return !isLoading ? (
    <>
      <Helmet>
        <title>Telepath</title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title} />
        <meta name="title" content={getSettingData.home_page_general_seo_title} />
        <meta name="description" content={getSettingData.home_page_general_seo_description} />
        <meta name="og:description" content={getSettingData.home_page_general_seo_description} />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo}`} />
      </Helmet>
      <Routes>
        <Route path="/" element={<HomePage t={t} />} />
        <Route path="/*" element={<PageNotFound t={t} />} from="front" />

        {/* Public Routes for organization */}
        <Route element={<PublicRoute />}>
          {/* Cms Routes */}
          <Route path="/page/*" element={<CmsRoutes t={t} />} />
        </Route>
        {/* Auth Routes for organization */}
        <Route element={<AuthRoute />}>
          <Route
            path={LOGIN}
            element={
              <Suspense fallback={<CommonAuthShimmer t={t} type={'login'} />}>
                <LoginPage t={t} />
              </Suspense>
            }
          />
          <Route
            path={routes.SIGNUP}
            element={
              <Suspense fallback={<CommonAuthShimmer t={t} type={'signup'} />}>
                <SignUpPage t={t} />
              </Suspense>
            }
          />
          <Route
            path={SIGNUP_OTP_VERIFY}
            element={
              <Suspense fallback={<CommonAuthShimmer t={t} type={'otp'} />}>
                <OtpVerificationSignUpPage t={t} />
              </Suspense>
            }
          />
          <Route
            path={FORGOT_PASSWORD}
            element={
              <Suspense fallback={<CommonAuthShimmer t={t} type={'forgot'} />}>
                <ForgotPasswordPage t={t} />
              </Suspense>
            }
          />
          <Route
            path={OTP_VERIFY}
            element={
              <Suspense fallback={<CommonAuthShimmer t={t} type={'otp'} />}>
                <OtpVerificationPage t={t} />
              </Suspense>
            }
          />
          <Route
            path={RESET_PASSWORD}
            element={
              <Suspense fallback={<CommonAuthShimmer t={t} type={'reset'} />}>
                <ResetPasswordPage t={t} />
              </Suspense>
            }
          />
          <Route
            path={routes.SET_PASSWORD + ':user_id'}
            element={
              <Suspense fallback={<CommonAuthShimmer t={t} type={'set'} />}>
                <SetPasswordPage t={t} />
              </Suspense>
            }
          />
          <Route path="/staff-info/:user_id" element={<StaffGeneralDetailsPage t={t} />} />
        </Route>

        {/* Private Routes for organization */}
        <Route element={<PrivateRoute />}>
          {/* New Signup Steps Pages Start */}
          <Route path={SUBSCRIPTION} element={<SubscriptionPage t={t} />} />
          <Route path="/auth/*" element={<SignUpRoute t={t} />} />
          <Route path={CLINIC} element={<ClinicPage t={t} />} />
          <Route
            path={SETTINGS + '/*'}
            element={
              <Suspense t={t}>
                <SettingsRoutes t={t} />
              </Suspense>
            }
          />
          <Route path={DASHBOARD} element={<DashboardPage t={t} />} />

          {/* <Route path="/my-message" element={<DashboardPage t={t} />} /> */}
          <Route path={routes.ORDERS + '/*'} element={<OrderRoutes t={t} />} />
          <Route path={routes.CHAT + '/*'} element={<ChatRoutes t={t} />} />
          <Route path={routes.PATIENT_CHART + '/:user_id'} element={<PatientChartPage t={t} />} />
          <Route path="/all-orders/*" element={<AllOrderRoutes t={t} />} />

          {/* <Route path="/print/" element={<Print t={t} />} /> */}
          <Route path={routes.VIDEO_CALL + '/*'} element={<BookingRoutes t={t} />} />
        </Route>
      </Routes>
    </>
  ) : (
    <>
      <HomePageNavbar t={t} banner_data={{}} />
      <Header t={t} banner_data={{}} />
      <AboutUsPage t={t} about_us_data={{}} />
      <HowItWorks t={t} how_its_work_data={{}} />
      <OurTeam our_team_data={{}} our_team_list_data={{}} />
      <Pricing t={t} sub_scripting_plan_data={{}} />
      <GetInTouchPage t={t} get_in_touch_data={{}} />
      <Footer t={t} get_in_touch_data={{}} />
    </>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func,
};
export { PagesRoutes };
