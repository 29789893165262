import { toast } from 'react-toastify';
import AgoraRTC from 'agora-rtc-sdk-ng';
import userIcon from 'assets/images/place-holder.png';
import { t } from 'i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

let OrgProfile = '';
let PatientProfile = '';
let userId = 0;
let userType = '';

let bookings_id = 0;
const setUserId = (user_id) => {
  userId = user_id;
};
const setBookingId = (booking_id) => {
  bookings_id = booking_id;
};
const setUserType = (user_type) => {
  userType = user_type;
};
const setOrgProfileUrl = (image_url) => {
  OrgProfile = image_url ? image_url : userIcon;
};
const setPatientProfileUrl = (image_url) => {
  PatientProfile = image_url ? image_url : userIcon;
};
const localTracks = {
  audioTrack: null,
  videoTrack: null,
};
const localTrackState = {
  videoTrackEnabled: true,
  audioTrackEnabled: true,
  user_id: 1,
  user_type: 1,
};
const rtc = {
  // For the local client.
  client: null,
  uid: null,
  // For the local audio track.
  localAudioTrack: null,
  localVideoTrack: null,
};
let remoteUsers = [];

const startBasicCall = async () => {
  rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
  AgoraRTC.onCameraChanged = (info) => {
    console.log('camera changed!', info.state, info.device);
  };
  AgoraRTC.onMicrophoneChanged = async (info) => {
    console.log('microphone changed!', info.state, info.device);
    if (info.state === 'ACTIVE') {
      localTracks.audioTrack.setDevice(info.device.deviceId);
      // Switch to an existing device when the current device is unplugged.
    } else if (info.device.label === localTracks.audioTrack.getTrackLabel()) {
      const oldMicrophones = await AgoraRTC.getMicrophones();
      oldMicrophones[0] && localTracks.audioTrack.setDevice(oldMicrophones[0].deviceId);
    }
  };
  AgoraRTC.onPlaybackDeviceChanged = (info) => {
    console.log('speaker changed!', info.state, info.device);
  };
};
async function joinForm(options, bookingCallStatusChanges) {
  if (document.querySelector('#mic-btn'))
    document.querySelector('#mic-btn').setAttribute('disabled', true);
  if (document.querySelector('#video-btn'))
    document.querySelector('#video-btn').setAttribute('disabled', true);
  if (document.querySelector('#leave'))
    document.querySelector('#leave').setAttribute('disabled', true);
  try {
    await join(options, bookingCallStatusChanges);
    return 'join';
  } catch (error) {
    // toast.info(t('front.org_video_call_camera_not_found'));
    console.error(error);
  }
}
async function join(options, bookingCallStatusChanges) {
  // add event listener to play remote tracks when remote user publishs.
  rtc.client.on('user-published', handleUserPublished);
  rtc.client.on('user-unpublished', handleUserUnpublished);
  rtc.client.on('user-joined', handleUserJoined);
  rtc.client.on('user-left', handleUserLeft);
  // join a channel and create local tracks, we can use Promise.all to run them concurrently
  // localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  // localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({
  //   encoderConfig: '1080p_5',
  // });
  try {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
      encoderConfig: 'music_standard',
    });
    localTracks.audioTrack = microphoneTrack;
    if (document.querySelector('#mic-btn'))
      document.querySelector('#mic-btn').removeAttribute('disabled');
  } catch (error) {
    if (document.querySelector('#mic-btn'))
      document.querySelector('#mic-btn').setAttribute('disabled', true);
    toast.info(t('front.org_video_call_audio_not_found'));
  }
  try {
    const cameraTrack = await AgoraRTC.createCameraVideoTrack({
      encoderConfig: '1080p_5',
    });
    localTracks.videoTrack = cameraTrack;
    if (document.querySelector('#video-btn'))
      document.querySelector('#video-btn').removeAttribute('disabled');
  } catch (error) {
    toast.info(t('front.org_video_call_camera_not_found'));
    if (document.querySelector('#video-btn'))
      document.querySelector('#video-btn').setAttribute('disabled', true);
  }
  if (!localTracks.videoTrack && !localTracks.audioTrack) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-alert-box">
            <div className="confirm-alert-heading">
              <span>{t('front.org_video_call_join_not_found_alert_text')}</span>
            </div>
            <div className="confirm-alert-operations">
              <button
                className="confirm-button"
                onClick={() => {
                  options.navigation('/settings/booking-list');
                  onClose();
                }}>
                {t('front.org_video_call_join_not_found_alert_ok_button')}
              </button>
              <button className="cancel-button" onClick={onClose}>
                {t('front.org_video_call_join_not_found_alert_cancel_button')}
              </button>
            </div>
          </div>
        );
      },
    });
    return false;
  }
  console.log(localTracks, options);
  if (localTracks.videoTrack && localTracks.audioTrack)
    rtc.uid = await rtc.client.join(options.appid, options.channel, options.token || null);
  else if (!localTracks.videoTrack && localTracks.audioTrack)
    rtc.uid = await rtc.client.join(options.appid, options.channel, options.token || null);
  else if (localTracks.videoTrack && !localTracks.audioTrack)
    rtc.uid = await rtc.client.join(options.appid, options.channel, options.token || null);

  if (localTracks.videoTrack) {
    if (document.querySelector('#local-player'))
      document.querySelector('#local-player').innerHTML = '';
    // play local video track
    localTracks.videoTrack ? localTracks.videoTrack.play('local-player') : null;
  }
  if (document.querySelector('#leave'))
    document.querySelector('#leave').removeAttribute('disabled');
  if (bookingCallStatusChanges) bookingCallStatusChanges({ type: 'join', bookings_id });
  // publish local tracks to channel
  if (localTracks.videoTrack && localTracks.audioTrack)
    await rtc.client.publish(Object.values(localTracks));
  else if (!localTracks.videoTrack && localTracks.audioTrack)
    await rtc.client.publish([localTracks.audioTrack]);
  else if (localTracks.videoTrack && !localTracks.audioTrack)
    await rtc.client.publish([localTracks.videoTrack]);

  // remove remote users and player views
  remoteUsers.splice(0, remoteUsers.length);
}

async function leave(bookingCallStatusChanges = '') {
  if (localTracks['videoTrack']) {
    localTracks['videoTrack'].stop();
    localTracks['videoTrack'].close();
    localTracks['videoTrack'] = undefined;
    if (document.querySelector('#video-btn'))
      document.querySelector('#video-btn').setAttribute('disabled', true);
  }
  if (localTracks['audioTrack']) {
    localTracks['audioTrack'].stop();
    localTracks['audioTrack'].close();
    localTracks['audioTrack'] = undefined;
    if (document.querySelector('#mic-btn'))
      document.querySelector('#mic-btn').setAttribute('disabled', true);
  }
  // remove remote users and player views
  remoteUsers.splice(0, remoteUsers.length);
  // leave the channel
  // bookingDataUpdate({ type: 'change', bookings_id });
  if (rtc.client) await rtc.client.leave();
  if (document.querySelector('#subscriber')) document.querySelector('#subscriber').innerHTML = '';
  if (bookingCallStatusChanges) bookingCallStatusChanges({ type: 'change', bookings_id });

  if (document.querySelector('#leave'))
    document.querySelector('#leave').setAttribute('disabled', true);
}
async function subscribe(user, mediaType) {
  const uid = user.uid;
  // subscribe to a remote user
  await rtc.client.subscribe(user, mediaType);
  if (mediaType == 'video') {
    const player = `
      <div id="player-wrapper-${uid}">
        <div id="player-${uid}" class="player"></div>
      </div>
    `;
    if (document.querySelector('#subscriber'))
      document.querySelector('#subscriber').innerHTML = player;
    user.videoTrack.play(`player-${uid}`);
    user.audioTrack.play();
    if (!localTracks.videoTrack && document.querySelector(`player-${uid}`)) {
      document.querySelector(`player-${uid}`).innerHTML =
        '<img src="' + OrgProfile + '" class="avatar-img-center" alt="">';
    }
  }
  if (mediaType === 'audio') {
    if (document.querySelector(`player-${uid}`))
      document.querySelector(`player-${uid}`).innerHTML =
        '<img src="' + OrgProfile + '" class="avatar-img-center" alt="">';
    user.audioTrack.play();
  }
}

function handleUserUnpublished(user) {
  if (user._video_muted_ == true) {
    const id = user.uid;
    if (document.querySelector(`#player-wrapper-${id}`))
      document.querySelector(`#player-wrapper-${id}`).innerHTML =
        '<img src="' + PatientProfile + '" class="avatar-img-center" alt="">';
  }
}

function handleUserJoined(user) {
  // const id = user.uid;
  user.user_id = userId;
  user.user_type = userType;
  var checkout = remoteUsers.filter(function (val) {
    return val.user_id.toString() === userId.toString();
  });
  if (checkout.length === 0) {
    remoteUsers.push(user);
  }
}

function handleUserLeft(user) {
  const id = user.uid;
  remoteUsers = remoteUsers.filter((v) => {
    v.uid !== id;
  });
  if (
    document.querySelector(`#player-wrapper-${id}`) &&
    document.querySelector(`#player-wrapper-${id}`).innerHTML != undefined
  ) {
    toast.info(t('front.org_video_call_user_left_message'));
    document.querySelector(`#player-wrapper-${id}`).remove();
    document.querySelector('#subscriber').innerHTML =
      '<img src="' + PatientProfile + '" class="avatar-img-center" alt="">';
  }
}

function handleUserPublished(user, mediaType) {
  var checkcount = remoteUsers.filter(function (val) {
    return val.user_id.toString() == userId.toString();
  });
  if (checkcount.length <= 1) {
    subscribe(user, mediaType);
  }
}

// Toggle Mic
const toggleMic = async (obj) => {
  if (obj.target.classList.contains('microphone') && localTrackState.audioTrackEnabled) {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setEnabled(false);
    localTrackState.audioTrackEnabled = false;
  } else {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setEnabled(true);
    localTrackState.audioTrackEnabled = true;
  }
  obj.target.classList.toggle('microphone');
  obj.target.classList.toggle('microphone-slash');
};

// Toggle Video
const toggleVideo = async (obj) => {
  if (obj.target.classList.contains('video-camera') && localTrackState.videoTrackEnabled) {
    if (!localTracks.videoTrack) return;
    await localTracks.videoTrack.setEnabled(false);
    localTrackState.videoTrackEnabled = false;
    document.querySelector('#publisher .player').innerHTML =
      '<img src="' + OrgProfile + '" class="avatar-img-right" alt="">';
  } else {
    document.querySelector('#publisher .player').innerHTML = '';
    if (!localTracks.videoTrack) return;
    await localTracks.videoTrack.setEnabled(true);
    localTrackState.videoTrackEnabled = true;
  }
  obj.target.classList.toggle('video-camera');
  obj.target.classList.toggle('video-camera-slash');
};

export {
  toggleVideo,
  toggleMic,
  leave,
  joinForm,
  startBasicCall,
  setOrgProfileUrl,
  setPatientProfileUrl,
  setUserId,
  setUserType,
  setBookingId,
};
