import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import validationSchema from './GetInTouchValidations';
import { TNButton } from 'common';
import { useSendGetInTouchEmail } from 'hooks';
import SocialMediaIcons from './socialMediaIcon';

const GetInTouchPage = ({ t, get_in_touch_data }) => {
  const handleDebouncedChange = debounce(
    (e) => formik.setFieldValue(e.target.name, e.target.value),
    300
  );

  /**
   * This Block will execute on Form Submit, provides form fields and validations for that
   */
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      question: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doSendGetInTouchEmail(values);
    },
    handleChange: handleDebouncedChange,
  });

  /**
   * !This Api will call on click submit button
   */
  const { mutate: doSendGetInTouchEmail, isLoading } = useSendGetInTouchEmail((response) => {
    formik.resetForm();
    toast.success(response.message);
  });

  const getFieldClassName = (field) =>
    'input-field-group--input ' +
    (formik.touched[field] && formik.errors[field]
      ? 'input-field-error'
      : formik.touched[field] && !formik.errors[field]
      ? 'input-field-success'
      : '');

  /**
   * Helper function to render error message
   */
  const renderError = (field) =>
    formik.touched[field] && formik.errors[field] ? (
      <div className="input-error-message">{t(formik.errors[field])}</div>
    ) : null;

  return (
    <Container fluid className="get-in-touch-container" data-aos="fade-up" data-aos-offset="300">
      <div className="get-in-touch">
        <h2 className="section-heading get-in-touch-heading">
          {get_in_touch_data?.home_page_get_in_touch_header_title}
        </h2>
        <h4 className="get-in-touch-heading-sub section-title">
          {get_in_touch_data?.home_page_get_in_touch_header_sub_title}
        </h4>
        <p className="get-in-touch-para">{get_in_touch_data?.home_page_get_in_touch_header_text}</p>
      </div>
      <Row className="get-in-touch-row">
        <Col lg={5} sm={12} xs={12}>
          <div className="get-in-touch-info">
            <div className="get-in-touch-detail">
              <h4>{t('front.home_page_get_in_touch_info_email_label')}</h4>
              <p>{get_in_touch_data?.home_page_get_in_touch_header_email}</p>
            </div>
            <div className="get-in-touch-social-media">
              <h4>{t('front.home_page_get_in_touch_info_contact_label')}</h4>
              <p>{get_in_touch_data?.home_page_get_in_touch_header_contact_number}</p>
            </div>
            {get_in_touch_data.home_page_get_in_touch_header_join_our_community && (
              <div className="get-in-touch-media">
                <h4>{get_in_touch_data.home_page_get_in_touch_header_join_our_community}</h4>
                <SocialMediaIcons
                  links={{
                    instagram:
                      get_in_touch_data.home_page_get_in_touch_header_join_our_community_instagram_social_media_link,
                    twitter:
                      get_in_touch_data.home_page_get_in_touch_header_join_our_community_twitter_social_media_link,
                    linkedin:
                      get_in_touch_data.home_page_get_in_touch_header_join_our_community_linkdin_social_media_link,
                    facebook:
                      get_in_touch_data.home_page_get_in_touch_header_join_our_community_facebook_social_media_link,
                  }}
                />
              </div>
            )}
            <div className="get-in-touch-button">
              <Link
                to={`${get_in_touch_data?.home_page_get_in_touch_header_button_link}`}
                className="btn get-in-touch-button-started button-rounded">
                {get_in_touch_data?.home_page_get_in_touch_header_button_text}
              </Link>
            </div>
          </div>
        </Col>
        <Col lg={7} sm={12} xs={12}>
          <div className="get-in-touch-form">
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="input-field-group">
                <Form.Label className="input-field-group--label">
                  {t('front.home_page_get_in_touch_form_name_label')}
                </Form.Label>
                <Form.Control
                  name="name"
                  placeholder={t('front.home_page_get_in_touch_form_name_placeholder')}
                  type="text"
                  className={getFieldClassName('name')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
              </Form.Group>
              <div className="form-field-error-text">{renderError('name')}</div>

              <Form.Group className="input-field-group">
                <Form.Label className="input-field-group--label">
                  {t('front.home_page_get_in_touch_form_email_label')}
                </Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  placeholder={t('front.home_page_get_in_touch_form_email_placeholder')}
                  className={getFieldClassName('email')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </Form.Group>
              <div className="form-field-error-text">{renderError('email')}</div>

              <Form.Group className="input-field-group">
                <Form.Label className="input-field-group--label">
                  {t('front.home_page_get_in_touch_form_question_label')}
                </Form.Label>
                <Form.Control
                  name="question"
                  placeholder={t('front.home_page_get_in_touch_form_question_placeholder')}
                  type="text"
                  as="textarea"
                  className={getFieldClassName('question')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.question}
                />
              </Form.Group>
              <div className="form-field-error-text">{renderError('question')}</div>

              <TNButton
                loading={isLoading}
                className="btn send-btn button-rounded w-100 mt-3"
                type="submit">
                {t('front.home_page_get_in_touch_form_button_send')}
              </TNButton>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

GetInTouchPage.propTypes = {
  t: PropTypes.func,
  get_in_touch_data: PropTypes.object,
};

export { GetInTouchPage };
