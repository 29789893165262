import * as Yup from 'yup';
import isValidDomain from 'is-valid-domain';
function isValidHttpUrl(string) {
  try {
    const newUrl = new URL(string);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}
Yup.addMethod(Yup.string, 'contactCheck', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    // const phoneRegExp = /^\d{7,13}?$/;
    const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (value) {
      return (
        (phoneRegExp.test(value) && value.length == 14) ||
        (/^\d+$/.test(value) && value.length === 10)
      );
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'number_check', function (errorMessage) {
  return this.test(`test-number-check`, errorMessage, function (value) {
    const phoneRegExp = /^\d{1,15}?$/;
    if (value && value.length > 0) {
      return phoneRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'otpLength', function (errorMessage) {
  return this.test(`otp-length`, errorMessage, function (value) {
    const { path, createError } = this;
    return (value && value.length === 6) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(Yup.string, 'priceCheck', function (errorMessage) {
  return this.test(`test-price`, errorMessage, function (value) {
    if (value && value.length > 0) {
      return parseFloat(value) <= 100000;
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'refundAmountCheck', function (errorMessage) {
  return this.test(`test-refund_amount_check`, errorMessage, function (value) {
    const { transaction_id } = this.parent;
    if (transaction_id === undefined || transaction_id === 0) {
      return true;
    }
    const amount_check = transaction_id.split('-')[2];
    return parseFloat(amount_check) >= parseFloat(value);
  });
});
Yup.addMethod(Yup.mixed, 'pdf_file_type', function (errorMessage) {
  return this.test(`test-pdf_file_type`, errorMessage, function (value) {
    console.log('value', value);
    if (value == undefined || typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
    if (value !== undefined) {
      return value && isValid.includes(value.type);
    }
  });
});

Yup.addMethod(Yup.mixed, 'file_type', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    if (value !== undefined) {
      return value && isValid.includes(value.type);
    }
  });
});

Yup.addMethod(Yup.string, 'npiCheck', function (errorMessage) {
  return this.test(`test-npi`, errorMessage, function (value) {
    var npi = value;
    if (value) {
      var tmp;
      var sum;
      var i;
      var j;
      i = npi.length;
      if (i == 15 && npi.indexOf('80840', 0, 5) == 0) sum = 0;
      else if (i == 10) sum = 24;
      else return false;
      j = 0;
      while (i != 0) {
        tmp = npi.charCodeAt(i - 1) - '0'.charCodeAt(0);
        if (j++ % 2 != 0) {
          if ((tmp <<= 1) > 9) {
            tmp -= 10;
            tmp++;
          }
        }
        sum += tmp;
        i--;
      }
      if (sum % 10 == 0) return true;
      else return false;
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'maxlength', function (errorMessage) {
  return this.test(`text-maxlength`, errorMessage, function (value) {
    if (value > 999 || value < 0) {
      return false;
    } else {
      return true;
    }
  });
});
Yup.addMethod(Yup.string, 'priceValidation', function (errorMessage) {
  return this.test(`test-priceValidation`, errorMessage, function (value) {
    const priceValidationRegExp = /^\d{1,3}(\.\d{1,8})?$/;
    if (value && value.length > 0) {
      return priceValidationRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'card_num', function (errorMessage) {
  return this.test(`test-card_num`, errorMessage, function (value) {
    const cardNumberRegExp =
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
    if (value && value.length > 0) {
      return cardNumberRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'expiry_month', function (errorMessage) {
  return this.test(`test-expiry_month`, errorMessage, function (value) {
    const expiryDateRegExp = /^(0[1-9]|1[0-2])$/;
    if (value && value.length > 0) {
      return expiryDateRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'expiry_year', function (errorMessage) {
  return this.test(`test-expiry_year`, errorMessage, function (value) {
    const expiryDateRegExp = /^(20[2-9]{2})$/;
    if (value && value.length > 0) {
      return expiryDateRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a.option_value) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});
Yup.addMethod(Yup.string, 'marginCheck', function (errorMessage) {
  return this.test(`test-margin`, errorMessage, function (value) {
    if (value && value.length > 0) {
      return parseFloat(value) <= 1000;
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'icon_file', function (errorMessage) {
  return this.test(`test-icon_file`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/svg+xml', 'image/jpg', 'image/jpeg', 'image/png'];
    if (value !== undefined) {
      return value && isValid.includes(value.type);
    }
  });
});
Yup.addMethod(Yup.mixed, 'icon_type', function (errorMessage) {
  return this.test(`test-icon_type`, errorMessage, function (value) {
    console.log(value, 'value');
    if (typeof value === 'string') {
      return true;
    }
    if (!value) {
      return true;
    } else {
      const isValid = ['image/png', 'image/svg+xml', 'image/vnd.microsoft.icon', 'image/x-icon'];
      if (value !== undefined) {
        return value && isValid.includes(value.type);
      }
    }
  });
});

Yup.addMethod(Yup.array, 'optionRequired', function (message, mapper = (a) => a.is_new == 2) {
  return this.test('optionRequired', message, function (list) {
    return new Set(list.filter(mapper)).size !== 0;
  });
});

Yup.addMethod(Yup.string, 'link', function (errorMessage) {
  return this.test('full-link', errorMessage, (value) => {
    // Check if the string contains a (.)
    const regex = /^(?!.*(?:http|www|\..*))[A-Za-z?=_][^.]*$|^$/;
    if (value && value.includes('.')) {
      // If it contains a ('.'), enforce that it should be a full link
      return value.startsWith('http://') || value.startsWith('https://');
    } else {
      // If there's no period, validation passes
      return regex.test(value);
    }
  });
});
Yup.addMethod(Yup.string, 'domain', function (errorMessage) {
  return this.test(`test-domain`, errorMessage, function (value) {
    if (value && value.length > 0) {
      return isValidDomain(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'analyticsKey', function (errorMessage) {
  return this.test(`test-analyticsKey`, errorMessage, function (value) {
    // const phoneRegExp = /^\d{7,13}?$/;
    const analyticsKeyRegExp = /^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/;
    if (value && value.length > 0) {
      return analyticsKeyRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'urlValid', function (errorMessage) {
  return this.test(`text-check-url`, errorMessage, function (value) {
    if (value) {
      try {
        const url = new URL(value);
        console.log(url);
        if (url.hostname) {
          return isValidDomain(url.hostname, { subdomain: true });
        }
        return false;
      } catch (_) {
        if (value.charAt(0) === '/') {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
  });
});
Yup.addMethod(Yup.string, 'urlOnlyValid', function (errorMessage) {
  return this.test(`text-check-only-url`, errorMessage, function (value) {
    if (value) {
      try {
        return isValidHttpUrl(value);
      } catch (_) {
        if (value.charAt(0) === '/') {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
  });
});
Yup.addMethod(Yup.string, 'ourLink', function (errorMessage) {
  return this.test(`text-ourLink`, errorMessage, function (value) {
    if (value) {
      try {
        const url = new URL(value);
        if (url.hostname) {
          return !isValidDomain(url.hostname, { subdomain: true });
        }
        return true;
      } catch (_) {
        console.log(value);
        if (value.charAt(0) === '/') {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
  });
});
export default Yup;
