import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { OrganizationService } from 'api';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useHowItsWorkFill = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.howItsWorkFill, {
    onSuccess,
    onError,
  });
};
const useUpdateGoogleAnalyticsSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.updateGoogleAnalyticsSettings, {
    onSuccess,
    onError,
  });
};
const useUpdateFacebookPixelSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.updateFacebookPixelSettings, {
    onSuccess,
    onError,
  });
};
const useUpdatePatientRegisterSettings = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.updatePatientRegisterSettings, {
    onSuccess,
    onError,
  });
};
const useStoreOrganizationIntakeQuestionData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.storeOrganizationIntakeQuestionData, {
    onSuccess,
    onError,
  });
};
const useStoreOrganizationDeletedIntakeQuestions = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.storeOrganizationDeletedIntakeQuestionData, {
    onSuccess,
    onError,
  });
};
const useDeleteOrganizationIntakeQuestionData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.deleteOrganizationIntakeQuestionData, {
    onSuccess,
    onError,
  });
};
const useGetSubscriptionData = (onSuccess, onError = onDefaultError) => {
  return useQuery(`subscription-view-data`, OrganizationService.getSubscriptionData, {
    onSuccess,
    onError,
  });
};
const useGetOrganizationBrandColorData = (onSuccess, onError = onDefaultError) => {
  return useQuery(`brand-color-view-data`, OrganizationService.getOrganizationBrandColorData, {
    onSuccess,
    onError,
  });
};
const useGetOrganizationIntakeQuestionData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`intake-question-data`, params],
    () => OrganizationService.getOrganizationIntakeQuestionData(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useStoreOrganizationBrandColorData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.storeOrganizationBrandColorData, {
    onSuccess,
    onError,
  });
};
const useDeleteOrganizationFileData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.deleteOrganizationFileData, {
    onSuccess,
    onError,
  });
};
const useGetStates = (onSuccess, onError = onDefaultError) => {
  return useQuery('states-view', OrganizationService.viewStates, {
    onSuccess,
    onError,
  });
};
const useGetSpecialties = (onSuccess, onError = onDefaultError) => {
  return useQuery('specialties', OrganizationService.viewSpecialties, {
    onSuccess,
    onError,
  });
};

const useGetOrganizationPracticeData = (onSuccess, onError = onDefaultError) => {
  return useQuery(`practice-view-data`, OrganizationService.getOrganizationPracticeData, {
    onSuccess,
    onError,
  });
};
const useGetFormularyData = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-formulary', OrganizationService.getFormularyData, {
    onSuccess,
    onError,
  });
};
const useStoreFormularyData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.storeFormularyData, {
    onSuccess,
    onError,
  });
};

const useStoreFormularyTagData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.storeFormularyTagData, {
    onSuccess,
    onError,
  });
};
const useGetUserFormularyData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-user-formulary', { params }],
    () => OrganizationService.getUserFormularyData(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useDeleteFormularyData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.deleteFormularyData, {
    onSuccess,
    onError,
  });
};
const useDeleteTagFormularyData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.deleteTagFormularyData, {
    onSuccess, // Success callback
    onError, // Error callback
  });
};

const useGetBusinessQuestionsData = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-business-questions', OrganizationService.getBusinessQuestionsData, {
    onSuccess,
    onError,
  });
};
const useGetBusinessAnswersData = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-business-answers', OrganizationService.getBusinessAnswersData, {
    onSuccess,
    onError,
  });
};
const useGetOrganizationFormularyQuestionData = (
  formulary_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [`formulary-question-data`, formulary_id],
    () => OrganizationService.getOrganizationFormularyQuestionData({ formulary_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetOrganizationFormularyQuestionnaireData = (
  formulary_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['formulary-questionnaire-data', { formulary_id }],
    () => OrganizationService.getOrganizationFormularyQuestionnaireData({ formulary_id }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useStoreOrganizationFormularyQuestionData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.storeOrganizationFormularyQuestionData, {
    onSuccess,
    onError,
  });
};
const useAddBusinessData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.addBusinessData, {
    onSuccess,
    onError,
  });
};
const useDeleteOrganizationFormularyQuestionData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.deleteOrganizationFormularyQuestionData, {
    onSuccess,
    onError,
  });
};
const useAllStepDoneOrganization = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.allStepDoneOrganization, {
    onSuccess,
    onError,
  });
};
const useAddCompanyInfoOne = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.addCompanyInfoOne, {
    onSuccess,
    onError,
  });
};
const useStoreOrganizationSubscriptionData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.storeOrganizationSubscriptionData, {
    onSuccess,
    onError,
  });
};
const useUpdateOrganizationSubscriptionData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.updateOrganizationSubscriptionData, {
    onSuccess,
    onError,
  });
};
const useUpdateOrganizationSubscriptionCard = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.updateOrganizationSubscriptionCard, {
    onSuccess,
    onError,
  });
};
const useCancelOrganizationSubscriptionData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.cancelOrganizationSubscriptionData, {
    onSuccess,
    onError,
  });
};
const useGetDashboardData = (onSuccess, onError = onDefaultError) => {
  return useQuery(`get-dashboard-data`, OrganizationService.getDashboardData, {
    onSuccess,
    onError,
  });
};
const userSyncOrganizationDoseSpotData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.syncOrganizationDoseSpotData, {
    onSuccess,
    onError,
  });
};
const useGetPatientData = (onSuccess, onError = onDefaultError) => {
  return useQuery(`patient-list`, OrganizationService.getPatientData, {
    onSuccess,
    onError,
  });
};

const useGetPatientMasterData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['patient-master-list', [params]],
    () => OrganizationService.getPatientMasterData(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPatientMasterDownloadData = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.getPatientMasterDownloadData, {
    onSuccess,
    onError,
  });
};
const useGetPrescriptionValiditiesMasterData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['prescription-validities-master', [params]],
    () => OrganizationService.getPrescriptionValiditiesMasterData(params),
    {
      onSuccess,
      onError,
    }
  );
};

const useGetFormularyNameList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-formulary-name-list', [params]],
    () => OrganizationService.getFormularyNameList(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetFormularyTagNameList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-tag-name-list', [params]],
    () => OrganizationService.getFormularyTagNameList(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useSelectTagNameList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['select-tag-name-list', [params]],
    () => OrganizationService.getSelectTagNameList(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useUpdatePrescriptionValidDate = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.updatePrescriptionValidDate, {
    onSuccess,
    onError,
  });
};

const useSyncFormularyQuestion = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrganizationService.syncFormularyQuestion, {
    onSuccess,
    onError,
  });
};
export {
  useHowItsWorkFill,
  useUpdateGoogleAnalyticsSettings,
  useUpdateFacebookPixelSettings,
  useUpdatePatientRegisterSettings,
  useGetSubscriptionData,
  useGetOrganizationBrandColorData,
  useStoreOrganizationBrandColorData,
  useDeleteOrganizationFileData,
  useGetStates,
  useGetSpecialties,
  useGetOrganizationPracticeData,
  useGetOrganizationIntakeQuestionData,
  useStoreOrganizationIntakeQuestionData,
  useDeleteOrganizationIntakeQuestionData,
  useGetFormularyData,
  useStoreFormularyData,
  useGetUserFormularyData,
  useDeleteFormularyData,
  useGetBusinessQuestionsData,
  useGetBusinessAnswersData,
  useAddBusinessData,
  useGetOrganizationFormularyQuestionData,
  useStoreOrganizationFormularyQuestionData,
  useDeleteOrganizationFormularyQuestionData,
  useGetOrganizationFormularyQuestionnaireData,
  useAllStepDoneOrganization,
  useAddCompanyInfoOne,
  useStoreOrganizationSubscriptionData,
  useCancelOrganizationSubscriptionData,
  useUpdateOrganizationSubscriptionData,
  useUpdateOrganizationSubscriptionCard,
  useGetDashboardData,
  useStoreOrganizationDeletedIntakeQuestions,
  userSyncOrganizationDoseSpotData,
  useGetPatientData,
  useGetPatientMasterData,
  useGetPrescriptionValiditiesMasterData,
  useGetFormularyNameList,
  useUpdatePrescriptionValidDate,
  useGetPatientMasterDownloadData,
  useSyncFormularyQuestion,
  useGetFormularyTagNameList,
  useStoreFormularyTagData,
  useSelectTagNameList,
  useDeleteTagFormularyData,
};
