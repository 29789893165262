import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SeoHeader = ({ title, description }) => {
  return (
    <Helmet
      defaultTitle={`Telepath - ${title}`}
      meta={[
        {
          name: 'title',
          content: title,
        },
        {
          property: 'ob:title',
          content: title,
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ]}>
      <title>Telepath - {title}</title>
    </Helmet>
  );
};
SeoHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SeoHeader;
