import client from 'libs/HttpClient';

class OrganizationService {
  static howItsWorkFill(request) {
    return client.put('organization/how-its-work-step', request);
  }
  static getSubscriptionData() {
    return client.get('organization/get-subscription-data');
  }
  static storeOrganizationBrandColorData(request) {
    return client.put('organization/organization-brand-color-data', request);
  }
  static getOrganizationBrandColorData() {
    return client.get('organization/get-organization-brand-color-data');
  }
  static syncOrganizationDoseSpotData() {
    return client.get('organization/sync-dosespot-data');
  }
  static getOrganizationIntakeQuestionData(params) {
    return client.get('question/get-intake', { params });
  }
  static storeOrganizationIntakeQuestionData(request) {
    return client.put('question/store-intake', request);
  }
  static storeOrganizationDeletedIntakeQuestionData(request) {
    return client.put('question/store-deleted-intake', request);
  }
  static deleteOrganizationIntakeQuestionData(params) {
    return client.delete('question/delete-intake', { params });
  }
  static deleteOrganizationFileData(params) {
    return client.delete('organization/file-delete', { params });
  }
  static viewStates() {
    return client.get('organization/state');
  }
  static viewSpecialties() {
    return client.get('organization/specialties');
  }
  static getOrganizationPracticeData() {
    return client.get('organization/get-general-info');
  }
  static getFormularyData() {
    return client.get('formulary/get');
  }
  static getUserFormularyData(params) {
    return client.get('formulary/get-formulary-data', { params });
  }
  static storeFormularyData(request) {
    return client.put('formulary/store-formulary-data', request);
  }

  static storeFormularyTagData(request) {
    return client.post('formulary/store-formulary-tag-data', request);
  }
  static deleteFormularyData(params) {
    return client.delete('formulary/delete', { params });
  }

  static deleteTagFormularyData(params) {
    return client.delete('formulary/delete-selected-formulary', { params });
  }

  static getBusinessQuestionsData() {
    return client.get('question/get-business');
  }
  static getBusinessAnswersData() {
    return client.get('question/get-business-ans');
  }
  static addBusinessData(request) {
    return client.put('question/add-business-ans', request);
  }
  static getOrganizationFormularyQuestionData(params) {
    return client.get('question/get-formulary', { params });
  }
  static getOrganizationFormularyQuestionnaireData(params) {
    return client.get('question/get-formulary-questionnaire', { params });
  }
  static storeOrganizationFormularyQuestionData(request) {
    return client.put('question/store-medication', request);
  }
  static deleteOrganizationFormularyQuestionData(params) {
    return client.delete('question/delete-medication', { params });
  }
  static allStepDoneOrganization(request) {
    return client.put('organization/organization-step-completion', request);
  }
  static addCompanyInfoOne(request) {
    return client.put('organization/general-info', request);
  }
  static storeOrganizationSubscriptionData(request) {
    return client.put('organization/store-subscription-details', request);
  }
  static updateOrganizationSubscriptionData(request) {
    return client.put('organization/update-subscription-details', request);
  }
  static updateOrganizationSubscriptionCard(request) {
    return client.put('organization/update-subscription-card-data', request);
  }
  static cancelOrganizationSubscriptionData(request) {
    return client.put('organization/cancel-subscription-details', request);
  }
  static getDashboardData() {
    return client.get('organization/get-dashboard-data');
  }
  static getPatientData() {
    return client.get('organization/patient-list');
  }
  static getPatientMasterData(params) {
    return client.get(`organization/patient-master-list`, { params });
  }
  static getPatientMasterDownloadData(params) {
    return client.get(`organization/patient-master-download`, {
      params,
    });
  }
  static getPrescriptionValiditiesMasterData(params) {
    return client.get(`organization/prescription-validities-master`, { params });
  }
  static getFormularyNameList(params) {
    return client.get(`formulary/get-formulary-name-list`, { params });
  }
  static getFormularyTagNameList(params) {
    return client.get(`formulary/get-tag-name-list`, { params });
  }

  static getSelectTagNameList(params) {
    return client.get(`formulary/select-tag-name-list`, { params });
  }
  static updatePrescriptionValidDate(request) {
    return client.put('organization/update-prescription-valid-date', request);
  }
  static updateGoogleAnalyticsSettings(request) {
    return client.put(`/organization/google-analytics-update`, request);
  }
  static updateFacebookPixelSettings(request) {
    return client.put(`/organization/facebook-pixel-update`, request);
  }
  static updatePatientRegisterSettings(request) {
    return client.put(`/organization/update-patient-register-setting`, request);
  }

  static syncFormularyQuestion(request) {
    return client.put('formulary/formulary-default-question', request);
  }
}

export { OrganizationService };
