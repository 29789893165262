import { useMutation, useQuery } from 'react-query';

import { HomeService } from 'api';
import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';
// import { settingData } from 'store';
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Hooks for Home page data
 * */
const useGetHomeData = (onSuccess, onError = onDefaultError) => {
  return useQuery(['home-view'], HomeService.getHomeData, {
    onSuccess,
    onError,
  });
};
const useSendGetInTouchEmail = (onSuccess, onError = onDefaultError) => {
  return useMutation(HomeService.sendGetInTouchEmail, {
    onSuccess,
    onError,
  });
};
const useGetSettingData = (onSuccess, onError) => {
  // const getSettingData = useSelector(settingData);
  return useQuery(
    ['setting_data'],
    () => {
      return HomeService.getSettingData();
    },
    {
      onSuccess,
      onError,
      // enabled: getSettingData ? getSettingData.setting_get : true,
    }
  );
};
export { useGetHomeData, useSendGetInTouchEmail, useGetSettingData };
