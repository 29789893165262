import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { PatientOrgServices } from 'api';

/**
 * Hook for  Organization Staff
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetPatientGeneralDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-patient-general-details', [params]],
    () => PatientOrgServices.getPatientGeneralDetails(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPatientBasicDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-patient-basic-details', [params]],
    () => PatientOrgServices.getPatientBasicDetails(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPatientInsuranceDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-patient-insurance-details', [params]],
    () => PatientOrgServices.getPatientInsuranceDetails(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPatientAllNotes = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-patient-all-notes', [params]],
    () => PatientOrgServices.getPatientAllNotes(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPatientOrders = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-patient-orders', [params]],
    () => PatientOrgServices.getPatientOrders(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useGetPatientAllOrder = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['get-orders', [params]], () => PatientOrgServices.getPatientAllOrders(params), {
    onSuccess,
    onError,
  });
};

const useGetPatientChatRoom = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-patient-chat-room', [params]],
    () => PatientOrgServices.getPatientChatRoom(params),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetPatientGeneralDetails,
  useGetPatientBasicDetails,
  useGetPatientInsuranceDetails,
  useGetPatientAllNotes,
  useGetPatientOrders,
  useGetPatientAllOrder,
  useGetPatientChatRoom,
};
