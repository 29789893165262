import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';

import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import { settingData } from 'store';
import { useMemo } from 'react';
import { setFormatDate } from 'helpers';
import { Col, Row } from 'react-bootstrap';
const useQuestionHandle = (t, isLoadingIntake, generalIntakeData, setModalImgUrl, setModalShow) => {
  const checkOrganization = useSelector(settingData);
  // Helper functions
  const processImages = (question) => {
    const files = [];

    const addFile = (fileUrl, isDocument) => {
      if (!fileUrl) return;
      const fileType = getFileType(fileUrl);
      files.push({
        doc_type: fileType,
        doc_id: isDocument,
        fileVal: fileUrl,
      });
    };

    addFile(question.document_id, true);
    addFile(question.selfie_image, false);

    return files;
  };

  const getFileType = (url) => {
    const ext = url?.split('?')[0]?.split('.').pop();
    return ['png', 'jpg', 'jpeg', 'avif', 'svg'].includes(ext) ? 1 : 2;
  };

  const processOptions = (options) => {
    return options
      ?.filter((opt) => opt.is_new === 2 && opt.user_question_ans_id !== 0)
      .map((opt) => opt.option_value);
  };
  const renderImageOrFile = (fileUrl) => {
    if (!fileUrl) return <span>{t('front.org_patient_not_added_answer')}</span>;
    const fileType = getFileType(fileUrl);
    return fileType === 1 ? renderImage(fileUrl) : renderFileLink(fileUrl);
  };

  const renderImage = (src) => (
    <div className="answer-img">
      <LazyLoadImage
        alt="Answer Image"
        src={src || checkOrganization.header_logo}
        placeholderSrc={checkOrganization.header_logo}
        className="img-fluid sigCanvas form-control"
        style={{ width: '100px' }}
        onClick={() => {
          setModalImgUrl(src || checkOrganization.header_logo);
          setModalShow(true);
        }}
      />
    </div>
  );

  const renderFileLink = (fileUrl) => (
    <div className="align-self-center py-2 mt-3">
      <a href={fileUrl} download className="download-link sub-text">
        <DownloadIcon className="heading-text download-icon me-2" />
        {t('front.orders_intake_response_download_file')}
      </a>
    </div>
  );
  // Process general intake response data
  const generalIntakeResponse = useMemo(() => {
    if (isLoadingIntake || !generalIntakeData) return [];

    return generalIntakeData.data
      .map((question) => {
        // Process document and selfie images
        if (question.document_id || question.selfie_image) {
          return processImages(question);
        }

        // Process user answers
        if (question.user_question_ans_id && question.user_question_ans_id !== 0) {
          return {
            label: question.label,
            question_type: question.question_type,
            ans_value: question.ans_value,
            ans_value_state: question.ans_value_state,
            option_value: processOptions(question.user_question_ans_option),
          };
        }
        return null;
      })
      .filter(Boolean);
  }, [isLoadingIntake, generalIntakeData]);

  const RenderAnswerContent = ({ question }) => {
    const { question_type, ans_value, ans_value_state, option_value } = question;
    if (!ans_value && ![3, 4, 5].includes(question_type))
      return <p className="sub-text question-answer">{t('front.org_patient_not_added_answer')}</p>;
    switch (question_type) {
      case 1:
      case 2:
        return ans_value ? <p className="sub-text question-answer">{ans_value}</p> : null;
      case 8:
        return ans_value_state ? (
          <p className="sub-text question-answer">{ans_value_state}</p>
        ) : null;
      case 3:
      case 4:
      case 5:
        return option_value?.length ? (
          option_value.map((option, index) => (
            <p key={index} className="sub-text question-answer">
              {question_type === 5 && index + 1}
              {question_type === 5 && '.'} {option}
            </p>
          ))
        ) : (
          <p className="sub-text question-answer">{t('front.org_patient_not_added_answer')}</p>
        );

      case 6:
      case 10:
        return renderImageOrFile(ans_value);

      case 7:
        return ans_value ? (
          <p className="sub-text question-answer">{setFormatDate(ans_value)}</p>
        ) : (
          <p className="sub-text question-answer">{t('front.org_patient_not_added_answer')}</p>
        );

      case 9:
        return (
          <p className="sub-text question-answer">
            {parseInt(ans_value) === 1
              ? t('front.question_type_agreement_check_box_checked')
              : t('front.question_type_agreement_check_box_not_checked')}
          </p>
        );
      default:
        return (
          <p className="sub-text question-answer">{t('front.org_patient_not_added_answer')}</p>
        );
    }
  };
  const RenderAnswerChartValue = ({ question }) => {
    const { question_type, ans_value, ans_value_state, option_value } = question;
    if (!ans_value && ![3, 4, 5].includes(question_type))
      return <p className="sub-text question-answer">{t('front.org_patient_not_added_answer')}</p>;
    switch (question_type) {
      case 1:
      case 2:
        return <span>{ans_value ? ans_value : t('front.org_patient_not_added_answer')}</span>;
      case 8:
        return (
          <span>{ans_value_state ? ans_value_state : t('front.org_patient_not_added_answer')}</span>
        );
      case 3:
      case 4:
      case 5:
        return option_value?.length ? (
          option_value.map((option, index) => (
            <p key={index} className="sub-text question-answer">
              {question_type === 5 && index + 1}
              {question_type === 5 && '.'} {option}
            </p>
          ))
        ) : (
          <span>{t('front.org_patient_not_added_answer')}</span>
        );
      case 6:
      case 10:
        return renderImageOrFile(ans_value);

      case 7:
        return (
          <span>
            {ans_value ? setFormatDate(ans_value) : t('front.org_patient_not_added_answer')}
          </span>
        );

      case 9:
        return (
          <span>
            {ans_value && parseInt(ans_value) == 1
              ? t('front.question_type_agreement_check_box_checked')
              : t('front.org_patient_not_added_answer')}
          </span>
        );
      default:
        return null;
    }
  };
  const RenderAnswerChartContent = ({ question }) => {
    return (
      <Row className="py-1 flex-nowrap">
        <Col sm={5} xs={5} className="question-text">
          <div
            dangerouslySetInnerHTML={{
              __html: question.label && question.label,
            }}></div>
        </Col>
        <Col sm={1} xs={1}>
          :
        </Col>
        <Col sm={6} xs={6} className="answer-text">
          <RenderAnswerChartValue question={question} />
        </Col>
      </Row>
    );
  };
  RenderAnswerContent.propTypes = {
    question: PropTypes.any,
  };
  RenderAnswerChartContent.propTypes = {
    question: PropTypes.any,
  };
  RenderAnswerChartValue.propTypes = {
    question: PropTypes.any,
  };
  return {
    generalIntakeResponse,
    RenderAnswerContent,
    RenderAnswerChartContent,
    renderImage,
    renderFileLink,
  };
};
export default useQuestionHandle;
