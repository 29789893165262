import client from 'libs/HttpClient';
class HomeService {
  static getHomeData() {
    return client.get('home/get-home');
  }
  static sendGetInTouchEmail(request) {
    return client.post('home/get-in-touch-send-email', request);
  }
  static getSettingData() {
    return client.get('settings/get-home-general');
  }
}
export { HomeService };
