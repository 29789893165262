import React from 'react';
import { faClose, faPaperclip, faXmark } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { useRef } from 'react';
import { Form, Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import validationSchema from '../PatientChartValidation';
import pdfIcon from 'assets/images/pdf.png';

import { useDeleteOrderNote, useGetAllNotes, useStoreOrderNotePatientChart } from 'hooks';
import {
  decryptValues,
  fileToDataUri,
  imagePreviewFromik,
  s3BucketFileUpload,
  setFormatDateAndTime,
} from 'helpers';
import { settingData, showLoader, hideLoader } from 'store';

const Notes = ({ t, user_id, setModalImgUrl, setModalShow }) => {
  const checkOrganization = useSelector(settingData);
  const attachmentNameRef = useRef([]);
  let notes = {};
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      note: '',
      patient_id: decryptValues(user_id),
      file_path: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      values.patient_id = decryptValues(user_id);
      dispatch(showLoader());
      doStoreOrderNote(values);
    },
  });
  const confirmDelete = (e) => {
    let order_note_id = e.currentTarget.getAttribute('order_note_id');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-alert-box">
            <div className="confirm-alert-heading">
              <span>{t('front.order_details_order_note_delete_confirm')}</span>
            </div>
            <div className="confirm-alert-operations">
              <button
                className="confirm-button"
                onClick={() => {
                  doDeleteOrderNote({ order_note_id });
                  onClose();
                }}>
                {t('front.order_details_order_note_delete_confirm_yes_button_text')}
              </button>
              <button className="cancel-button" onClick={onClose}>
                {t('front.order_details_order_note_delete_confirm_no_button_text')}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const {
    refetch,
    isLoading: isLoadingNotes,
    data: notes_data,
  } = useGetAllNotes(
    {
      user_id: decryptValues(user_id),
    },
    (data) => {
      notes = data.data.map((val) => {
        return val;
      });
    }
  );
  if (!isLoadingNotes && notes_data) {
    notes = notes_data.data;
  }
  const { mutate: doStoreOrderNote, isLoading: isLoadingSubmit } = useStoreOrderNotePatientChart(
    async (response) => {
      if (response.data.length > 0) {
        await Promise.all(
          response.data.map(async (value) => {
            let fieldName = value.field_name;
            fieldName = value.field_name.split('.')[0];
            let cnt = parseInt(value.field_name.split('.')[1]);
            fileToDataUri(formik.values[fieldName][cnt]).then(async (dataUri) => {
              await s3BucketFileUpload(
                dataUri,
                formik.values[fieldName][cnt].type,
                value.uploadURL
              ).then(() => {
                window.scrollTo(0, 0);
                refetch();
              });
            });
          })
        );
        refetch();
        toast.success(response.message);
        dispatch(hideLoader());
        formik.resetForm({
          values: {
            note: '',
            patient_id: '',
            file_path: [],
          },
        });
        console.warn('FOrmik Values', formik.values);
        window.scrollTo(0, 0);
      } else {
        refetch();
        toast.success(response.message);
        dispatch(hideLoader());
        formik.resetForm({
          values: {
            note: '',
            patient_id: '',
            file_path: [],
          },
        });
        console.warn('FOrmik Values', formik.values);
        window.scrollTo(0, 0);
      }
    },
    async (error) => {
      toast.error(error.message);
      if (error.data.length > 0) {
        await Promise.all(
          error.data.map(async (value) => {
            fileToDataUri(formik.values[value.field_name]).then(async (dataUri) => {
              await s3BucketFileUpload(
                dataUri,
                formik.values[value.field_name].type,
                value.uploadURL
              );
            });
          })
        );
        refetch();
      }
    }
  );

  const { mutate: doDeleteOrderNote } = useDeleteOrderNote((response) => {
    toast.success(response.message);
    refetch();
  });
  const onHeightChangeEvent = (height) => {
    console.log('height', height);
  };

  return (
    <Card.Body className="py-0">
      <h5 className="chart-inner-heading pt-md-1 pt-0 pb-2">
        {t('front.patient_orders_notes_text')}
      </h5>
      <Col lg={11}>
        <div className="order-note">
          <Form onSubmit={formik.handleSubmit}>
            <Row className="pb-3 g-2">
              <Col lg={10} className="position-relative">
                <TextareaAutosize
                  className={
                    'enter-note w-100 ' +
                    (formik.touched.note && formik.errors.note
                      ? 'input-field-error'
                      : formik.touched.note && !formik.errors.note
                      ? 'input-field-success'
                      : '')
                  }
                  value={formik.values.note}
                  name="note"
                  rows={5}
                  maxRows={4}
                  placeholder={t('front.order_note_placeholder')}
                  onChange={formik.handleChange}
                  onHeightChange={onHeightChangeEvent}
                />
                <div
                  className="upload-img upload-main-img paper-pin"
                  onClick={() => {
                    attachmentNameRef.current.click();
                  }}>
                  <FontAwesomeIcon className="on-hover-hand" icon={faPaperclip} />
                </div>
                <Col sm={1} xs={2} className="position-relative">
                  <input
                    name="file_path"
                    id="file_path"
                    multiple
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={(event) => {
                      let file_path = formik.values.file_path;
                      for (var i = 0; i < event.currentTarget.files.length; ++i) {
                        const extension = event.currentTarget.files[i].name.split('.');
                        if (['png', 'jpg', 'jpeg', 'pdf'].includes(extension[1])) {
                          file_path.push(event.currentTarget.files[i]);
                        }
                      }
                      formik.setFieldValue(`file_path`, file_path);
                      formik.values.file_path = file_path;
                    }}
                    ref={attachmentNameRef}
                    style={{ visibility: 'hidden', display: 'none' }}
                    type={'file'}></input>
                </Col>
                <div className="form-field-error-text">
                  {formik.touched.note && formik.errors.note ? (
                    <span className="input-error-message ps-4 pt-3">{t(formik.errors.note)}</span>
                  ) : (
                    ''
                  )}
                  {formik.touched.file_path && formik.errors.file_path ? (
                    <span className="input-error-message ps-4 pt-3">
                      {t(formik.errors.file_path)}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
              <Col lg={2} className="position-relative">
                <Button
                  loading={isLoadingSubmit}
                  className="feature-single-item enter-note-btn"
                  type="submit">
                  {t('front.order_details_note_enter_btn_text')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Row>
                  {formik.values.file_path.length > 0 &&
                    formik.values.file_path.map((item, i) => {
                      return (
                        <Col key={i} xs={6} sm={6} lg={2}>
                          <div className="preview-image-notes on-hover-hand">
                            <img src={imagePreviewFromik(item)} alt="profile_img" />
                            <FontAwesomeIcon
                              icon={faClose}
                              onClick={() => {
                                formik.values.file_path.splice(i, 1);
                                formik.setFieldValue('file_path', formik.values.file_path);
                              }}
                            />
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
      <Card>
        <Row className="order-details-note-section border-bottom-0 item-align-center">
          <div className="order-note bg-transparent order-notes-row">
            <div className="all-notes ps-3 pt-2">
              <p>{t('front.orders_all_notes_text')}</p>
              {!isLoadingNotes ? (
                notes && notes.length > 0 ? (
                  notes.map((val, i) => {
                    return (
                      <div key={i} className="single-note">
                        <div className="note-text position-relative">
                          {val.note.includes('\r\n') ? (
                            val.note.split('\r\n')?.map((d, i) => {
                              return <p key={i}>{d}</p>;
                            })
                          ) : (
                            <>
                              <p>{val.note}</p>
                              <p>
                                {val.order_notes_attachments.map((v) => {
                                  return (
                                    <LazyLoadImage
                                      className="img-fluid-notes on-hover-hand"
                                      alt={'Notes File'}
                                      key={
                                        v.file ?? checkOrganization.home_page_general_header_logo
                                      }
                                      placeholderSrc={
                                        checkOrganization.home_page_general_header_logo
                                      }
                                      src={
                                        v.type === 3
                                          ? pdfIcon
                                          : v.file
                                          ? v.file
                                          : checkOrganization.home_page_general_header_logo
                                      }
                                      onClick={() => {
                                        if (v.type === 3) {
                                          window.open(v.file);
                                        } else {
                                          setModalImgUrl(
                                            v.file
                                              ? v.file
                                              : checkOrganization.home_page_general_header_logo
                                          );
                                          setModalShow(true);
                                        }
                                      }}
                                    />
                                  );
                                })}
                              </p>
                            </>
                          )}
                          <Col className="text-center close_main_btn">
                            <FontAwesomeIcon
                              icon={faXmark}
                              onClick={confirmDelete.bind(this)}
                              order_note_id={val.order_note_id}
                            />
                          </Col>
                          {val.note.includes('\r\n') ? (
                            val.note.split('\r\n') ? (
                              <p>
                                {val.order_notes_attachments.map((v) => {
                                  return (
                                    <LazyLoadImage
                                      className="img-fluid-notes on-hover-hand"
                                      alt={'Notes File'}
                                      key={
                                        v.file ?? checkOrganization.home_page_general_header_logo
                                      }
                                      placeholderSrc={
                                        checkOrganization.home_page_general_header_logo
                                      }
                                      src={
                                        v.type === 3
                                          ? pdfIcon
                                          : v.file
                                          ? v.file
                                          : checkOrganization.home_page_general_header_logo
                                      }
                                      onClick={() => {
                                        if (v.type === 3) {
                                          window.open(v.file);
                                        } else {
                                          setModalImgUrl(
                                            v.file
                                              ? v.file
                                              : checkOrganization.home_page_general_header_logo
                                          );
                                          setModalShow(true);
                                        }
                                      }}
                                    />
                                  );
                                })}
                              </p>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}

                          <span className="date">
                            {val.created_at && setFormatDateAndTime(val.created_at_tz)}
                          </span>
                          <span className="px-2">
                            {val.order_id != '' &&
                              `( ${t('front.order_view_order_order_id_text')} -
                          ${val.order_id} )`}
                          </span>
                          {val.createdby ? (
                            <span className="px-2">
                              {`( ${t('front.order_note_view_order_created_by_text')} -
                          ${val.createdby.first_name} ${val.createdby.last_name} )`}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="page-not-found">
                    <div className="error-page-text">
                      {t('front.no_order_notes_added_for_patient_text')}
                    </div>
                  </div>
                )
              ) : (
                <div className="page-not-found">
                  <div className="error-page-text">{t('front.orders_loading_data_sub_text')}</div>
                </div>
              )}
            </div>
          </div>
        </Row>
      </Card>
    </Card.Body>
  );
};
Notes.propTypes = {
  t: PropTypes.any,
  user_id: PropTypes.any,
  setModalImgUrl: PropTypes.any,
  setModalShow: PropTypes.any,
};
export default Notes;
