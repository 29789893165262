import { useMutation, useQuery } from 'react-query';
import { WebhookService } from '../api/WebhookService';
import { toast } from 'react-toastify';

/**
 * Hook for Admin Organization Master section
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetWebhookSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['setting_webhook_all', params],
    () => WebhookService.getWebhookSettingData(params),
    {
      onSuccess,
      onError,
    }
  );
};
const useSaveWebhookSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(WebhookService.saveWebhookSettingData, {
    onSuccess,
    onError,
  });
};

export { useGetWebhookSettingData, useSaveWebhookSettingData };
