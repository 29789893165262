import client from 'libs/HttpClient';

class OrganizationHomePageSettingsServices {
  static viewGeneralData(request) {
    return client.get(`settings/get-home-organization-general`, request);
  }
  static updateGeneralSettings(request) {
    return client.post(`settings/home-organization-general`, request);
  }
  static viewButtonData(request) {
    return client.get(`settings/get-home-organization-button`, request);
  }
  static updateButtonSettings(request) {
    return client.post(`settings/home-organization-button`, request);
  }
  static viewHomeRedirectData(request) {
    return client.get(`settings/get-home-organization-home-redirect`, request);
  }
  static updateHomeRedirectSettings(request) {
    return client.post(`settings/home-organization-home-redirect`, request);
  }
  static viewHomeBannerData(request) {
    return client.get(`settings/get-home-organization-banner`, request);
  }
  static updateHomeBannerSettings(request) {
    return client.post(`settings/home-organization-banner`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewHomeWhoWeAreData(request) {
    return client.get(`settings/get-home-organization-who-we-are`, request);
  }
  static updateHomeWhoWeAreSettings(request) {
    return client.post(`settings/home-organization-who-we-are`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewHomeClientData(request) {
    return client.get(`settings/get-home-organization-client`, request);
  }
  static updateHomeClientSettings(request) {
    return client.post(`settings/home-organization-client`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewHomeGetInTouchData(request) {
    return client.get(`settings/get-home-organization-get-in-touch`, request);
  }
  static updateHomeGetInTouchSettings(request) {
    return client.post(`settings/home-organization-get-in-touch`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewHomeSeoData(request) {
    return client.get(`settings/get-home-organization-seo`, request);
  }
  static updateHomeSeoSettings(request) {
    return client.put(`settings/home-organization-seo`, request);
  }
}
export { OrganizationHomePageSettingsServices };
