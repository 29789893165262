import axios from 'axios';
import { createBrowserHistory } from 'history';
// import { toast } from 'react-toastify';

import { API_URL } from 'config';
const myHistory = createBrowserHistory({ window });
const client = axios.create({
  baseURL: `${API_URL}/front/`,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
  },
});
client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error, 'error');
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (!error.response) {
      throw new Error('Error Connection');
    }
    const url = error.config.url.split('?');
    console.log(url, error, 'url');
    if (
      url[0] &&
      !['/setting/token/store', '/front/booking/get-details'].includes(url[0]) &&
      (error.response.status === 401 || error.response.status === 414)
    ) {
      window.localStorage.clear();
      myHistory.replace('/');
      window.localStorage.isShowingError = error.response.data.message;
      window.location.reload();
    }
    return Promise.reject(error.response.data);
  }
);

export default client;

export const setAuthToken = (token) => {
  client.defaults.headers.common['Authorization'] = '';
  delete client.defaults.headers.common['Authorization'];
  if (token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const fcmToken = localStorage.fcmToken;
    if (fcmToken !== undefined)
      client.post(
        '/token/store',
        { token: fcmToken },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  }
};
export const setClinicId = (clinic_id) => {
  client.defaults.headers.common['ClinicID'] = '';
  delete client.defaults.headers.common['ClinicID'];
  if (clinic_id) {
    client.defaults.headers.common['ClinicID'] = `${clinic_id}`;
  }
};
export const setUserType = (user_type) => {
  client.defaults.headers.common['UserType'] = '';
  delete client.defaults.headers.common['UserType'];
  if (user_type) {
    client.defaults.headers.common['UserType'] = `${user_type}`;
  }
};
