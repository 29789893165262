import client from 'libs/HttpClient';
class ChatService {
  static getUserChatList(params) {
    return client.get(`chat/get-org-user-list`, { params });
  }
  static getUserChatListSearch(params) {
    return client.get(`chat/get-org-user-list-search`, { params });
  }
  static getUnreadMessageCount() {
    return client.get('chat/get-org-chat-count');
  }
  static getChatList(params) {
    return client.get(`chat/get-org-chat-list`, { params });
  }
  static storeChatData(request) {
    return client.post(`chat/store`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
}
export { ChatService };
