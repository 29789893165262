import client from 'libs/HttpClient';

class PatientOrgServices {
  static getPatientGeneralDetails(params) {
    return client.get(`organization/patient/get-patient-general-details`, { params });
  }

  static getPatientBasicDetails(params) {
    return client.get(`organization/patient/get-patient-basic-details`, { params });
  }
  static getPatientInsuranceDetails(params) {
    return client.get(`organization/patient/get-patient-insurance-details`, { params });
  }
  static getPatientAllNotes(params) {
    return client.get(`organization/patient/get-patient-all-notes`, { params });
  }
  static getPatientOrders(params) {
    return client.get(`organization/patient/get-patient-orders`, { params });
  }
  // get all order data without pagination
  static getPatientAllOrders(params) {
    return client.get(`organization/patient/get-orders`, { params });
  }
  static getPatientChatRoom(params) {
    return client.get(`organization/patient/get-patient-chat-room`, { params });
  }
}

export { PatientOrgServices };
