import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HeroSection = ({ banner_data }) => {
  return (
    <>
      <Container fluid className="hero-container">
        <Row>
          <Col className="hero-container--col" lg={5} md={6} sm={8} xs={10}>
            <h1 className="hero-heading">{banner_data?.home_page_banner_header_title}</h1>
            <p className="hero-para">{banner_data?.home_page_banner_header_text}</p>
            <div className="hero-button-container">
              <Link
                className="btn get-started-btn button-rounded"
                to={`${banner_data?.home_page_banner_button_link}`}>
                {banner_data?.home_page_banner_button_text}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
HeroSection.propTypes = {
  banner_data: PropTypes.object,
};
export { HeroSection };
