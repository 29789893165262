import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'config';
import SettingShimmers from './SettingPageShimmer';
import ListCommonShimmer from './CommonListShimmer';
import LoadingShimmer from './CommonShimmer';

const SettingsPage = React.lazy(() => import('./SettingsPage'));
const EditProfilePage = React.lazy(() =>
  import('./AccountInformation/EditProfilePage/EditProfilePage')
);
const EditEmailPage = React.lazy(() =>
  import('./AccountInformation/EditProfilePage/EditEmailPage')
);
const EmailOtpVerificationPage = React.lazy(() =>
  import('./AccountInformation/EditProfilePage/EmailOtpVerificationPage')
);
const ChangePasswordPage = React.lazy(() =>
  import('./AccountInformation/ChangePassword/ChangePasswordPage')
);
const SubscriptionPlanDetailPage = React.lazy(() =>
  import('./AccountInformation/SubscriptionPlanDetailPage/SubscriptionPlanDetailPage')
);

const CIPracticeDetail = React.lazy(() =>
  import('./CompanyInformation/PracticeData/CIPracticeDetail')
);
const CIBusinessDetails = React.lazy(() =>
  import('./CompanyInformation/BuseinessData/CIBusinessDetails')
);
const CIBrandColorDetails = React.lazy(() =>
  import('./CompanyInformation/BrandColor/CIBrandColorDetails')
);
const IntakeEditorSettingsPage = React.lazy(() =>
  import('./IntakeEditor/IntakeEditorSettingsPage')
);
const FormularyRoutes = React.lazy(() => import('./FormularyEditorSettings/FormularyRoutes'));

const HomePageSettingsRoutes = React.lazy(() =>
  import('./HomePageSettings/HomePageSettingsRoutes')
);
const GoogleAnalyticsSettingPage = React.lazy(() =>
  import('./CompanyInformation/GoogleAnalytics/GoogleAnalyticsSettingPage')
);
const FacebookPixelSettingPage = React.lazy(() =>
  import('./CompanyInformation/FacebookPixel/FacebookPixelSettingPage')
);
// const CMSUpdatePage = React.lazy(() => import('./HomePageSettings/CMSUpdate/CMSUpdatePage'));
const AvailabilityPage = React.lazy(() => import('./Availability/AvailabilityPage'));
const ListBookingPage = React.lazy(() => import('./Booking/BookingListPage'));
const StaffRoutes = React.lazy(() => import('./Staff/StaffRoutes'));

const PatientRoutes = React.lazy(() => import('./Patient/PatientRutes'));
const PrescriptionValiditiesPage = React.lazy(() =>
  import('./PrescriptionValidities/PrescriptionValiditiesPage')
);
const TemplateRoutes = React.lazy(() => import('./EmailTemplates/TemplateRoutes'));

const CouponRoutes = React.lazy(() => import('./CouponCode/CouponRoutes'));
const CustomDomainPage = React.lazy(() =>
  import('./CompanyInformation/CustomDomain/CustomDomainPage')
);
const CustomSmtpPage = React.lazy(() => import('./CompanyInformation/CustomSmtp/CustomSmtpPage'));
const WebhookSettingPage = React.lazy(() => import('./WebhookSetting/WebhookSetting'));
const PatientSettingPage = React.lazy(() =>
  import('./CompanyInformation/PatientSetting/PatientSettingPage')
);
const CustomAnalyticsSettingPage = React.lazy(() =>
  import('./CompanyInformation/CustomAnalytics/CustomAnalyticsSettingPage')
);
const AddCustomAnalytics = React.lazy(() =>
  import('./CompanyInformation/CustomAnalytics/AddCustomAnalutics')
);
const EditCustomAnalytics = React.lazy(() =>
  import('./CompanyInformation/CustomAnalytics/EditCustomAnalytics')
);
const ListDrVisitPage = React.lazy(() => import('./DrVisitFee/ListDrvisitFee'));
const AddDrVisitFeePage = React.lazy(() => import('./DrVisitFee/AddDrVisitFee'));
const EditDrVisitFeePage = React.lazy(() => import('./DrVisitFee/EdisDrVisitFee.js'));
const PatientOrderPage = React.lazy(() => import('./OrderEmailTemplates/TemplatePatientOrder'));
const PatientOrderAcceptPage = React.lazy(() =>
  import('./OrderEmailTemplates/TemplatePatientOrderAccept')
);
const FormularyCategoryRoutes = React.lazy(() =>
  import('./FormularyCategory/FormularyCategoryRoute')
);
const ButtonSettings = React.lazy(() => import('./HomePageSettings/ButtonSettings/ButtonSettings'));

import { CommonShimmer } from './AccountInformation';

const SettingsRoutes = ({ t }) => {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <Suspense fallback={<SettingShimmers t={t} />}>
            <SettingsPage t={t} />
          </Suspense>
        }
      />{' '}
      <Route
        exact
        path={routes.EDIT_PROFILE}
        element={
          <Suspense t={t}>
            <EditProfilePage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.EDIT_PROFILE + routes.UPDATE_EMAIL}
        element={
          <Suspense t={t} fallback={<CommonShimmer />}>
            <EditEmailPage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.EDIT_PROFILE + routes.VERIFY_EMAIL}
        element={
          <Suspense t={t} fallback={<CommonShimmer />}>
            <EmailOtpVerificationPage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.CHANGE_PASSWORD}
        element={
          <Suspense t={t} fallback={<CommonShimmer />}>
            <ChangePasswordPage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.PAYMENT_INFO}
        element={
          <Suspense t={t} fallback={<CommonShimmer />}>
            <SubscriptionPlanDetailPage t={t} />
          </Suspense>
        }
      />
      <Route exact path="/practice-detail" element={<CIPracticeDetail t={t} />} />
      <Route exact path="/business-detail" element={<CIBusinessDetails t={t} />} />
      <Route exact path={routes.BRAND_COLOR_DETAIL} element={<CIBrandColorDetails t={t} />} />
      <Route exact path="/intake-question-list" element={<IntakeEditorSettingsPage t={t} />} />
      <Route exact path={routes.FORMULARY + '/*'} element={<FormularyRoutes t={t} />} />
      <Route exact path="/availability" element={<AvailabilityPage t={t} />} />
      <Route exact path="/booking-list" element={<ListBookingPage t={t} />} />
      <Route
        exact
        path={routes.WEBSITE_SETTINGS + '/*'}
        element={<HomePageSettingsRoutes t={t} />}
      />
      <Route exact path="/buttons" element={<ButtonSettings t={t} />} />
      <Route exact path="/google-analytics" element={<GoogleAnalyticsSettingPage t={t} />} />
      <Route exact path="/facebook-pixel" element={<FacebookPixelSettingPage t={t} />} />
      <Route exact path="/custom-analytics" element={<CustomAnalyticsSettingPage t={t} />} />
      <Route exact path="/custom-analytics-add/" element={<AddCustomAnalytics t={t} />} />
      <Route
        exact
        path="/custom-analytics-edit/:organization_analytics_id"
        element={<EditCustomAnalytics t={t} />}
      />
      <Route exact path={routes.STAFF + '/*'} element={<StaffRoutes t={t} />} />
      {/* <Route exact path="/add-custom-anyalitcs" element={<AddCustomAnalytics t={t} />} /> */}
      <Route exact path={routes.PATIENT + '/*'} element={<PatientRoutes t={t} />} />
      <Route
        exact
        path="/prescription-validities-list"
        element={<PrescriptionValiditiesPage t={t} />}
      />
      <Route exact path="/patient-register" element={<PatientSettingPage t={t} />} />
      <Route exact path="/custom-domain" element={<CustomDomainPage t={t} />} />
      <Route exact path="/webhook-setting" element={<WebhookSettingPage t={t} />} />
      <Route
        exact
        path={routes.TEMPLATE + '/*'}
        element={
          <Suspense fallback={<LoadingShimmer t={t} />}>
            <TemplateRoutes t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.COUPON + '/*'}
        element={
          <Suspense fallback={<ListCommonShimmer t={t} />}>
            <CouponRoutes t={t} />
          </Suspense>
        }
      />{' '}
      <Route exact path="/custom-smtp" element={<CustomSmtpPage t={t} />} />
      <Route exact path="/doctor-visit-fee-list" element={<ListDrVisitPage t={t} />} />
      <Route exact path="/add-doctor-visit-fee" element={<AddDrVisitFeePage t={t} />} />
      <Route
        exact
        path="/edit-dr-visit-fee/:org_dr_visit_fee_id"
        element={<EditDrVisitFeePage t={t} />}
      />
      <Route exact path="/patient-order-email" element={<PatientOrderPage t={t} />} />
      <Route exact path="/patient-order-accept-email" element={<PatientOrderAcceptPage t={t} />} />
      <Route exact path="/formulary-category/*" element={<FormularyCategoryRoutes t={t} />} />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
SettingsRoutes.propTypes = {
  t: PropTypes.func,
};
export default SettingsRoutes;
