import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ChatService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Cmg data
 */
const useGetUserChatList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`user-list-view`, [params]], () => ChatService.getUserChatList(params), {
    onSuccess,
    onError,
  });
};
const useGetUserChatListSearch = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`user-list-view-search`, [params]],
    () => ChatService.getUserChatListSearch(params),
    {
      onSuccess,
      onError,
    }
  );
};

const useGetChatList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`user-chat-list-view`, [params]], () => ChatService.getChatList(params), {
    onSuccess,
    onError,
  });
};
const userStoreChatData = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.storeChatData, {
    onSuccess,
    onError,
  });
};

const userGetUnreadMessageCount = (onSuccess, onError = onDefaultError) => {
  return useQuery(['unread_count'], ChatService.getUnreadMessageCount, {
    onSuccess,
    onError,
  });
};
export {
  useGetUserChatList,
  useGetChatList,
  userStoreChatData,
  userGetUnreadMessageCount,
  useGetUserChatListSearch,
};
