/* eslint-disable react/no-unknown-property */
import { Row, Card, Container } from 'react-bootstrap';

import { ShimmerButton, ShimmerSectionHeader, ShimmerTable } from 'common/shimmer';

const ListCommonShimmer = () => {
  return (
    <>
      <Container className="order-page inner-section-container staff-list">
        <div className="d-flex justify-content-between mt-4">
          <ShimmerSectionHeader subTitle={true} />
        </div>

        <Card className="card-with-box-shadow">
          <Row className="d-flex justify-content-end p-4 gap-2">
            <ShimmerButton />
            <ShimmerButton />
          </Row>

          <ShimmerTable row={5} col={5} header={true} />
        </Card>
      </Container>
    </>
  );
};

export default ListCommonShimmer;
