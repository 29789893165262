import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { decryptValues, formatPhoneNumber } from 'helpers';
import { useGetBasicDetails, useGetGeneralDetails, useGetInsuranceDetails } from 'hooks';
import userIcon from 'assets/images/place-holder.png';
import { useQuestionHandle } from 'common';

const GeneralDetails = ({ t, user_id, setModalImgUrl, setModalShow }) => {
  let basicDetails = [];
  const { isLoading: isLoadingBasicDetails, data: basic_details } = useGetBasicDetails(
    {
      user_id: decryptValues(user_id),
    },
    (data) => {
      basicDetails = data.data;
    }
  );
  if (!isLoadingBasicDetails && basic_details) {
    basicDetails = basic_details.data;
  }
  const { isLoading: isLoadingInsuranceDetails, data: insurance_details } = useGetInsuranceDetails({
    user_id: decryptValues(user_id),
  });
  const { isLoading: isLoadingGeneralDetails, data: general_details } = useGetGeneralDetails({
    user_id: decryptValues(user_id),
  });
  const { RenderAnswerChartContent, generalIntakeResponse: basicIntakeResponse } =
    useQuestionHandle(t, isLoadingGeneralDetails, general_details, setModalImgUrl, setModalShow);
  const {
    RenderAnswerChartContent: RenderAnswerInsuranceContent,
    generalIntakeResponse: insuranceIntakeResponse,
  } = useQuestionHandle(
    t,
    isLoadingInsuranceDetails,
    insurance_details,
    setModalImgUrl,
    setModalShow
  );

  return (
    <Card.Body>
      <Row>
        <Col lg={6}>
          <h5 className="chart-inner-heading pt-md-3 pt-0 pb-2">
            {t('front.patient_chart_patient_id_text')}
          </h5>
          <Card className="p-2 patient-chart-card">
            {!isLoadingBasicDetails ? (
              basicDetails && (
                <div className="text-center align-items-center">
                  <LazyLoadImage
                    alt={'Profile Image'}
                    key={basicDetails.profile_image ? basicDetails.profile_image : userIcon}
                    placeholderSrc={userIcon}
                    className="profile-image"
                    src={basicDetails.profile_image ? basicDetails.profile_image : userIcon}
                  />
                  <p className="py-1 question-text">{`${basicDetails.first_name}  ${basicDetails.last_name}`}</p>
                </div>
              )
            ) : (
              <div className="page-not-found">
                <div className="error-page-text">{t('front.orders_loading_data_sub_text')}</div>
              </div>
            )}
          </Card>
          <h5 className="chart-inner-heading pt-md-3 pt-0 pb-2">
            {t('front.patient_chart_insurance_details_label')}
          </h5>
          <Card className="p-4 patient-chart-card">
            {!isLoadingInsuranceDetails ? (
              insuranceIntakeResponse.length > 0 ? (
                insuranceIntakeResponse.map((question, i) => {
                  return <RenderAnswerInsuranceContent question={question} key={i} />;
                })
              ) : (
                <div className="page-not-found">
                  <div className="error-page-text">
                    <p>{t('front.patient_chart__no_insurance_added_text')}</p>
                  </div>
                </div>
              )
            ) : (
              <div className="page-not-found">
                <div className="error-page-text">{t('front.orders_loading_data_sub_text')}</div>
              </div>
            )}
          </Card>
        </Col>
        <Col lg={6}>
          <h5 className="chart-inner-heading pt-md-3 pt-0 pb-2">
            {t('front.patine_chart_view_general_details_label')}
          </h5>
          <Card className="p-4 patient-chart-card">
            {!isLoadingBasicDetails && basicIntakeResponse.length > 0 && (
              <>
                <Row className="py-1 flex-nowrap">
                  <Col sm={5} xs={5} className="question-text">
                    {t('front.org_patient_email_label')}
                  </Col>
                  <Col sm={1} xs={1}>
                    :
                  </Col>
                  <Col className="answer-text" sm={6} xs={6}>
                    {basicDetails.email}
                  </Col>
                </Row>
                <Row className="py-1 flex-nowrap">
                  <Col sm={5} xs={5} className="question-text">
                    {t('front.org_patient_phone_phone_label')}
                  </Col>
                  <Col sm={1} xs={1}>
                    :
                  </Col>
                  <Col className="answer-text" sm={6} xs={6}>
                    {' '}
                    {'+'}
                    {basicDetails.phone_code} {formatPhoneNumber(basicDetails.phone)}
                  </Col>
                </Row>
                <Row className="py-1 flex-nowrap">
                  <Col sm={5} xs={5} className="question-text">
                    {t('front.org_patient_bmi_value_label')}
                  </Col>
                  <Col sm={1} xs={1}>
                    :
                  </Col>
                  <Col className="answer-text" sm={6} xs={6}>
                    {basicDetails.bmi_value
                      ? parseFloat(basicDetails.bmi_value).toFixed(2)
                      : 'No data'}
                  </Col>
                </Row>
              </>
            )}
            {!isLoadingGeneralDetails ? (
              basicIntakeResponse.length > 0 ? (
                basicIntakeResponse.map((question, i) => {
                  return <RenderAnswerChartContent question={question} key={i} />;
                })
              ) : (
                <div className="page-not-found">
                  <div className="error-page-text">{t('front.patient_details_not_found_text')}</div>
                </div>
              )
            ) : (
              <div className="page-not-found">
                <div className="error-page-text">{t('front.orders_loading_data_sub_text')}</div>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Card.Body>
  );
};
GeneralDetails.propTypes = {
  t: PropTypes.any,
  user_id: PropTypes.any,
  setModalImgUrl: PropTypes.any,
  setModalShow: PropTypes.any,
};
export default GeneralDetails;
