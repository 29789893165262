import React, { useEffect, useState } from 'react';
import ErrorPage from 'assets/images/404-page.png';
import PropTypes from 'prop-types';
import { Container, Navbar } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TNButton from './TNButton';
import { settingData } from 'store';
const PageNotFound = (props) => {
  const getSettingData = useSelector(settingData);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };
  const [navBgColor, setNavBgColor] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
  });
  const changeBackground = () => {
    if (window.scrollY >= 3) {
      setNavBgColor(true);
    } else {
      setNavBgColor(false);
    }
  };
  return (
    <>
      <div {...props} className="common-container">
        <Container fluid className="navbar-container">
          <Navbar
            collapseOnSelect
            expand="lg"
            style={{ backgroundColor: 'transparent' }}
            className={navBgColor ? 'navbar-dark-bg' : 'navbar-light-bg'}>
            <span>
              <NavLink to="/" className="brand-logo navbar-brand">
                <img
                  src={
                    navBgColor
                      ? getSettingData.home_page_general_header_sub_logo
                      : getSettingData.home_page_general_header_logo
                  }
                  className="brand-logo-img"
                  alt="Header Logo"
                />
              </NavLink>
            </span>
          </Navbar>
        </Container>
        <section className="main-section">
          <Container>
            <div className="page-not-found">
              <img src={ErrorPage} alt="404" className="not-found-image" />
              <h1 className="page-heading-center">{props.t('front.not_found_oops_text')}</h1>
              <div className="error-page-text">{props.t('front.not_found_text')}</div>
              <div className="home-button">
                <TNButton onClick={handleClick} loading={false} className="inner-button">
                  {props.t('front.back_to_home')}
                </TNButton>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

PageNotFound.propTypes = {
  from: PropTypes.string,
  t: PropTypes.func,
};

export default PageNotFound;
