import { Card, Col, Container, Row, Form } from 'react-bootstrap';
import { t } from 'i18next';
import SideBarTabs from './SidebarTabs';
import { LoadingShimmer } from 'common';

const CommonShimmer = () => {
  return (
    <>
      <Container className="profile-update-container sidebar-tabs-container inner-section-container">
        <Row className="account-information-row subscription-wrap   spacing-between">
          <Col xl={4} lg={4} md={5} sm={12} className="column-responsive-padding">
            <SideBarTabs t={t} />
          </Col>
          <Col xl={8} lg={8} md={7} sm={12} className="column-padding">
            <Card className="profile-inner-card auth-inner-container inner-card-section">
              <Form className="profile-form">
                <div className="text-center mt-5">
                  <LoadingShimmer />
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export { CommonShimmer };
