import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { getHowItCmsSlug } from 'store';
import { routes } from 'config';

/**
 * Sign up process routes, and pages
 */
const HowItWorkPage = React.lazy(() => import('./HowItWork/HowItWorkPage'));
const SubscriptionPage = React.lazy(() => import('./Subscription/SubscriptionPage'));
const CompanyInformationOnePage = React.lazy(() =>
  import('./CompanyInformationOne/CompanyInformationOnePage')
);
const CompanyInformationTwoPage = React.lazy(() =>
  import('./CompanyInformationTwo/CompanyInformationTwoPage')
);
const PracticeInformationPage = React.lazy(() =>
  import('./PracticeInformation/PracticeInformationPage')
);
const CompanyIntakeInformationPage = React.lazy(() =>
  import('./CompanyIntakeInformation/CompanyIntakeInformationPage')
);
const FormularyQuestionPage = React.lazy(() =>
  import('./FormularyQuestionPage/FormularyQuestionPage')
);
const ListQuestionaryFormularyPage = React.lazy(() =>
  import('./QuestionaryFormulary/ListQuestionaryFormularyPage')
);
const CompanyStaffInformationPage = React.lazy(() =>
  import('./CompanyStaffInformation/CompanyStaffPage')
);
const AllSetPage = React.lazy(() => import('./AllSetPage/AllSetPage'));

const FormularyPharmacy = React.lazy(() => import('./FormularyPages/FormularyPharmacy'));
const SignUpRoute = ({ t }) => {
  let how_its_work_cms_slug = useSelector(getHowItCmsSlug);

  return (
    <Routes>
      <Route
        index
        path={`/${how_its_work_cms_slug}`}
        element={
          <Suspense t={t}>
            <HowItWorkPage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.SUBSCRIPTION_DETAILS}
        element={
          <Suspense t={t}>
            <SubscriptionPage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.COMPANY_INFORMATION_PAGE1}
        element={
          <Suspense t={t}>
            <CompanyInformationOnePage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.COMPANY_INFORMATION_PAGE2}
        element={
          <Suspense t={t}>
            <CompanyInformationTwoPage t={t} />
          </Suspense>
        }
      />
      <Route
        path={routes.FORMULARY_QUESTION + '/:formulary_id'}
        element={
          <Suspense t={t}>
            <FormularyQuestionPage t={t} />
          </Suspense>
        }
      />
      <Route
        path={routes.PRACTICE_INFORMATION}
        element={
          <Suspense t={t}>
            <PracticeInformationPage t={t} />
          </Suspense>
        }
      />

      <Route
        path={routes.FORMULARY_PHARMACY}
        element={
          <Suspense t={t}>
            <FormularyPharmacy t={t} />
          </Suspense>
        }
      />
      <Route
        path={routes.LIST_QUESTION_FORMULARY + '/:formulary_id'}
        element={
          <Suspense t={t}>
            <ListQuestionaryFormularyPage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.ALL_SET}
        element={
          <Suspense t={t}>
            <AllSetPage t={t} />
          </Suspense>
        }
      />
      <Route
        exact
        path={routes.COMPANY_INTAKE_INFO}
        element={
          <Suspense t={t}>
            <CompanyIntakeInformationPage t={t} />
          </Suspense>
        }
      />
      <Route
        path={routes.COMPANY_STAFF_INFO + '/:user_id'}
        element={
          <Suspense t={t}>
            <CompanyStaffInformationPage t={t} />
          </Suspense>
        }
      />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
SignUpRoute.propTypes = {
  t: PropTypes.func,
};
export default SignUpRoute;
