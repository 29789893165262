import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CouponCodeSettingsService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Cmg data
 */
const useGetAllCoupons = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`get-coupons`, params], () => CouponCodeSettingsService.getAllCoupons(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useUpdateCoupons = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeSettingsService.updateCoupons, {
    onSuccess,
    onError,
  });
};

const useUpdateCouponStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeSettingsService.updateCouponStatus, {
    onSuccess,
    onError,
  });
};

const useAddCoupons = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeSettingsService.addCoupons, {
    onSuccess,
    onError,
  });
};

const useViewCoupons = (coupon_code_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['coupon-view', coupon_code_id],
    () => CouponCodeSettingsService.viewCoupons({ coupon_code_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useCouponDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeSettingsService.deleteCoupon, {
    onSuccess,
    onError,
  });
};

export {
  useGetAllCoupons,
  useUpdateCoupons,
  useAddCoupons,
  useViewCoupons,
  useUpdateCouponStatus,
  useCouponDelete,
};
