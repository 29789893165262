import client from 'libs/HttpClient';

class BookingList {
  static organizationBookingList(params) {
    return client.get('booking/get-organization-booking-list', { params });
  }
  static getBookingOrgSpecificSlotTime(params) {
    return client.get('booking/get-book-org-specific-time-slot', { params });
  }
  static getOrganizationTimezoneList(request) {
    return client.get('common/timezone-list', request);
  }
  static cancelBookingOrganization(request) {
    return client.put(`booking/organization-cancel-booking`, request);
  }
  static bookingCallStatusChanges(request) {
    return client.put(`booking/join-data-update`, request);
  }
  static organizationSlotBookingStore(request) {
    return client.put('booking/organization-slot-book', request);
  }
  static getBookingDetails(params) {
    return client.get('booking/get-details', { params });
  }
  static rescheduleBookingOrganization(request) {
    return client.put(`booking/organization-reschedule-booking`, request);
  }
  static getOrderItemBookingData(params) {
    return client.get('booking/get-order-item-booking', { params });
  }
  static completeBookingOrganization(request) {
    return client.put(`booking/organization-complete-booking`, request);
  }
  static skipBookingOrganization(request) {
    return client.put(`booking/organization-skip-booking`, request);
  }
}

export { BookingList };
