import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as PriceIcon } from 'assets/images/price-icon.svg';
import { ReactComponent as VisiterIcon } from 'assets/images/visiter_icon.svg';
import { ReactComponent as PendingRXIcon } from 'assets/images/pending_rx.svg';
import { ReactComponent as FormularyIcon } from 'assets/images/formulary.svg';
import { ReactComponent as MessageIcon } from 'assets/images/message.svg';
import { ReactComponent as OrderIcon } from 'assets/images/order_view.svg';
import { ReactComponent as DiscountIcon } from 'assets/images/discount_icon.svg';
import { ReactComponent as WithoutDiscountIcon } from 'assets/images/without_discount.svg';
import { ReactComponent as DiscountFree } from 'assets/images/Discount_free.svg';
import { ReactComponent as LineChartIcon } from 'assets/images/line-chart.svg';
import { ReactComponent as DownChartIcon } from 'assets/images/down-arrow.svg';

import PlaceholderImage from 'assets/images/whiteSquare.png';
import { currencyType } from 'helpers';
import { useGetDashboardData, useGetUserProfile } from 'hooks';
import { loggedUser, updateUserData } from 'store';
import { ORDERS, SETTINGS_FORMULARY_LIST } from 'config';

const DashboardPage = ({ t }) => {
  const dispatch = useDispatch();
  const getUserData = useSelector(loggedUser);
  const socket = window.socket;
  const navigate = useNavigate();
  socket.on('disconnect', () => {
    socket.emit('user_disconnect', { user_id: getUserData.user.user_id });
  }); //prints "transport close"
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  if (getUserData.isLoggedIn && !isLoggedIn) {
    setIsLoggedIn(true);
    if (!isOffline) {
      socket.emit('disconnected', {});
      setIsOffline(true);
    }
  }
  if (getUserData.isLoggedIn && isLoggedIn) {
    socket.emit('user_connected', {
      from_user_id: getUserData.user.user_id,
      user_chat_room_id: 0,
    });
  }
  const [data, setData] = useState({
    unread_message_count: 0,
    pending_rx_count: 0,
    total_visiter: 0,
    total_price: 0,
    total_visiter_cal: 0,
    total_price_cal: 0,
    visiter_cal_check: 1,
    price_cal_check: 1,
    total_draft_orders: 0,
    delivered_cart_order: 0,
    total_order_without_discount: 0,
  });
  const {
    refetch: chatMessageRefetch,
    data: getData,
    isLoading,
  } = useGetDashboardData((res) => {
    setTimeout(() => {
      setData(res.data);
    }, 1000);
  });
  if (isLoading && getData) {
    setData(getData.data);
  }
  /**
   * This api call will gives response of user profile and setting data
   */
  useGetUserProfile(({ data: profile }) => {
    const dataStore = {
      userData: profile.userData,
      cancelSubscription: profile.userCancelSubscription,
      userSubscription: profile.userSubscription,
      // multipleAccount: profile.multipleAccount || false,
      // clinic_data: profile.clinic_data,
      // user_type_data: profile.user_type_data,
    };
    dispatch(updateUserData(dataStore));
  });
  useEffect(() => {
    chatMessageRefetch();
    socket.on('check_message', (data) => {
      if (data.to_user_id == getUserData.user.user_id) chatMessageRefetch();
    });
  }, [socket, getUserData]);
  return (
    <Container className="dashboard-section-container">
      <h2 className="dashboard-page-heading align-self-center text-start heading-text">
        {t('front.company_dashboard_page_header_title')}
      </h2>
      <Row className="dashboard-section-row g-3">
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to={ORDERS} className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <OrderIcon />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_order_title')}
                </Card.Title>
              </Card.Body>
            </Card>
          </NavLink>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to={SETTINGS_FORMULARY_LIST} className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <FormularyIcon />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_formulary_title')}
                </Card.Title>
              </Card.Body>
            </Card>
          </NavLink>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to="/chat" target="_blank" className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <MessageIcon className="custom-icon-stroke custom-icon" />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_message_title')}
                </Card.Title>
                {/* {data.unread_message_count > 0 && ( */}
                <Card.Text className="heading-text">
                  {data.unread_message_count}&nbsp;
                  {t('front.company_dashboard_page_message_text')}
                </Card.Text>
                {/*  )} */}
              </Card.Body>
            </Card>
          </NavLink>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to="/orders/?status=2" className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <PendingRXIcon />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_pending_rx_title')}
                </Card.Title>
                <Card.Text className="heading-text">{data.pending_rx_count}</Card.Text>
              </Card.Body>
            </Card>
          </NavLink>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to={ORDERS} className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <VisiterIcon />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_visiter_title')}
                </Card.Title>
                <Card.Text className="heading-text">{data.total_visiter}</Card.Text>
              </Card.Body>
              {data.total_visiter_cal > 0 && (
                <h6 className="text-end">
                  {`Last 30 Days`}
                  <br />
                  {data.visiter_cal_check == 1 ? (
                    <LineChartIcon className="me-1" />
                  ) : (
                    <DownChartIcon className="me-1" />
                  )}
                  <span className="text-success">+{data.total_visiter_cal}%</span>
                </h6>
              )}
            </Card>
          </NavLink>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to={ORDERS} className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <PriceIcon />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_price_title')}
                </Card.Title>
                <Card.Text className="heading-text">{data.total_price}</Card.Text>
              </Card.Body>
              {data.total_price_cal > 0 && (
                <h6 className="text-end">
                  {`Last 30 Days`}
                  <br />
                  {data.price_cal_check == 1 ? (
                    <LineChartIcon className="me-1" />
                  ) : (
                    <DownChartIcon className="me-1" />
                  )}
                  <span className="text-success">+{data.total_price_cal}%</span>
                </h6>
              )}
            </Card>
          </NavLink>
        </Col>

        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to={ORDERS} className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <DiscountIcon className="discount-icon" />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_abandonment_cart_draft_orders')}
                </Card.Title>
                <Card.Text className="heading-text">{data.total_draft_orders}% &nbsp;</Card.Text>
              </Card.Body>
            </Card>
          </NavLink>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to={ORDERS} className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <DiscountFree className="discount-icon" />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_abandonment_cart_delivered_order')}
                </Card.Title>
                <Card.Text className="heading-text">{data.delivered_cart_order}&nbsp;</Card.Text>
              </Card.Body>
            </Card>
          </NavLink>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NavLink to={ORDERS} className="dash-box">
            <Card className="text-center">
              <Card.Body>
                <Card.Title>
                  <WithoutDiscountIcon className="discount-icon" />
                </Card.Title>
                <Card.Title className="heading-text">
                  {t('front.company_dashboard_page_abandonment_cart_without_discount')}
                </Card.Title>
                <Card.Text className="heading-text">
                  {data.total_order_without_discount}&nbsp;
                </Card.Text>
              </Card.Body>
            </Card>
          </NavLink>
        </Col>
      </Row>
      <h2 className="dashboard-page-heading align-self-center text-start heading-text dashboard-margin">
        {t('front.company_dashboard_page_discounted_item')}
      </h2>
      <Row className="dashboard-section-row">
        {!isLoading && getData?.data.formulary.length > 0 ? (
          getData?.data.formulary.map((formularyData, index) => {
            return (
              <Col key={index} xl={3} lg={3} md={6} sm={12}>
                <Card className="order-card">
                  <div className="order-imgs">
                    <LazyLoadImage
                      alt={'Featured Image'}
                      key={formularyData.featured_image ? formularyData.featured_image : ''}
                      placeholderSrc={PlaceholderImage}
                      className="card-img"
                      src={formularyData.featured_image ? formularyData.featured_image : ''}
                      onClick={() => navigate(ORDERS)}
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="heading-text">
                      <span className="text-start">
                        {formularyData.name.length >= 21
                          ? formularyData.name.slice(0, 21) + '...'
                          : formularyData.name}
                      </span>
                      <span className="text-end">{`${currencyType()}${formularyData.price}`}</span>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        ) : (
          <div className="page-not-found data-not-found border py-0">
            <div className="error-page-text pt-4 heading-text">
              <span className="product-link">{t('front.no_discount_orders_found_text')}</span>
            </div>
          </div>
        )}
      </Row>
    </Container>
  );
};
DashboardPage.propTypes = {
  t: PropTypes.func,
};
export default DashboardPage;
