import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { OtherPageFooter, OtherPageNavbar } from 'common/layout';
import { useLocation } from 'react-router';

const PublicLayout = (props) => {
  const { t } = useTranslation();
  const history = useLocation();
  if (
    localStorage.getItem('timeInterval') &&
    history &&
    history.pathname &&
    history.pathname.split('/')[1] !== 'video-call'
  ) {
    clearInterval(localStorage.getItem('timeInterval'));
    localStorage.removeItem('timeInterval');
  }
  if (
    localStorage.getItem('timeIntervalJoin') &&
    history &&
    history.pathname &&
    history.pathname.split('/')[1] !== 'video-call'
  ) {
    clearInterval(localStorage.getItem('timeIntervalJoin'));
    localStorage.removeItem('timeIntervalJoin');
  }
  return (
    <div {...props} className="common-container">
      <OtherPageNavbar t={t} />
      <section className="main-section">{props.children}</section>
      <OtherPageFooter t={t} />
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.any,
  t: PropTypes.func,
};

export { PublicLayout };
