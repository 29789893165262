import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { FormularyCategoryService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Frequency types
 */
const useListFormularyCategory = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['formulary-category-list', { params }],
    () => FormularyCategoryService.listFormularyCategory(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useListFormularyCategoryName = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['formulary-category-name', { params }],
    () => FormularyCategoryService.listFormularyCategoryName(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useAddFormularyCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FormularyCategoryService.addFormularyCategory, {
    onSuccess,
    onError,
  });
};
const useViewFormularyCategory = (formulary_category_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-formulary-category',
    () => FormularyCategoryService.getFormularyCategory({ formulary_category_id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useUpdateFormularyCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FormularyCategoryService.updateFormularyCategory, {
    onSuccess,
    onError,
  });
};
const useUpdateFormularyCategoryStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(FormularyCategoryService.updateFormularyCategoryStatus, {
    onSuccess,
    onError,
  });
};
const useFormularyCategoryDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(FormularyCategoryService.deleteFormularyCategory, {
    onSuccess,
    onError,
  });
};
export {
  useListFormularyCategory,
  useAddFormularyCategory,
  useViewFormularyCategory,
  useUpdateFormularyCategory,
  useUpdateFormularyCategoryStatus,
  useListFormularyCategoryName,
  useFormularyCategoryDelete,
};
