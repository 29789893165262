import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { loggedUser, logoutSuccess } from 'store';
import { settingData } from 'store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { setAuthToken, setClinicId, setUserType } from 'libs/HttpClient';
import { LOGIN, CLINIC } from 'config';

const OtherPageNavbar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkLoggedInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggedInUser.isLoggedIn;
  const socket = window.socket;

  const [isActive, setIsActive] = useState(false);
  const [navBgColor, setNavBgColor] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
  });
  const { t } = props;
  const changeBackground = () => {
    if (window.scrollY >= 3) {
      setNavBgColor(true);
    } else {
      setNavBgColor(false);
    }
  };
  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-alert-box">
            <div className="confirm-alert-heading">
              <span>{t('front.settings_sign_out_confirm_message')}</span>
            </div>
            <div className="confirm-alert-operations">
              <button
                className="confirm-button"
                onClick={() => {
                  setTimeout(() => {
                    dispatch(logoutSuccess());
                    setClinicId('');
                    setUserType('');
                    setAuthToken('');
                    window.localStorage.removeItem('link');
                    socket.emit('user_disconnect', { user_id: checkLoggedInUser?.user.user_id });
                    navigate(LOGIN);
                  }, 500);
                  onClose();
                }}>
                {t('front.settings_sign_out_confirm_yes_button_text')}
              </button>
              <button className="cancel-button" onClick={onClose}>
                {t('front.settings_sign_out_confirm_no_button_text')}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const getSettingData = useSelector(settingData);
  const handleDashboard = () => {
    navigate('/');
  };
  const handleSteps = () => {
    if (
      checkLoggedInUser.multipleAccount === true &&
      checkLoggedInUser.isMultipleDefaultCheck === true
    )
      navigate(CLINIC);
    else navigate(LOGIN);
  };
  const setNavExpandedClick = (expanded) => {
    setIsActive(expanded);
  };

  const setNavClose = () => {
    setIsActive(false);
  };
  return (
    <Container fluid className="navbar-container">
      <Navbar
        collapseOnSelect
        expand="lg"
        onToggle={setNavExpandedClick}
        expanded={isActive}
        className={navBgColor || isActive === true ? 'navbar-dark-bg' : 'navbar-light-bg'}>
        <span>
          <NavLink to="/" className="brand-logo navbar-brand">
            <img
              src={
                getSettingData &&
                (navBgColor || isActive === true
                  ? getSettingData.home_page_general_header_sub_logo
                  : getSettingData.home_page_general_header_logo)
              }
              className="brand-logo-img"
              alt={'Header Logo'}
            />
          </NavLink>
        </span>
        <div className="d-flex">
          <div className="login-button mobile-login-btn">
            {isLoggedIn ? (
              <>
                <Button onClick={handleLogout} className="login-btn button-rounded">
                  {t('front.home_page_navbar_button_log_out')}
                </Button>
                {location && location.pathname.includes('/auth/') ? (
                  <Button onClick={handleDashboard} className="step-btn button-rounded">
                    {t('front.home_page_navbar_button_dashboard')}
                  </Button>
                ) : (
                  <Button onClick={handleSteps} className="step-btn button-rounded">
                    {t('front.home_page_navbar_button_dashboard')}
                  </Button>
                )}
              </>
            ) : (
              <Link to="/login" className="login-btn text-decoration-none me-2 button-rounded">
                {t('front.home_page_navbar_button_log_in')}
              </Link>
            )}
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FontAwesomeIcon className="custom-icon" icon={isActive === true ? faXmark : faBars} />
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav" className={isActive == true ? 'show' : ''}>
          <Nav onClick={setNavClose} className="navbar-color">
            <NavLink to="/?redirect=Home_header_section" className="nav-link ">
              {t('front.home_page_navbar_nav_link_home')}
            </NavLink>
            <NavLink to="/?redirect=about_us_section" className="nav-link ">
              {t('front.home_page_navbar_nav_link_about')}
            </NavLink>
            <NavLink to="/?redirect=how_it_works_section" className="nav-link ">
              {t('front.home_page_navbar_nav_link_how_to')}
            </NavLink>
            <NavLink to="/?redirect=pricing_section" className="nav-link ">
              {t('front.home_page_navbar_nav_link_pricing')}
            </NavLink>
            <NavLink to="/" className="nav-link ">
              {t('front.home_page_navbar_nav_link_blog')}
            </NavLink>
            <div className="login-button desktop-login-btn">
              {isLoggedIn ? (
                <>
                  <Button onClick={handleLogout} className="login-btn ms-2 button-rounded">
                    {t('front.home_page_navbar_button_log_out')}
                  </Button>
                  {location && location.pathname.includes('/auth/') ? (
                    <Button onClick={handleDashboard} className="step-btn button-rounded">
                      {t('front.home_page_navbar_button_dashboard')}
                    </Button>
                  ) : (
                    <Button onClick={handleSteps} className="step-btn ms-2 button-rounded">
                      {t('front.home_page_navbar_button_dashboard')}
                    </Button>
                  )}
                </>
              ) : (
                <Link to="/login" className="login-btn ms-2 text-decoration-none button-rounded">
                  {t('front.home_page_navbar_button_log_in')}
                </Link>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};
OtherPageNavbar.propTypes = {
  t: PropTypes.func,
};
export { OtherPageNavbar };
