import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import 'assets/scss/components/_pagination.scss';
import { ReactComponent as LeftIcon } from 'assets/images/left.svg';
import { ReactComponent as LeftAllIcon } from 'assets/images/left_all.svg';
import { ReactComponent as RightIcon } from 'assets/images/right.svg';
import { ReactComponent as RightAllIcon } from 'assets/images/right_all.svg';
import { ShimmerTableCol } from 'common';
const initialState = {
  queryPageIndex: 0,
};

const PAGE_CHANGED = 'PAGE_CHANGED';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const TNTable = ({
  columns,
  data,
  paginationData,
  t,
  onSelectPage,
  idName = '',
  pageIndexGet = 0,
  loading = false,
}) => {
  initialState.queryPageIndex = pageIndexGet;
  const [{ queryPageIndex }, dispatch] = React.useReducer(reducer, initialState);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex },
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: queryPageIndex,
      },
      autoResetFilters: false,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: paginationData ? paginationData.last_page : 1,
    },
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    onSelectPage(pageIndex);
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  const firstPageRows = rows.slice(0, 20);
  console.log(firstPageRows, 'firstPageRows');
  return (
    <>
      <Table {...getTableProps()} responsive className="text-center table-sortable" id={idName}>
        <thead className="align-middle">
          {headerGroups.map((headerGroup, key) => (
            <tr key={key} className="header-text">
              {headerGroup.headers.map((column, key) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={key}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading &&
            Array.from({ length: 4 }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>
                    <ShimmerTableCol col={1} />
                  </td>
                ))}
              </tr>
            ))}
          {!loading && firstPageRows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                {' '}
                {t('front.organization_booking_no_data_found_table')}
              </td>
            </tr>
          )}
          {!loading &&
            firstPageRows.length !== 0 &&
            firstPageRows.map((row, key) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={key}>
                  {row.cells.map((cell, key) => {
                    return (
                      <td {...cell.getCellProps()} key={key}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="pagination-table">
        <Row className={firstPageRows.length === 0 ? 'd-none' : ''}>
          <Col lg={12} xs={12}>
            <div className="table-pagination">
              <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <LeftAllIcon />
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                <LeftIcon />
              </Button>
              &nbsp;&nbsp;&nbsp;
              <span>
                Page
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                <RightIcon />
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <RightAllIcon />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
TNTable.propTypes = {
  columns: PropTypes.any,
  column: PropTypes.any,
  paginationData: PropTypes.any,
  data: PropTypes.any,
  pageIndexGet: PropTypes.any,
  t: PropTypes.func,
  idName: PropTypes.string,
  onSelectPage: PropTypes.func,
  handleCheckboxSelection: PropTypes.any,
  loading: PropTypes.any,
};

export default TNTable;
