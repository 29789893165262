import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { BookingList } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useBookingList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`get-organization-booking-list`, [params]],
    () => BookingList.organizationBookingList(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useBookingOrgSpecificSlotTime = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.getBookingOrgSpecificSlotTime, {
    onSuccess,
    onError,
  });
};
const useOrganizationGetTimezoneList = (onSuccess, onError = onDefaultError) => {
  return useQuery('timezone-list', BookingList.getOrganizationTimezoneList, {
    onSuccess,
    onError,
  });
};
const useBookingCancelStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.cancelBookingOrganization, {
    onSuccess,
    onError,
  });
};
const useBookingReschedule = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.rescheduleBookingOrganization, {
    onSuccess,
    onError,
  });
};
const useOrganizationSlotBooking = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.organizationSlotBookingStore, {
    onSuccess,
    onError,
  });
};
const userGetBookingDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['get-booking-detail', [params]], () => BookingList.getBookingDetails(params), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useBookingCallStatusChanges = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.bookingCallStatusChanges, {
    onSuccess,
    onError,
  });
};
const userGetOrderItemBookingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.getOrderItemBookingData, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useBookingCompleteStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.completeBookingOrganization, {
    onSuccess,
    onError,
  });
};
const useSkipBookingStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookingList.skipBookingOrganization, {
    onSuccess,
    onError,
  });
};

export {
  useBookingList,
  useOrganizationGetTimezoneList,
  useBookingCancelStatus,
  useBookingOrgSpecificSlotTime,
  useOrganizationSlotBooking,
  userGetBookingDetails,
  useBookingReschedule,
  useBookingCallStatusChanges,
  userGetOrderItemBookingData,
  useBookingCompleteStatus,
  useSkipBookingStatus,
};
